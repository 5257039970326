/**
 * Created by ashahab on 8/8/2017.
 */

import React, {Component} from "react";
import Heading from "../heading/heading";
import {browserHistory} from "react-router";
import {connect} from "react-redux";
import {RECEIVED_SCHOOLS_RESULTS,SCHOOLS_SEARCH_RESULT,    ElasticSearchTypes,
    ElasticSearchColumns} from "../../actions/types";
import MaintenanceTable from "../../components/maintenance-table/maintenance-table";
import LoginUtils from "../common/login-utils";
import {Alert, Button, Modal} from "react-bootstrap";
import config from "../../config";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import $ from "jquery";
import apiClient from "../../actions/api-client";
import TextFieldGroup from "../forms/text-field-group";
import SelectFieldGroup from "../forms/select-field-group";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import ReactScrollPagination from 'react-scroll-pagination'
import Filter from "../../components/filter/filter";
import virtualLogo from '../../assets/images/virtual-logo.png'
import stemLabsLogo from '../../assets/images/stem-labs.png'
import "./custom.css";

class ManageSchools extends Component {

    constructor(props) {
        super(props);
        this.hideViewQuestionPage = this.hideViewQuestionPage.bind(this);
        this.hideViewDetailPage = this.hideViewDetailPage.bind(this);
        this.showViewDetailsPage = this.showViewDetailsPage.bind(this);

        this.getCreateSchoolDialog  = this.getCreateSchoolDialog.bind(this);
        this.getSubjectSelectionDialog  = this.getSubjectSelectionDialog.bind(this);
        this.handleSearch  = this.handleSearch.bind(this);

        this.generateLicense = this.generateLicense.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

        this.editSchool = this.editSchool.bind(this);
        this.renewLicense = this.renewLicense.bind(this);
        this.suspendSchool = this.suspendSchool.bind(this);
        this.getSuspendSchoolDialog = this.getSuspendSchoolDialog.bind(this);
        this.onMultilingualLicense = this.onMultilingualLicense.bind(this);

        this.state = {
            screenWidth: window.innerWidth, viewQuestionDetailsPage:false, selectedRecord:{}, viewQuestionPanel: false,
            alertText: "This is a sample alert", alertStyle: "danger", showAlert: false,
            loading:false,
            showCreateSchoolDialog:false,schoolName:'',state:'',numOfTeachers:'',schoolAdminName:'',emailAddress:'',licenseTypes:'',errors:{},
            loadingNextPage: 0,licenseExpiry:'', showRenewDialog:false, showSuspendSchoolDialog:false, multiLingualLicense : false,
            showCreateSchoolSelectSubjectDialog: false,
            allSubjects:[],
            subjects:[],
            searchTerm: '',
            schoolType: '',
            isStemSelected: false
        };

        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock ';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';

        this.isEditSchool = false;
        this.isSuspendSchool = false;
        this.fetchAllSubjects = this.fetchAllSubjects.bind(this);
    }

    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if(isMobile && this.state.loadingNextPage === 0)
        {
            let myID = Math.random() * 10000000000000000000;
            this.setState({'loadingNextPage': myID});
            console.log("getting more activities");
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('school');
                const {dispatch} = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch,'school',esAttr.searchTerm, esAttr.pageSize+10, esAttr.activePage,esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery  = new ElasticSearchQuery();
                elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, this.props,
                    esAttr.searchTerm, esAttr.pageSize+10, esAttr.activePage , esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_SCHOOLS_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords:totalRecords
                        });
                    }, false);
                this.setState({'loadingNextPage': 0});
            }

        }

    }

    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/administration', '/');
        if (!this.props.manageSchools.alertText) {
            const {dispatch} = this.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch,'school','', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);
            let elasticSearchQuery  = new ElasticSearchQuery();
            elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, this.props,
                '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {
                    
                    dispatch({
                        type: RECEIVED_SCHOOLS_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords:totalRecords
                    });
                }, false);
        }
        this.setState({'alertText': this.props.manageSchools.alertText,'alertStyle': this.props.manageSchools.alertStyle, 'showAlert': this.props.manageSchools.showAlert});
        setTimeout(()=>{
            this.setState({...this.state, showAlert: false});
        }, 5000);
        this.fetchAllSubjects();
    }

    handleAlertDismiss(e) {
        this.setState({'showAlert': false});
    }

    onChange(e)
    {
        if (e.target.id==='licenseTypes' && e.target.value==='custom')
        {
            //  $('#licenseTypesDiv').addClass('removeDisplay');
            $('#customLicense').removeClass('removeDisplay');
            $('#customLicenseTextBox').prop('placeholder','Enter Custom Value');
            this.setState({[e.target.name] : ''});
        }
        else if (e.target.id==='licenseTypes' && e.target.value!=='custom')
        {
            $('#customLicense').addClass('removeDisplay');
            this.setState({[e.target.name] : e.target.value});
        }
        else if (e.target.id==='schoolType')
        {
            let allSubjects =this.state.allSubjects
            allSubjects.forEach(obj=>obj.selected = false);
            this.setState({[e.target.name] : e.target.value, allSubjects: allSubjects});
            setTimeout(() => {
                this.handleSearch(false, true);
            }, (0));
        }
        else
        {
            this.setState({[e.target.name] : e.target.value});
        }
    }

    onMultilingualLicense(e) {
        this.setState({
            multiLingualLicense : e.target.checked
        });
    }

    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    showViewDetailsPage()
    {
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).addClass('removeDisplay');
        this.setState({viewQuestionDetailsPage: true});
    }

    hideViewDetailPage()
    {
        this.setState({viewQuestionDetailsPage: false});
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    hideViewQuestionPage()
    {
        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName= 'listViewBlock';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';

        this.setState({viewQuestionPanel:false});
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    changeSubjectSelection(event, id){
        let subjects =this.state.subjects
        let obj = subjects.find(obj=>obj.id == id);
        if(obj){
            obj.selected = event.target.checked;
        }
        
        let allSubjects =this.state.allSubjects
        if (obj) {
          obj = subjects.find((obj) => obj.id == id);
          obj.selected = event.target.checked;
        }
        this.setState({subjects: subjects, allSubjects: allSubjects});
    }


    getCreateSchoolDialog()
    {
        const {errors} = this.state;
        let title = "";
        let buttonName = "";

        if (this.isEditSchool)
        {
            title = "Edit School";
            buttonName = "Update";        
        }
        else
        {
            title = "Create New School";
            buttonName = "Generate License";
        }
        let licenseTypes = [
            {
                "id": "500",
                "name": "500 Student License"
            },
            {
                "id": "1000",
                "name": "1000 Student License"
            },
            {
                "id": "1500",
                "name": "1500 Student License"
            },
            {
                "id": "custom",
                "name": "Custom License"
            },
        ];

        const selectedSubjectsHtml = [];
        const selectedSubjects = this.state.allSubjects.filter(obj=>obj.selected);
        if (selectedSubjects && selectedSubjects.length > 0) {
            for (let i = 0; i < selectedSubjects.length; i++) {
                selectedSubjectsHtml.push(
                  <span key={selectedSubjects[i].id} className="keywordTag">
                    <input
                      type="hidden"
                      name="undefined[]"
                      value={selectedSubjects[i].name}
                    />
                    {selectedSubjects[i].name}{" "}
                    <span className="text-cross-icon" onClick={()=>this.changeSubjectSelection({target:{checked: false}},selectedSubjects[i].id)}>
                      ×
                    </span>
                  </span>
                );
            }
        }

        const schoolTypes = [
            {id: 'Lower School', name: 'Lower School'},
            {id: 'Middle School', name: 'Middle School'},
            {id: 'Upper School', name: 'Upper School'},
            {id: 'Professional Learning', name: 'Professional Learning'}
        ]

        const themeChanged = (event) =>{
            this.setState({isStemSelected: !this.state.isStemSelected});
            console.log(this.state.isStemSelected);
        }

        return <Modal
            show={this.state.showCreateSchoolDialog}
            onHide={()=> {
                this.setState({showCreateSchoolDialog: false});
                this.isEditSchool = false;
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mainFormBox">
                    <div className="col-lg-12 col-md-12">
                        <TextFieldGroup
                            isDisabled={this.isEditSchool}
                            error={errors.schoolName}
                            label="School Name:"
                            field="schoolName"
                            value={this.state.schoolName}
                            placeholder="Enter School Name"
                            onChange={this.onChange}
                        />
                        <TextFieldGroup
                            isDisabled={this.isEditSchool}
                            error={errors.state}
                            label="State:"
                            field="state"
                            value={this.state.state}
                            placeholder="Enter State"
                            onChange={this.onChange}
                        />
                        <TextFieldGroup
                            error={errors.numOfTeachers}
                            label="Number of Teacher:"
                            field="numOfTeachers"
                            value={this.state.numOfTeachers}
                            placeholder="Enter Number of Teacher"
                            onChange={this.onChange}
                        />
                        <TextFieldGroup
                            isDisabled={this.isEditSchool}
                            error={errors.schoolAdminName}
                            label="School Admin Name:"
                            field="schoolAdminName"
                            value={this.state.schoolAdminName}
                            placeholder="Enter Name of the School Admin"
                            onChange={this.onChange}
                        />
                        <TextFieldGroup
                            isDisabled={this.isEditSchool}
                            error={errors.emailAddress}
                            label="Email Address:"
                            field="emailAddress"
                            value={this.state.emailAddress}
                            placeholder="Enter Email Address"
                            onChange={this.onChange}
                        />
                        <SelectFieldGroup
                            label="School Type:"
                            placeholder="Select School Type"
                            error={this.state.errors.schoolType}
                            dropDownId="schoolType"
                            value={this.state.schoolType}
                            field="schoolType"
                            options={schoolTypes}
                            onChange={this.onChange}
                        />
                        <div id="licenseTypesDiv" className=" ">
                            <SelectFieldGroup
                                error={errors.licenseTypes}
                                label="License:"
                                field="licenseTypes"
                                dropDownId="licenseTypes"
                                value={this.state.licenseTypes}
                                options={licenseTypes}
                                placeholder="Select License Type"
                                onChange={this.onChange}
                            />
                        </div>
                        <div id="customLicense" className="removeDisplay">
                            <TextFieldGroup
                                id="customLicenseTextBox"
                                error={errors.licenseTypes}
                                label="Custom License:"
                                field="licenseTypes"
                                value={this.state.licenseTypes}
                                placeholder="Select License Type"
                                onChange={this.onChange}
                            />
                        </div>
                        <div id="licenseTypesDiv" className="form-group">
                                <label>MultiLingual License</label>
                                <div>
                                    <input type="checkbox" id='searchtipBoxCheckBox' className="css-checkbox" defaultChecked={this.state.multiLingualLicense} onClick= {this.onMultilingualLicense} />
                                    <label htmlFor='searchtipBoxCheckBox' className="pay_label css-label1 ml-checkbox-margin"></label>
                                </div>

                        </div>
                        {selectedSubjectsHtml}
                        <div id="licenseSubjectsSelection" className="form-group">
                                <Button onClick={()=>{
                                    this.setState({showCreateSchoolSelectSubjectDialog: true});
                                }} bsStyle='primary' disabled={this.state.schoolType == ''}>Select Subjects</Button>
                                {this.state.errors.addSubjects && (<span className="subject-error">
                                    Please select subjects for school
                                </span>)}

                        </div>
                        <div className="theme-selector">
                            <img src={virtualLogo} alt="-" className={this.state.isStemSelected ? 'logoClass' : 'logoClass selected'} />
                            <span className="switch">
                                <input id="switch-rounded" type="checkbox" checked={this.state.isStemSelected} onChange={themeChanged}/>
                                <label htmlFor="switch-rounded"></label>
                            </span>
                            <img src={stemLabsLogo} alt="-"  className={this.state.isStemSelected ? 'logoClass selected' : 'logoClass '} />
                        </div>
    
                    </div>
                    <div className="row"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showCreateSchoolDialog: false});
                }}>Cancel</Button>
                <Button onClick={()=>{
                        this.generateLicense();
                }} bsStyle='primary'>{buttonName}</Button>
            </Modal.Footer>
        </Modal>
    }

    getRenewSchoolDialog() {
        let message = "Are you sure you want to extend this school's license for another year. Current expiry date is " + new Date(parseFloat(this.state.currentRecord.licenseExpiry));
        let title = "Renew License";
        let body = <div id="deleteDialogContent">
            <p>
                {message}
            </p>
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showRenewDialog}
            onHide={()=> {
                this.setState({showRenewDialog: false, currentRecord: null});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    that.setState({showRenewDialog: false, currentRecord: null});
                }}>Cancel</Button>
                <Button onClick={()=> {
                    that.renewLicense();
                }} bsStyle='primary'>Renew</Button>
            </Modal.Footer>
        </Modal>;
    }

    getSuspendSchoolDialog() {
        let message = "";
        let title = "";
        if (this.isSuspendSchool)
        {
            message = "Are you sure you want to suspend this school's license? All users will be unable to login";
            title = "Suspend School";
        }
        else
        {
            message = "Are you sure you want to reinstate this school's license? All users will be able to login";
            title = "Reinstate School";
        }

        let body = <div id="deleteDialogContent">
            <p>
                {message}
            </p>
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showSuspendSchoolDialog}
            onHide={()=> {
                this.setState({showSchoolCodeDiaogBox: false, currentRecord: null});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    that.setState({showSuspendSchoolDialog: false, currentRecord: null});
                }}>Cancel</Button>
                <Button onClick={()=> {
                    that.suspendSchool(this.isSuspendSchool);
                }} bsStyle='primary'>Confirm</Button>
            </Modal.Footer>
        </Modal>;
    }

    suspendSchool(suspend)
    {
        
        let that = this;
        let pathTemplate = '/school/update/status';
        let method = 'PUT';
        let body = {
            schoolID: this.state.currentRecord.id,
            suspendFlag: suspend
        };
        
        this.setState({showSuspendSchoolDialog:false, loading: true});

        apiClient.invokeApi({}, pathTemplate, method, {}, body, '4').then(function(result){
            const {dispatch} = that.props;
            setTimeout(() => {
                that.hideIndicator();
            }, 3000);
            setTimeout(function () {
                if (that.child) { // Ensure the ref is not null
                    that.child.filterRecords(that.child.appliedFilters);
                } else {
                    ElasticSearchActions.setElasticSearchAttributes(dispatch,'school','', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);
                    let elasticSearchQuery  = new ElasticSearchQuery();
                    elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, that.props,
                        '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {
                            
                            dispatch({
                                type: RECEIVED_SCHOOLS_RESULTS,
                                status: 200,
                                data: JSON.parse(JSON.stringify(results)),
                                alertText: '',
                                alertStyle: '',
                                showAlert: false,
                                totalRecords:totalRecords
                            });
                        }, false);
                }
            }, 2000);
            that.setState({showAlert:true, alertText:result.data, alertStyle:'success', showSuspendSchoolDialog:false});
            setTimeout(()=>{
                that.setState({...that.state, showAlert: false});
            }, 5000);
        }).catch( function(result){
            that.hideIndicator();
            that.setState({showAlert:true, alertText:result.message, alertStyle:'danger', showSuspendSchoolDialogs:false});
            setTimeout(()=>{
                that.setState({...that.state, showAlert: false});
            }, 5000);
        });
    }

    generateLicense()
    {
        let that = this;
        let state = this.state;
        let pathTemplate = '';
        let method = '';
        let body = {};
        if (!this.isEditSchool)
        {
            pathTemplate = '/school/add';
            method = 'POST';
            body = {
                schoolName: state.schoolName,
                state: state.state,
                licensedTeachers: parseInt(state.numOfTeachers),
                licensedStudents: parseInt(state.licenseTypes),
                email: state.emailAddress,
                multiLingualLicense : state.multiLingualLicense,
                subjects : state.allSubjects.filter(obj=>obj.selected).map(obj=>obj.id),
                schoolType: state.schoolType
            };
            if(!body.schoolType){
                let error = this.state.errors;
                error.schoolType = 'School Type is required';
                that.setState({errors:error});
                return;
            }
            if(body.subjects.length == 0){
                let error = this.state.errors;
                error.addSubjects = true;
                that.setState({errors:error});
                return;
            }
            if (state.schoolAdminName) {
                body.adminName = state.schoolAdminName;
            }
            if(this.state.isStemSelected){
                body.schoolTheme = 'stemLabs'
            } else {
                body.schoolTheme = 'virtual'
            }

            this.showIndicator();
            that.setState({showCreateSchoolDialog:false})
            apiClient.invokeApi({}, pathTemplate, method, {}, body).then(function(result){
                setTimeout(() => {
                    that.hideIndicator();
                }, 2500);
                const {dispatch} = that.props;
                setTimeout(function () {
                    if (that.child) { // Ensure the ref is not null
                        that.child.filterRecords(that.child.appliedFilters);
                    } else {
                        ElasticSearchActions.setElasticSearchAttributes(dispatch,'school','', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);
                        let elasticSearchQuery  = new ElasticSearchQuery();
                        elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, that.props,
                            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {
                                
                                dispatch({
                                    type: RECEIVED_SCHOOLS_RESULTS,
                                    status: 200,
                                    data: JSON.parse(JSON.stringify(results)),
                                    alertText: '',
                                    alertStyle: '',
                                    showAlert: false,
                                    totalRecords:totalRecords
                                });
                            }, false);
                    }
                }, 1500);
                that.setState({showAlert:true, alertText:result.data.message, alertStyle:'success'});
                setTimeout(()=>{
                    that.setState({...that.state, showAlert: false});
                }, 5000);

            }).catch( function(result){
                that.hideIndicator();
                that.setState({showAlert:true, alertText:result.message, alertStyle:'danger'});
                setTimeout(()=>{
                    that.setState({...that.state, showAlert: false});
                }, 5000);
            });
        }
        else
        {
            pathTemplate = '/school/update';
            method = 'PUT';
            body = {
                schoolID: state.schoolID,
                licensedTeachers: parseInt(state.numOfTeachers),
                licensedStudents: parseInt(state.licenseTypes),
                multiLingualLicense : state.multiLingualLicense,
                subjectList : state.allSubjects.filter(obj=>obj.selected).map(obj=>obj.id),
                schoolType: state.schoolType
            };
            if(!body.schoolType){
                let error = this.state.errors;
                error.schoolType = 'School Type is required';
                that.setState({errors:error});
                return;
            }
            if(body.subjectList.length == 0){
                let error = this.state.errors;
                error.addSubjects = true;
                that.setState({errors:error});
                return;
            }
            if(this.state.isStemSelected){
                body.schoolTheme = 'stemLabs'
            } else {
                body.schoolTheme = 'virtual'
            }
            this.showIndicator();
            that.setState({showCreateSchoolDialog:false})
            apiClient.invokeApi({}, pathTemplate, method, {}, body, '4').then(function(result){
                setTimeout(() => {
                    that.hideIndicator();
                }, 2500);
                const {dispatch} = that.props;
                setTimeout(function () {
                    if (that.child) { // Ensure the ref is not null
                        that.child.filterRecords(that.child.appliedFilters);
                    } else {
                        ElasticSearchActions.setElasticSearchAttributes(dispatch,'school','', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);
                        let elasticSearchQuery  = new ElasticSearchQuery();
                        elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, that.props,
                            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {
                                
                                dispatch({
                                    type: RECEIVED_SCHOOLS_RESULTS,
                                    status: 200,
                                    data: JSON.parse(JSON.stringify(results)),
                                    alertText: '',
                                    alertStyle: '',
                                    showAlert: false,
                                    totalRecords:totalRecords
                                });
                            }, false);
                    }
                }, 1500);
                that.setState({showAlert:true, alertText:result.data, alertStyle:'success'});
                setTimeout(()=>{
                    that.setState({...that.state, showAlert: false});
                }, 5000);

            }).catch( function(result){
                that.hideIndicator();
                that.setState({showAlert:true, alertText:result.message, alertStyle:'danger'});
                setTimeout(()=>{
                    that.setState({...that.state, showAlert: false});
                }, 5000);
            });
        }
    }

    renewLicense()
    {
        let expiry  = new Date (parseFloat(this.state.currentRecord.licenseExpiry));
        if (expiry)
        {
            let year = expiry.getFullYear();
            let month = expiry.getMonth();
            let day = expiry.getDate();
            let newExpiryDate = new Date(year + 1, month, day);
            
            let that = this;
            let pathTemplate = '/school/update';
            let method = 'PUT';
            let body = {
                schoolID: this.state.currentRecord.id,
                licenseExpiry: newExpiryDate.getTime()
            }; 
            
            apiClient.invokeApi({}, pathTemplate, method, {}, body, '4').then(function(result){
                const {dispatch} = that.props;
                setTimeout(() => {
                    that.hideIndicator();
                }, 2500);
                setTimeout(function () {
                    if (that.child) { // Ensure the ref is not null
                        that.child.filterRecords(that.child.appliedFilters);
                    } else {
                        ElasticSearchActions.setElasticSearchAttributes(dispatch,'school','', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);
                        let elasticSearchQuery  = new ElasticSearchQuery();
                        elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, that.props,
                            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {
                                
                                dispatch({
                                    type: RECEIVED_SCHOOLS_RESULTS,
                                    status: 200,
                                    data: JSON.parse(JSON.stringify(results)),
                                    alertText: '',
                                    alertStyle: '',
                                    showAlert: false,
                                    totalRecords:totalRecords
                                });
                            }, false);
                    }
                }, 1000);
                that.setState({showAlert:true, alertText:result.data, alertStyle:'success', showRenewDialog:false});
                setTimeout(()=>{
                    that.setState({...that.state, showAlert: false});
                }, 5000);
            }).catch( function(result){
                that.hideIndicator();
                that.setState({showAlert:true, alertText:result.message, alertStyle:'danger', showRenewDialog:false});
                setTimeout(()=>{
                    that.setState({...that.state, showAlert: false});
                }, 5000);
            });
        }
    }

    editSchool(selectedRecord)
    {
        this.isEditSchool = true;
        let allSubjects = this.state.allSubjects.map(obj=>{obj.selected = false; return obj;});
        if(selectedRecord.subjects){
          for (const sub of selectedRecord.subjects) {
            let selectedSubject = allSubjects.find(obj => obj.id == sub);
            if(selectedSubject){
                selectedSubject.selected = true;
            }
          }
        }
        this.setState({
          schoolName: selectedRecord.schoolName,
          state: selectedRecord.state,
          numOfTeachers: String(selectedRecord.licensedTeachers),
          licenseTypes: String(selectedRecord.licensedStudents),
          emailAddress: selectedRecord.email,
          showCreateSchoolDialog: true,
          schoolID: selectedRecord.id,
          multiLingualLicense: selectedRecord.multiLingualLicense,
          allSubjects: allSubjects,
          schoolType: selectedRecord.schoolType
        });
    }

    handleSearch(addSpace = false, blur=false) {
        const element = document.getElementById('subjectSearch')
        let search = ''
        if(element)
         search = element.value.trim().toLowerCase();
        let schoolType = this.state.schoolType;
        let subjects = this.state.allSubjects.filter(obj=>
            obj.tags && obj.tags.find(tag => tag && tag == schoolType) &&
            obj.name.toLowerCase().includes(search) 
        )
        if(addSpace){
            search +=' ';
        }
        this.setState({subjects: subjects, searchTerm: search});
        if(!blur){
          setTimeout(() => {
            const element = document.getElementById("subjectSearch");
            element.focus();
            element.selectionStart = 1000;
            element.selectionEnd = 1000;
          }, 1);
        }
    }


    getSubjectSelectionDialog(){

        const onSearchChange = (e) =>{
            if(e.key == ' '){
                this.handleSearch(true);
            }
            if(e.key === 'Enter'){
                this.handleSearch();
            }
        }

        const checkSubjectSelectionError= ()=> {
            if(this.state.allSubjects.filter(obj=>obj.selected).length == 0){
                let errors = this.state.errors;
                errors.addSubjects = true;
                this.setState({errors: errors});
              } else {
                let errors = this.state.errors;
                errors.addSubjects = false;
                this.setState({errors: errors});
              }
        }

        const allSubjectsHtml = [];
        const selectedSubjects = this.state.allSubjects.filter(obj=>obj.selected);
        if (selectedSubjects && selectedSubjects.length > 0) {
            for (let i = 0; i < selectedSubjects.length; i++) {
                allSubjectsHtml.push(
                  <span key={selectedSubjects[i].id} className="keywordTag">
                    <input
                      type="hidden"
                      name="undefined[]"
                      value={selectedSubjects[i].name}
                    />
                    {selectedSubjects[i].name}{" "}
                    <span className="text-cross-icon" onClick={()=>this.changeSubjectSelection({target:{checked: false}},selectedSubjects[i].id)}>
                      ×
                    </span>
                  </span>
                );
            }
        }
        console.log(selectedSubjects);
    
        return (
          <Modal
            show={this.state.showCreateSchoolSelectSubjectDialog}
            onHide={() => {
              this.setState({ showCreateSchoolSelectSubjectDialog: false });
              this.isEditSchool = false;
            }}
            container={this}
            aria-labelledby="contained-modal-title"
            className="subject-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="select-subject-modal">
                Select Subjects for license
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {allSubjectsHtml}
                <div
                    className="mainFormBox pb-0"
                    key={"subjectselection" + Math.random()}
                >

                    <div
                    style={{
                        width: "50%",
                    }}
                    >
                    <div className="input-group">
                        <input
                            id='subjectSearch'
                            defaultValue={this.state.searchTerm}
                            onKeyUp={onSearchChange}
                            onBlur={()=> this.handleSearch(false, true)}
                            className={"form-control"}
                        />
                        <span className="input-group-btn" onClick={()=>this.handleSearch()}>
                        <div className="btn btn-primary" type="button">
                            <i className="fa fa-search search-icon"></i>
                        </div>
                        </span>
                    </div>
                    </div>
                    <div style={{ columnCount: 2 }} className="subject-checkbox-ul">
                    {this.state.subjects.map((obj) => {
                        return (
                        <div key={"li_" + Math.random()}>
                            <input
                            type="checkbox"
                            id={obj.id}
                            key={obj.id + Math.random()}
                            className="css-checkbox"
                            value={obj.id}
                            onChange={(event) =>
                                this.changeSubjectSelection(event, obj.id)
                            }
                            checked={obj.selected}
                            />
                            <label
                            htmlFor={obj.id}
                            className="pay_label css-label1 subject-checkbox-margin"
                            >
                            {obj.name}
                            </label>
                        </div>
                        );
                    })}
                    </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.setState({ showCreateSchoolSelectSubjectDialog: false });
                  checkSubjectSelectionError();
                }}
                bsStyle="primary"
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        );
    };
    
    fetchAllSubjects(){
        this.showIndicator();
        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: schoolName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then((result)=> {
            const subjects = result.data.filter(obj=> obj.subjectState == "Publish");
            this.setState({
                ...this.state,
                subjects: subjects,
                allSubjects: subjects,
                loading: false
            });
        }).catch((error)=> {
            this.hideIndicator();
        });
        
    }

    render() {
        let esAttr = this.props.elasticSearchAttributes.map.get('school');
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: true,
            actions: [
                {
                    linkLabel: 'View Teachers', action: (selectedRecord, evt) => {
                    browserHistory.push({
                        pathname: '/users',
                        state: {searchBySchoolId:selectedRecord.id}
                    });
                },
                    isConditional: false,
                },
                {
                    linkLabel: 'Edit', action: (selectedRecord, evt) => {
                    this.editSchool(selectedRecord);
                },
                    isConditional: false,
                },
                {
                    linkLabel: 'Renew License', action: (selectedRecord, evt) => {
                    this.isDeleteSchool = false;
                    this.setState({showRenewDialog:true, currentRecord:selectedRecord});
                },
                    isConditional: false,
                },
                {
                    linkLabel: 'Suspend', action: (selectedRecord) => {

                    this.isSuspendSchool = true;
                    this.setState({showSuspendSchoolDialog:true, currentRecord:selectedRecord});
                },
                    isConditional: true,
                    conditionExpression: (currentRecord) =>
                    {
                        if (currentRecord.schoolStatus2 && currentRecord.schoolStatus2.toLowerCase() === 'active')
                        {
                            return true
                        }
                    }
                },
                {
                    linkLabel: 'Reinstate', action: (selectedRecord) => {
                    this.isSuspendSchool = false;
                    this.setState({showSuspendSchoolDialog:true, currentRecord:selectedRecord});
                },
                    isConditional: true,
                    conditionExpression: (currentRecord) =>
                    {
                        if (currentRecord.schoolStatus2 && currentRecord.schoolStatus2.toLowerCase() === 'suspended')
                        {
                            return true
                        }
                    }
                },
            ],
            columns: [
                {name: 'schoolName', type: 'text', label: 'School Name', options: {sortable: true}},
                {name: 'usedTeachers', type: 'text', label: 'Used Teacher', options: {sortable: true}},
                {name: 'usedStudents', type: 'text', label: 'Used Students', options: {sortable: true}},
                {name: 'adminCode', type: 'text', label: 'Admin Code', options: {
                    sortable: true, customDom: (record) => {
                        return (<td>{record.adminCode}</td>);
                    }}},
                {name: 'teacherCode', type: 'text', label: 'School Code', options: {
                    sortable: true, customDom: (record) => {
                        return (<td>{record.teacherCode}</td>);
                    }}},
                {name: 'studentCode', type: 'text', label: 'Universal Student Registration Code', options: {
                    sortable: true, customDom: (record) => {
                        return (<td>{record.studentCode}</td>);
                    }}},
                {name: 'licensedStudents', type: 'text', label: 'Licensed Students', options: {sortable: false}},
                {name: 'licensedTeachers', type: 'text', label: 'Licensed Teachers', options: {sortable: false}},
                {name: 'licenseExpiry', type: 'date', label: 'License Expiration', options: {sortable: true}},
                {name: 'schoolStutus2', type: 'date', label: 'School Status', options: {sortable: true}},
                {name: 'multiLingualLicense', type: 'text', label: 'Multilingual License', options: {
                    sortable: true, customDom: (record) => {
                        return (<td>{record.multiLingualLicense === true ? "Yes" : "No" }</td>)
                    }}}
            ],
            items: this.props.manageSchools.results,
            pageable: true,
            totalRecords: this.props.manageSchools.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize?esAttr.pageSize:10,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize:5
            }
        };

        return(
            <div>
                <Heading
                    isQuery = {false}
                    isHideHeading={false}
                    allButtons={[<button type="button" className="btn btn-primary crtBtn" onClick={()=>{this.setState({showCreateSchoolDialog:true, schoolName:'', emailAddress:'', state:'', licenseTypes:'', numOfTeachers:'', adminName:''})}}
                                         key="CreateButton"><span> </span> Create New</button>]}
                    isCreateButton={true}
                    heading="Manage Schools"
                    isShowButton={true}
                    isSearch={true}
                    actionName={RECEIVED_SCHOOLS_RESULTS}
                    screenNameForElasticSearch = {ElasticSearchTypes.School}
                    allColumnsForElasticSearch = {ElasticSearchColumns.School}
                    location = "school"
                />
                <div id="tableFiltersAndSideView">
                    <div className="container-fluid noPad listMainBoxWrapper">
                        <Filter
                            isQuery={false}
                            actionName = {RECEIVED_SCHOOLS_RESULTS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            clearFilters={'manageSchools.clearFilters'}
                            filterColumns={[
                                {name: 'schoolStatus2', type: 'text', label: 'Status', sort: 'desc'}
                            ]}
                            screenNameForElasticSearch = {ElasticSearchTypes.School}
                            allColumnsForElasticSearch = {ElasticSearchColumns.School}
                            location = "school"
                            setInstance={(instance)=> { this.child = instance; }}
                        />
                        <div className={this.tableCssClassName}>
                            <div className="listToBesslide NiceScroll">
                                <div className="blockForlist ">
                                    <MaintenanceTable
                                        location = "school"
                                        isQuery = {false}
                                        actionName={RECEIVED_SCHOOLS_RESULTS}
                                        screenNameForElasticSearch = {ElasticSearchTypes.School}
                                        allColumnsForElasticSearch = {ElasticSearchColumns.School}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: RECEIVED_SCHOOLS_RESULTS,
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                    <ReactScrollPagination
                                        fetchFunc={this.getNextPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                {this.getCreateSchoolDialog()}
                {this.getSubjectSelectionDialog()}
                {this.state.showRenewDialog && this.getRenewSchoolDialog()}
                {this.state.showSuspendSchoolDialog && this.getSuspendSchoolDialog()}
            </div>
        );
    }
    
}


function mapStateToProps(state) {
    return {...state};
}

export default connect(mapStateToProps)(ManageSchools);
