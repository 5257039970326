import React from "react";
import {Route, IndexRoute} from "react-router";
import App from "../../container/app";
import AppContainer from "../../container/app-container";
import ManageActivities from "../activities/manage-activities";
import Login from "../login/login";
import TeacherSignup from "../signup/teacher-signup";
import CreateActivityQuestion from "../activities/question/new/create-question-activity";
import CreateFlashCardActivity from "../activities/flash-card/new/create-flash-card-activity";
import CreateStudentFlashCardActivity from "../activities/flash-card/new/create-student-flash-card-activity";
import EditQuestionActivity from "../activities/question/edit/edit-question-activity";
import EditFlashCardActivity from "../activities/flash-card/edit/edit-flash-card-activity";
import CreateCourse from "../courses/create-course/create-course";
import ManageCourses from "../courses/manage-courses/manage-courses";
import PageNotFound from "../page-not-found/page-not-found";
import Assignments from "../assignments/assignments";
import CreateAssignment from "../assignments/new/create-assignment";
import ManageQuestions from "../questions/manage-questions";
import TeacherProfile from "../profiles/teacher-profile/teacher-profile";
import ManageStudents from "../students/manage-students";
import StudentAssignments from "../student-portal/assignments/assignments";
import StudentReport from "../student-portal/reports/assignment-report/reports";
import TeacherStudentReport from "../reports/student-report/reports";
import ActivityReport from "../reports/activity-report/report";
import StudentActivityReport from "../reports/student-activity-report/reports";
import ReportCard from "../student-portal/reports/report-card/report-card";
import StudentManageReport from "../student-portal/reports/manage-report";
import ViewAssignment from "../student-portal/assignments/view/view-assignment";
import StudentPractice from "../student-portal/practice/practice";
import ManageSchools from "../schools/manage-schools";
import StudentProfile from "../profiles/student-profile/student-profile";
import PracticeSubject from "../student-portal/practice/view/practice-subject";
import TeacherReport from "../reports/report";
import ManageReport from "../reports/manage-report";
import ViewReport from "../reports/view/view-report";
import PracticeCourse from "../student-portal/practice/course/practice-course";
import GlossaryListing from "../student-portal/glossary/glossary-listing";
import GlossaryOverview from "../student-portal/glossary/glossary-overview";
import ManageDashboardStudent from "../dashboard/student/manage-dashboard";
import ManageDashboardTeacher from "../dashboard/teacher/manage-dashboard";
import StudentSubmission from "../assignments/student-submissions/StudentSubmission";
import SchoolAdministration from "../schools/school-administration";
import PreivewCourse from "../student-portal/preview-course/preview-course";
import ManageUsers from "../users/manage-users";
import LpAdminReport from "../reports/lp-admin-report/lp-admin-report";
// import CourseReport from "../reports/course-report/report"
import CourseReportActivities from "../reports/course-report/activities"
import CourseReportStudents from "../reports/course-report/students"
import CourseReportActivityReport from "../reports/course-report/activityReport"
import AdminReport from '../reports/admin-report/admin-report'
import interactiveAssignment from "../student-portal/assignments/view/interactive-assignment";

export function requireAuth(nextState, replace) {
    if ( nextState.location.search == '?autosignin' ) {
        localStorage.clear()
    }
    if (!localStorage.getItem('userObj')) {
        if ( nextState.location.search == '?autosignin' ) {
            replace({
                pathname : '/login',
                query : { "autoSignIn" : true , "pathname" : nextState.location.pathname }
            })
        } else {
            replace({
              pathname: '/'
            })

        }
    } else {
        let user = JSON.parse(localStorage.getItem('userObj'));
        if (user.userStatus && user.userStatus.toLowerCase() === "disable" && nextState.location.pathname.indexOf('profile') === -1) {
            if (user.userType && user.userType.toLowerCase() === "student") {
                replace({
                  pathname: '/student/profile'
                })
            } else {
                replace({
                  pathname: '/profile'
                })
            }
        }
        if (user.userType.toLowerCase === "student" && nextState.location.pathname.indexOf('student') < 0) {
            replace({
              pathname: '/student/dashboard'
            })
        }



        // not allowed pages       
        if (nextState.location.pathname == "/users" || nextState.location.pathname == "/administration") {
            // only for lpAdmin
            onlyAllow(user, replace)
        } else if (nextState.location.pathname == "/school/administration") {
            // only for Admin
            onlyAllow(user, replace, true)
        }


    }
}

function onlyAllow(user, replace, ignoreLearnerPalSchoolCheck) {
    if (user.userType == "admin" && (user.schoolName.toLowerCase() === "learnerpal" || ignoreLearnerPalSchoolCheck)) {
        console.log("Good to go")
    } else if (user.userType == "student") {
        replace({
            pathname: '/student/dashboard'
        })
    } else {
        replace({
            pathname: '/dashboard'
        })
    }
}

export default (
    <Route path="/" component={App}>
        <IndexRoute component={Login}/>
        <Route path="activities" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageActivities}/>
            <Route path="question/new" component={CreateActivityQuestion} onEnter={requireAuth}/>
            <Route path="question/edit/:id" component={EditQuestionActivity} onEnter={requireAuth}/>
            <Route path="flashcard/new" component={CreateFlashCardActivity} onEnter={requireAuth}/>
            <Route path="flashcard/edit/:id" component={EditFlashCardActivity} onEnter={requireAuth}/>
        </Route>
        <Route path="assignments" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={Assignments}/>
            <Route path="assign" component={CreateAssignment} onEnter={requireAuth}/>
            <Route path="activities" component={ManageActivities} onEnter={requireAuth}/>
            <Route path="courses" component={ManageCourses} onEnter={requireAuth}/>
            <Route path="submissions" component={StudentSubmission} onEnter={requireAuth}/>
            <Route path="interactive/:id/:name" component={interactiveAssignment} onEnter={requireAuth}/>
        </Route>
        <Route path="courses" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageCourses}/>
            <Route path="create-course" component={CreateCourse} onEnter={requireAuth}/>
            <Route path="activities" component={ManageActivities} onEnter={requireAuth}/>
            <Route path="preview/:id" component={PreivewCourse} onEnter={requireAuth}/>
        </Route>
        <Route path="questions" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageQuestions}/>
        </Route>
        <Route path="profile" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={TeacherProfile}/>
        </Route>
        <Route path="students" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageStudents}/>
        </Route>
        <Route path="administration" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageSchools}/>
        </Route>
        <Route path="dashboard" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageDashboardTeacher}/>
        </Route>
        <Route path="reports" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageReport}/>
            <Route path="students/:subject" component={TeacherStudentReport} onEnter={requireAuth}/>
            <Route path="activities" component={ActivityReport} onEnter={requireAuth}/>
            <Route path="assignments" component={TeacherReport} onEnter={requireAuth}/>
            <Route path="view/:id" component={ViewReport} onEnter={requireAuth}/>
            <Route path="studentactivityreport" component={StudentActivityReport} onEnter={requireAuth}/>
            <Route path="lpAdmin" component={LpAdminReport} onEnter={requireAuth}/>    
            {/* <Route path="course-report-activities/:id" component={CourseReportActivities} onEnter={requireAuth}/> */}
            {/* <Route path="course-report-activities/course-report-students/:id" component={CourseReportStudents} onEnter={requireAuth}/> */}
            <Route path="course-report-students/:id" component={CourseReportStudents} onEnter={requireAuth}/>
            <Route path="course-report-students/course-report-activities/:id" component={CourseReportActivities} onEnter={requireAuth}/>
            <Route path="course-report-activity-report/:id" component={CourseReportActivityReport} onEnter={requireAuth}/>
            <Route path="admin-report" component={AdminReport} onEnter={requireAuth}/>
        </Route>

        <Route path="student" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={StudentAssignments}/>
            <Route path="flashcard/new/:id" component={CreateStudentFlashCardActivity} onEnter={requireAuth}/>
            <Route path="assignments" component={StudentAssignments} />
            <Route path="assignments/view/:id" component={ViewAssignment}/>
            <Route path="selfstudy" component={StudentPractice}/>
            <Route path="selfstudy/:id" component={StudentPractice}/>
            <Route path="selfstudy/view/:id" component={PracticeSubject}/>
            <Route path="selfstudy/flashcard/:id" component={PracticeSubject}/>
            <Route path="selfstudy/course/:id" component={PracticeCourse}/>
            <Route path="reports/assignments" component={StudentReport}/>
            <Route path="reports/reportcard/:subject" component={ReportCard}/>
            <Route path="reports" component={StudentManageReport}/>
            <Route path="profile" component={StudentProfile}/>
            <Route path="glossary-overview" component={GlossaryOverview}/>
            <Route path="glossary-listing" component={GlossaryListing}/>
            <Route path="dashboard" component={ManageDashboardStudent}/>
        </Route>
        <Route path="school" component={AppContainer} onEnter={requireAuth}>
            <Route path="administration" component={SchoolAdministration}/>
        </Route>
        <Route path="users" component={AppContainer} onEnter={requireAuth}>
            <IndexRoute component={ManageUsers}/>
        </Route>
        <Route path="signup" component={TeacherSignup}/>
        <Route path="login" component={Login}/>
        <Route path='*' component={PageNotFound}/>
    </Route>
)
