import React from "react";
import {connect} from "react-redux";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import {RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns} from "../../../actions/types";
import classnames from "classnames";
import $ from "jquery";
import {browserHistory} from "react-router";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import ElasticSearchStore from "../../../actions/elastic-search-store";
import "../../reports/view/view-report.css";
import "./student-assignment.css";
import apiClient from "../../../actions/api-client";
import MaterialIcon from "react-google-material-icons";
import {Alert, Modal, Button} from "react-bootstrap";
import {Parser} from "html-to-react";
import SelectFieldGroup from "../../forms/select-field-group";
import Progress from 'react-progressbar';
import config from "../../../config";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../utils/timeParser';

class TeacherStudentReport extends React.Component {
    constructor(props) {

        super(props);
        this.getTeacherStudentReport = this.getTeacherStudentReport.bind(this);
        this.viewStudentDetails = this.viewStudentDetails.bind(this);
        this.loadStudentDetails = this.loadStudentDetails.bind(this);
        this.loadStudentReportCard = this.loadStudentReportCard.bind(this);
        this.loadAttemptDetails = this.loadAttemptDetails.bind(this);
        this.getPeriodsByTeacher = this.getPeriodsByTeacher.bind(this);
        this.getStudentsByTeacher = this.getStudentsByTeacher.bind(this);
        this.getStudentList = this.getStudentList.bind(this);
        this.getPeriodDropdown = this.getPeriodDropdown.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.loadAllTeachersStudentReportCard = this.loadAllTeachersStudentReportCard.bind(this);
        this.loadAllStudentReportCard = this.loadAllStudentReportCard.bind(this);
        this.loadAllTeachersStudentDetails = this.loadAllTeachersStudentDetails.bind(this);
        this.loadAllStudentDetails = this.loadAllStudentDetails.bind(this);
        this.loadAllStudentByPeriodReportCard = this.loadAllStudentByPeriodReportCard.bind(this);
        this.loadAllStudentByPeriodDetails = this.loadAllStudentByPeriodDetails.bind(this);
        this.loadAllTeacherFromSchoolID = this.loadAllTeacherFromSchoolID.bind(this);

        this.state = {screenWidth: window.innerWidth,loadingNextPage: 0, selectedType:'assignment', selectedReport:'gradeTracker', cachedData:{}, allTeachersCachedData:{}, currentReportName:'All Students Overview',intervalcount:0, loading:false,loadingReport:false};

        this.state.selectedSubject = this.props.params.subject;
        this.filterComponentCssClassName = 'filterBlock filterBlockBox NiceScroll';
        this.tableCssClassName = 'listViewBlock ';

        this.backToSubjectAggr = false;
        this.backToClassAggr = false;
        this.classSelected = false;
        this.i = 0;

        this.Loadingfunction = this.Loadingfunction.bind(this);
        this.showProgressBar = this.showProgressBar.bind(this);
        this.hideProgressBar = this.hideProgressBar.bind(this);

        if (this.props.location.state) {
            this.state.forAdminReport = this.props.location.state.forAdminReport;
            this.state.forAdminReportForSingleStudent = this.props.location.state.forAdminReportForSingleStudent;
            this.state.studentIDforAdminReportForSingleStudent = this.props.location.state.studentIDforAdminReportForSingleStudent;
            this.state.teacherIDforAdminReportForSingleStudent = this.props.location.state.teacherIDforAdminReportForSingleStudent;
            this.state.teacherNameforAdminReportForSingleStudent = this.props.location.state.teacherNameforAdminReportForSingleStudent;
            this.state.department = this.props.auth.user.department;
            this.state.schoolID = this.props.auth.user.schoolID;

            this.state.allTeachers = this.props.location.state.allTeachersList;

            this.state.forAdminReportForSingleTeacher = this.props.location.state.forAdminReportForSingleTeacher;
            this.state.teacherForAdminReportForSingleTeacher = this.props.location.state.teacherForAdminReportForSingleTeacher;

            this.state.fromAllStudents = this.props.location.state.fromAllStudents;
        }

        if(this.state.forAdminReport) {
            this.state.disableStudentPeriodDropDown = true;
        }

        if(this.state.forAdminReportForSingleStudent) {
            this.state.selectedType = "cumulative";
        }

        if(this.state.forAdminReportForSingleTeacher) {
            this.state.selectedTeacher = this.state.teacherForAdminReportForSingleTeacher
        }

        // if(this.state.forAdminReport) alert('forAdminReport')
        // if(this.state.forAdminReportForSingleStudent) alert('forAdminReportForSingleStudent')
        // if(this.state.forAdminReportForSingleTeacher) alert('forAdminReportForSingleTeacher')

        this.state.isPrincipal = this.props.auth.user.isPrincipal;

        console.log(this.state)
    }

    Loadingfunction(){
        {

            let context = this;

            setTimeout(function () {

                let tempNum = Math.floor(Math.random() * 5) + 5;

                context.i += tempNum ;

                if (context.i < 100) {

                    context.setState({intervalcount:context.i})

                    context.Loadingfunction();
                }
            }, 1000)
        }
    }


    componentDidMount() {
        if(this.state.forAdminReport) {
            // this.loadAllTeacherFromSchoolID(); // use to give all the teachers registered with the school but not by subject, now it`s coming from admin report
            this.loadAllTeachersStudentDetails();
            this.getAllSubjects();
        } else if (this.state.forAdminReportForSingleStudent) {
            this.getPeriodsByTeacher(this.state.teacherIDforAdminReportForSingleStudent);
            this.getTeacherStudentReport(this.state.teacherIDforAdminReportForSingleStudent);
            this.getStudentsByTeacher(this.state.teacherIDforAdminReportForSingleStudent); // this will cause double loader
            this.getAllSubjects();
            // this.loadAllStudentDetails();
        } else if (this.state.forAdminReportForSingleTeacher) {
            this.getPeriodsByTeacher(this.state.teacherForAdminReportForSingleTeacher.id);
            this.getTeacherStudentReport(this.state.teacherForAdminReportForSingleTeacher.id);
            this.getStudentsByTeacher(this.state.teacherForAdminReportForSingleTeacher.id, true);
            this.getAllSubjects();
            this.loadAllStudentDetails(this.state.teacherForAdminReportForSingleTeacher.id, this.state.teacherForAdminReportForSingleTeacher.name);
        }
        else {
            this.getPeriodsByTeacher();
            this.getTeacherStudentReport();
            this.getStudentsByTeacher(null, true);
            this.getAllSubjects();
            this.loadAllStudentDetails();
        }
    }


    loadAllTeacherFromSchoolID() {
        let context = this;
        // context.showProgressBar();
        // context.showIndicator();
        apiClient.invokeApi({},'/user/schoolname/'+this.props.auth.user.schoolID,'GET',{},{}).then(function (result) {

            let allTeachers = result.data;
            if (!context.state.isPrincipal) {
                allTeachers = allTeachers.filter((teacher) => {
                    return ((teacher.department === context.state.department) && (teacher.id !== context.props.auth.user.userName))
                });
            }
            allTeachers.sort((a, b) => a.name.localeCompare(b.name));
            context.setState({ allTeachers });
            // context.hideProgressBar();
            // context.hideIndicator();
        }).catch(function (error) {
            // context.hideProgressBar();
            // context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load data, please contact administrator. Error: ' + error.message
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });;
    }

    getStudentActivities(studentID){
        let context = this;
        context.showIndicator();
        let user = context.props.auth.user;
        let estore = new ElasticSearchStore({esIndexName:'lp-student'});
        let teacher = user.firstName + ' ' + user.lastName;
        return new Promise((resolve, reject) => {
            estore.query(null, "stdassign", {
                "match": {
                    "teacher": teacher
                }
            }, ["studentID", "type", "ownerName", "dueDate", "assignmentStatus", "teacher", "assignmentName", "assignmentID", "assignmentType", "activityID", "activityType", "activityName", "progress", "createdAt", "assignedActivities", "score", "timeIn", "attempts", "assignedCourses"], null, null, null, "10000").then((filterData) => {
                var activities = [];
                filterData.data.forEach((item) => {
                    if(item.assignedActivities){
                        activities.push({
                            assignmentID: item.assignmentID,
                            assignmentName: item.assignmentName,
                            assignmentType: item.assignmentType,
                            studentID: item.studentID,
                            teacher: item.teacher,
                            activityID: item.assignedActivities.activityID,
                            activityType: item.assignedActivities.activityType,
                            activityName: item.assignedActivities.activityName,
                            completionDate: item.createdAt,
                            progress: item.progress,
                            timeIn: item.timeIn,
                            score: item.score
                        });
                    }else if(item.assignedCourses){
                        item.assignedCourses.units.forEach(unit => {
                            unit.activities.forEach(act => {
                                activities.push({
                                    assignmentID:item.assignmentID,
                                    assignmentName:item.assignmentName,
                                    assignmentType:item.assignmentType,
                                    studentID:item.studentID,
                                    teacher:item.teacher,
                                    activityID: act.activityID,
                                    activityName: act.activityName,
                                    activityType: act.activityType,
                                    attempts:act.attempts,
                                    completionDate:act.lastUpdated,
                                    progress:act.progress,
                                    timeIn:act.timeIn,
                                    score:act.score
                                });
                            });
                        });
                    }
                });
                resolve(activities);
            }).catch(error=> {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to perform search, please contact administrator.',
                    loading:false
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            })
        });
    }

    loadAllStudentByPeriodReportCard(subject, periodName, teacherID) {
        let context = this;
        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                userName: teacherID ? teacherID : this.props.auth.user.userName,
                periodName:periodName
            };
            let pathTemplate = '/assignment/analysis/subject/{subject}/teacher/{userName}/class/{periodName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            context.showProgressBar();
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideProgressBar();
                resolve(result.data);
                context.hideIndicator();
            }).catch(function (error) {
                context.hideProgressBar();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }


    loadAllTeachersStudentReportCard(subject) {
        let context = this;

        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                department: this.props.auth.user.department,
                schoolName: this.props.auth.user.schoolID
            };
            let pathTemplate = '/assignment/analysis/allTeachers/{schoolName}/{department}/{subject}';
            let method = 'GET';
            let additionalParams = { queryParams : { isPrincipal: this.state.isPrincipal /*, subjects: this.props.auth.user.subjects */ } };
            let body = {};
            context.showProgressBar();
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {
                context.hideProgressBar();
                resolve(result.data);


            }).catch(function (error) {
                context.hideProgressBar();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadAllStudentReportCard(subject, teacherID) {
        let context = this;

        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                userName: teacherID ? teacherID : this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};

            // context.showProgressBar();

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {

                context.hideProgressBar();
                resolve(result.data);

            }).catch(function (error) {
                context.hideProgressBar();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadStudentReportCard(studentID, subject, teacherID) {
        let context = this;
        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                subject: subject,
                userName: teacherID ? teacherID : this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/student/{studentID}/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            context.showProgressBar();
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideProgressBar();
                resolve(result.data);
            }).catch(function (error) {
                context.hideProgressBar();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getPeriodsByTeacher(teacherID) {
        let that = this;
        let context = this;
        let params = {
            userName: teacherID ? teacherID : that.props.auth.user.userName
        };
        let pathTemplate = '/class/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "2").then(function (result) {
            let periods = result.data.allClasses;
            that.setState({'periods': periods});

        }).catch(function (result) {
            that.hideIndicator();
        });
    }

    loadAllStudentByPeriodDetails(className) {

        let context = this;
        context.backToClassAggr = false;
        context.classSelected = true;
        context.backToSubjectAggr = false;
        let periodName = '';
        if (className)
        {
            this.setState({periodName:className});
            periodName = className;
        }
        else if (this.state.periodName)
        {
            periodName = this.state.periodName;
        }
        else
        {
            return;
        }

        if (context.state.cachedData['byPeriod'+periodName+this.state.selectedSubject])
        {
            context.setState({
                periodName:periodName,
                currentReportName: periodName+' Overview',
                cumulative: context.state.cachedData['byPeriod'+periodName+this.state.selectedSubject].cumulative,
                practiceAssignment: context.state.cachedData['byPeriod'+periodName+this.state.selectedSubject].practiceAssignment,
                assignment: context.state.cachedData['byPeriod'+periodName+this.state.selectedSubject].assignment,
                view: 'report-card',
                students: context.state.cachedData['byPeriod'+periodName+this.state.selectedSubject].studentsList
                // hideLoading: true
            }, context.hideIndicator);
        }
        else
        {
            if(this.state.selectedSubject){
                this.loadAllStudentByPeriodReportCard(this.state.selectedSubject, periodName, context.state.selectedTeacher ? context.state.selectedTeacher.id : context.state.teacherIDforAdminReportForSingleStudent).then((result) => {
                    let cachedData = context.state.cachedData;
                    cachedData['byPeriod'+periodName+this.state.selectedSubject] = result;
                    context.setState({
                        cachedData,
                        periodName:periodName,
                        currentReportName: periodName+' Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card',
                        students: result.studentsList
                    });
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

    showProgressBar()
    {
        this.setState({loadingReport:true, intervalcount:0});
        this.i =0;
        this.Loadingfunction();
    }
    hideProgressBar()
    {
        this.i =101;
        // this.setState({loadingReport:false,intervalcount:100});
        this.setState({
            intervalcount: 100
        });
        setTimeout(() => {
            this.setState({ loadingReport: false });
        }, 100);
    }

    loadAllTeachersStudentDetails() {
        let context = this;

        if (context.state.allTeachersCachedData[context.state.selectedSubject])
        {
            context.setState({
                currentReportName: 'All Teachers` Students Overview',
                cumulative: context.state.allTeachersCachedData[context.state.selectedSubject].cumulative,
                practiceAssignment: context.state.allTeachersCachedData[context.state.selectedSubject].practiceAssignment,
                assignment: context.state.allTeachersCachedData[context.state.selectedSubject].assignment,
                view: 'report-card',
                hideLoading: true
            }, context.hideIndicator);
        }
        else
        {
            if(context.state.selectedSubject){

                context.loadAllTeachersStudentReportCard(context.state.selectedSubject).then((result) => {
                    let allTeachersCachedData = context.state.allTeachersCachedData;
                    allTeachersCachedData[context.state.selectedSubject] = result;
                    context.setState({
                        allTeachersCachedData,
                        currentReportName: 'All Teachers` Students Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card'
                    }, context.hideIndicator);
                }, (error) => {
                    // context.setState({loadingReport:false,
                    //     intervalcount:100});
                    context.hideProgressBar();
                    console.error(error);
                });
            }
        }
    }

    loadAllStudentDetails(teacherID, teacherName) {
        let context = this;

        if (context.state.cachedData[teacherID ? teacherID+"_"+context.state.selectedSubject : context.state.selectedSubject])
        {
            context.setState({
                currentReportName: (teacherName ? teacherName + " - " : "") +  'All Students Overview',
                cumulative: context.state.cachedData[teacherID ? teacherID+"_"+context.state.selectedSubject : context.state.selectedSubject].cumulative,
                practiceAssignment: context.state.cachedData[teacherID ? teacherID+"_"+context.state.selectedSubject : context.state.selectedSubject].practiceAssignment,
                assignment: context.state.cachedData[teacherID ? teacherID+"_"+context.state.selectedSubject : context.state.selectedSubject].assignment,
                view: 'report-card',
                students: context.state.cachedData[teacherID ? teacherID+"_"+context.state.selectedSubject : context.state.selectedSubject].studentsList,
                hideLoading: true
            }, context.hideIndicator);
        }
        else
        {
            if(context.state.selectedSubject){
                context.loadAllStudentReportCard(context.state.selectedSubject, teacherID ? teacherID : context.state.teacherIDforAdminReportForSingleStudent).then((result) => {
                    let cachedData = context.state.cachedData;
                    cachedData[teacherID ? teacherID+"_"+context.state.selectedSubject : context.state.selectedSubject] = result;
                    context.setState({
                        cachedData,
                        currentReportName: (teacherName ? teacherName + " - " : "") +  'All Students Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card',
                        students: result.studentsList
                    }, context.hideIndicator);
                }, (error) => {
                    // context.setState({loadingReport:false,
                    //     intervalcount:100});
                    context.hideProgressBar();
                    console.error(error);
                });
            }
        }
    }

    loadSelectedStudentDetails(student, type) {

        let context = this;
        context.backToClassAggr = true;
        if (!context.classSelected)
        {
            context.backToSubjectAggr = true;
        }
        if (type === 'report-card') {
            if(this.state.selectedSubject){
                if (context.state.cachedData['byStudent'+student.userName+this.state.selectedSubject])
                {
                    context.setState({
                        currentReportName: student.stdFirstName+' '+student.stdLastName,
                        cumulative: context.state.cachedData['byStudent'+student.userName+this.state.selectedSubject].cumulative,
                        practiceAssignment: context.state.cachedData['byStudent'+student.userName+this.state.selectedSubject].practiceAssignment,
                        assignment: context.state.cachedData['byStudent'+student.userName+this.state.selectedSubject].assignment,
                        selectedStudent: student,
                        view: 'report-card'
                    });
                }
                else
                {
                    context.loadStudentReportCard(student.students, context.state.selectedSubject, context.state.selectedTeacher ? context.state.selectedTeacher.id : context.state.teacherIDforAdminReportForSingleStudent).then((result) => {
                        let cachedData = context.state.cachedData;
                        cachedData['byStudent'+student.userName+this.state.selectedSubject]=result;
                        context.setState({
                            cachedData,
                            currentReportName: student.stdFirstName+' '+student.stdLastName,
                            cumulative: result.cumulative,
                            practiceAssignment: result.practiceAssignment,
                            assignment: result.assignment,
                            selectedStudent: student,
                            view: 'report-card'
                        });
                    }, (error) => {
                        console.error(error);
                    });
                }
            }else{
                this.setState({
                    selectedStudent: student,
                    view: 'report-card'
                });
            }
        } else {
            // load activities data.
            this.getStudentActivities(student.students).then(activities => {

                context.setState({
                    loading: false,
                    view: 'activities',
                    studentActivities: activities,
                    selectedStudent: student
                });
            });
        }
    }

    getStudentList(selectedPeriod) {
        let context = this;
        if (!context.state.search) {
            if (selectedPeriod && selectedPeriod !== 'All Students') {
                // filter students by period.
                if (context.state.students) {
                    let filtered = context.state.students.filter((std) => {
                        return std.className === selectedPeriod;
                    });
                    filtered.sort(function(a,b){
                        return a.stdFirstName.localeCompare(b.stdFirstName);
                    });
                    return filtered.map(student => {
                        return <li key={student.students} className={context.state.selectedStudent && context.state.selectedStudent.students === student.students ? 'selectedStudentClass':''}>
                            <div onClick={() => {
                                context.loadSelectedStudentDetails(student, 'report-card');
                            }} className="studentNameLink">{student.stdFirstName + ' ' + student.stdLastName}</div>
                            {/*<a onClick={() => {
                             this.loadSelectedStudentDetails(student, 'activities');
                             }
                             }>Activities</a>&nbsp;|&nbsp;
                             <a onClick={() => {
                             this.loadSelectedStudentDetails(student, 'report-card');
                             }
                             }>Report card</a>*/}
                        </li>
                    });
                }
                return [];
            } else {
                // return all.
                if (context.state.students) {
                    return context.state.students.map(student => {
                        return <li key={student.students} className={context.state.selectedStudent && context.state.selectedStudent && context.state.selectedStudent.students === student.students ? 'selectedStudentClass':''}>
                            <div onClick={() => {
                                context.loadSelectedStudentDetails(student, 'report-card');
                            }} className="studentNameLink">{student.stdFirstName + ' ' + student.stdLastName}</div>
                            {/*<a onClick={() => {
                             this.loadSelectedStudentDetails(student, 'activities');
                             }
                             }>Activities</a>&nbsp;|&nbsp;
                             <a onClick={() => {
                             this.loadSelectedStudentDetails(student, 'report-card');
                             }
                             }>Report card</a>*/}
                        </li>
                    });
                }
                return [];
            }
        } else {
            let searchTerm = this.state.search;
            if (this.state.students) {
                let filtered = this.state.students.filter((std) => {
                    return (std.stdFirstName + ' ' + std.stdLastName).indexOf(searchTerm) > -1;
                });
                return filtered.map(student => {
                    return <li key={student.students}>
                        <div>{student.stdFirstName + ' ' + student.stdLastName}</div>
                        <a onClick={() => {
                            this.loadSelectedStudentDetails(student, 'activities');
                        }
                        }>Activities</a>&nbsp;|&nbsp;
                        <a onClick={() => {
                            this.loadSelectedStudentDetails(student, 'report-card');
                        }
                        }>Report card</a>
                    </li>
                });
            }
            return [];
        }
    }

    getTeacherList() {
        let context = this;
        if (context.state.allTeachers) {
            // let filtered = context.state.allTeachers.filter((teacher) => {
            //     return (teacher.department === context.state.department) && (teacher.id !== context.props.auth.user.userName)
            // });
            // filtered.sort(function (a, b) {
            //     return a.name.localeCompare(b.name);
            // });
            return context.state.allTeachers.map(teacher => {
                return <li key={teacher.id} className={context.state.selectedTeacher && context.state.selectedTeacher.id === teacher.id ? 'selectedStudentClass' : ''}>
                <style>{"\
                li:hover{\
                  background-color:#ddd !important;\
                }\
              "}</style>
                    <div onClick={() => { context.state.hideLoading = true;
                        context.getStudentsByTeacher(teacher.id, true);
                        context.getPeriodsByTeacher(teacher.id);

                        context.getTeacherStudentReport(teacher.id);

                        context.getAllSubjects();
                        // context.loadAllStudentDetails();

                        // context.state.selectedTeacher = teacher;
                        context.state.selectedTeacher = {id:teacher.id, name:teacher.name}
                        context.state.backToTeacherAggr= true;
                        // context.setState({backToTeacherAggr: true, selectedTeacher: teacher})

                        console.log(teacher);
                        console.log(context.state.selectedTeacher);

                    }} className="studentNameLink">{teacher.name}</div>
                </li>
            });
        }
        return [];
    }

    getStudentsByTeacher(teacherID, noCallNeeded) {

        let context = this;

        if (noCallNeeded) {
            context.showProgressBar();
            return;
        }

        let params = {
            userName: teacherID ? teacherID : this.props.auth.user.userName
        };
        let pathTemplate = '/user/students/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            if(result.data.students && result.data.students.length == 0) {
                // return <li style={{ fontStyle: "italic" }}><div>No Students</div></li>;
                context.setState({students: []});
                if(context.state.hideLoading)  {
                    context.hideProgressBar();
                }
                return;
            }

            result.data.sort(function(a,b){
                return a.stdFirstName.localeCompare(b.stdFirstName);
            });

            context.setState({students: result.data}, () => {
                if(context.state.forAdminReportForSingleStudent) {
                    let student = context.state.students.find(std => std.students == context.state.studentIDforAdminReportForSingleStudent);
                    context.state.studentNameForAdminReportForSingleStudent = student.stdFirstName+" "+student.stdLastName;
                    context.loadSelectedStudentDetails(student, 'report-card');
                }
            });

            if(context.state.hideLoading)  {
                context.hideProgressBar();
            }

        }).catch(function (error) {
            context.hideIndicator();
            context.hideProgressBar();
        });
    }

    render() {
        const {screenWidth} = this.state;
        let context = this;
        let categoryData = [];
        let activities = [];
        if(this.state.selectedType === 'cumulative'){
            categoryData = context.state.cumulative || [];
        }else if(this.state.selectedType === 'practice'){
            categoryData = context.state.practiceAssignment || [];
        }else if(this.state.selectedType === 'assignment'){
            categoryData = context.state.assignment || [];
        }
        if(this.state.studentActivities){
            activities = this.state.studentActivities;
        }
        let subjectScore = 0;
        let questions = 0;
        let correctAns = 0;

        categoryData.forEach((cat) => {
            questions += cat.totalQuestions;
            correctAns += cat.answeredCorrectly;
        });

        if (correctAns === 0 && questions === 0) {
            subjectScore = 0;
        } else {
            subjectScore = (correctAns / questions) * 100;
            subjectScore = subjectScore.toFixed(0);
        }

        categoryData = categoryData.sort((a,b) => {
            return a.category.localeCompare(b.category);
        });

        let rows = [];
        categoryData.forEach((category, index) =>
        {
            let average = 0;
            let color = 'black';

            average = category.average;
            if (average.toFixed(0) < 65)
            {
                color = 'red';
            }
            rows.push(<tr key={'category-parent-' + category.category + index} id={category.category}>
                <td data-parent-id={category.category} data-is-expanded={false} style={{width:'40%'}}>
                    <a href="javascript:void 0" onClick={function (evt) {
                        var parent = evt.target.parentElement.parentElement;
                        var rows = document.querySelectorAll('tr[id*=\"parent-' + parent.dataset.parentId + '\"]');
                        if (parent.dataset.isExpanded === 'false') {
                            parent.dataset.isExpanded = 'true';
                            parent.firstElementChild.firstElementChild.textContent = 'remove_circle_outline';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = '';
                            }
                        } else {
                            parent.firstElementChild.firstElementChild.textContent = 'add_circle_outline';
                            parent.dataset.isExpanded = 'false';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = 'none';
                            }
                        }
                    }.bind(this)} data-parent-id={category.category} style={{ marginLeft: "0px" }}><MaterialIcon icon="add_circle_outline" size={24}/><strong>{category.category}</strong></a>
                </td>
                <td style={{width:'15%', color:color}}>
                    {average ? average.toFixed(0):0}{'%'}
                </td>
                <td style={{width:'15%'}}>
                    {category.totalQuestions}
                </td>
                <td style={{width:'15%'}}>
                    {category.answeredCorrectly}
                </td>
                <td style={{width:'15%'}}>
                    {category.answeredIncorrectly}
                </td>
            </tr>);

            category.subCategories.forEach((subCat, index) => {
                let average = 0;
                let color = 'black';

                average = subCat.average
                if (average.toFixed(0) < 65)
                {
                    color = 'red';
                }
                rows.push(<tr key={'category-child-' + subCat.category + index + Math.random()} id={'parent-' + category.category + subCat.category}
                              data-category-parent-id={category.category} style={{display:'none'}}>
                    <td className="indentSubCat">
                        {subCat.category}
                    </td>
                    <td style={{color:color}}>
                        {average.toFixed(0)}{'%'}
                    </td>
                    <td>
                        {subCat.totalQuestions}
                    </td>
                    <td>
                        {subCat.answeredCorrectly}
                    </td>
                    <td>
                        {subCat.answeredIncorrectly}
                    </td>
                </tr>);
            });
        });

        let actRows = [];
        activities.forEach((activity) => {
            actRows.push(<tr key={'activity-parent-' + activity.activityID} id={activity.activityID}>
                <td style={{width:'40%'}}>
                    {activity.activityName}
                </td>
                <td style={{width:'15%'}}>
                    {activity.score ? activity.score.toFixed(0):'0'}
                </td>
                <td style={{width:'15%'}}>
                    {activity.timeIn ? (parseHours(activity.timeIn) + ':' + parseMinutes(activity.timeIn) + ':' + parseSeconds(activity.timeIn)):'0:0:0'}
                </td>
                <td style={{width:'15%'}}>
                    {activity.progress? parseFloat(activity.progress).toFixed(0): 0}{'%'}
                </td>
                <td style={{width:'15%'}}>
                    {activity.attempts || 0}
                </td>
                <td style={{width:'5%'}} className="viewUserDetLink">
                    <a onClick={
                        function(){
                            this.viewStudentDetails(this.state.selectedStudent, activity)
                        }.bind(this)
                    }>
                        <MaterialIcon icon="search" size={24}/>
                    </a>
                </td>
            </tr>);
        });

        let studentStats = <table className="table" id="student-attempt-history">
            <thead>
            <tr>
                <th>
                </th>
                <th>
                    Average
                </th>
                <th>
                    Total Questions
                </th>
                <th>
                    Answered Correctly
                </th>
                <th>
                    Answered Incorrectly
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;

        let activityStats = <table className="table" id="student-attempt-history">
            <thead>
            <tr>
                <th>
                    Activity Name
                </th>
                <th>
                    Score
                </th>
                <th>
                    Time In
                </th>
                <th>
                    Progress
                </th>
                <th>
                    Attempts
                </th>
                <th>
                </th>
            </tr>
            </thead>
            <tbody>
            {actRows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p className="subjectScoreHeading">
                    {this.state.selectedSubject} : <span style={{color:subjectScore < 65 ? 'red':'green'}} className="subjectScoreHeadingSpan">{subjectScore===NaN ? 0:subjectScore}{'%'}</span>
                    <a className = "Activity-toolTip toolTipCus tipReportPos"><span className = "tooltiptext"style={{ fontWeight: 'normal' }}>This score is based on all the questions that were completed within LearnerPal</span></a>
                </p>
                <p className="gradeTrackerOverviewTextUnderSubject" style={{marginLeft:'23px', marginTop: '-30px', fontStyle:'italic', display:'table', textTransform:'capitalize'}}>
                    {this.state.currentReportName}
                </p>
                <p style={{overflow: 'auto'}}>
                    {studentStats}
                </p>
            </div>
        </div>;

        let activityTable = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{overflow: 'auto'}}>
                    {activityStats}
                </p>
            </div>
        </div>;

        let onChange = function (evt) {
            this.setState({
                selectedType: evt.target.value
            })
        }.bind(context);

        let onReportChange = function (evt) {
            if (evt.target.value == 'studentReport') {
                browserHistory.push({
                    pathname: '/reports/studentactivityreport', state: { forAdminReportForSingleStudent: true, studentIDforAdminReportForSingleStudent: this.state.studentIDforAdminReportForSingleStudent, studentNameForAdminReportForSingleStudent: this.state.studentNameForAdminReportForSingleStudent, teacherIDforAdminReportForSingleStudent: this.state.teacherIDforAdminReportForSingleStudent, teacherNameforAdminReportForSingleStudent: this.state.teacherNameforAdminReportForSingleStudent, subjectForAdminReportForSingleStudent: this.state.selectedSubject  }
                })
            }
            else {
                this.setState({
                    selectedReport: evt.target.value,
                })
            }
        }.bind(context);

        let subjects = this.state.subjects;
        let nodes = [];
        if(subjects){
            subjects.forEach(sub => {
                nodes .push({id:sub.id, name:sub.name});
            });
        }

        let selectSubject = <div className="selectSubjectDropdown">
            <SelectFieldGroup
                field="selectedSubject"
                value={this.state.selectedSubject}
                options={nodes}
                placeholder="Select a Subject"
                onChange={(evt) => {

                    if(!this.state.forAdminReportForSingleStudent) {
                        context.backToClassAggr = false;
                        context.backToSubjectAggr = false;
                        context.classSelected = false;
                    }
                    let subject = evt.target.value;
                    if(evt.target.value && evt.target.value !== 'none'){


                        if (this.state.forAdminReportForSingleStudent) {

                            context.setState({ selectedSubject: subject }, function () {
                                context.loadSelectedStudentDetails(context.state.selectedStudent, 'report-card');
                            });

                        } else if(this.state.forAdminReport) {

                            context.setState({ selectedSubject: subject }, function () {
                                context.loadAllTeachersStudentDetails();

                            }); } else if(this.state.forAdminReportForSingleTeacher) {

                                context.setState({ selectedSubject: subject }, function () {
                                    context.loadAllStudentDetails(this.state.teacherForAdminReportForSingleTeacher.id, this.state.teacherForAdminReportForSingleTeacher.name);

                                }); } else {

                            context.setState({ selectedSubject: subject }, function () {
                                context.loadAllStudentDetails();
                            });
                            // this.loadStudentReportCard(this.state.selectedStudent.students, subject).then((result) => {
                            //     context.setState({
                            //         cumulative: result.cumulative,
                            //         practiceAssignment: result.practiceAssignment,
                            //         assignment: result.assignment,
                            //         selectedSubject: subject
                            //     });
                            // }, (error) => {
                            //     console.error(error);
                            // });
                        }
                    }
                }}
            />
        </div>;

        return (
            <div id="TeacherStudentReport">
                <Heading
                    isQuery={false}
                    customURL={ElasticSearchTypes.StudentAssignment}
                    location = "studentAssignment"
                    actionName = {RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                    heading="Assignment Reports"
                    isSearch={false}
                    isShowButton={false}
                    screenNameForElasticSearch = {this.props.auth.user.userName}
                    allColumnsForElasticSearch = {ElasticSearchColumns.StudentAssignment}
                    breadcrumbsData={this.state.forAdminReport || this.state.forAdminReportForSingleStudent || this.state.forAdminReportForSingleTeacher ?
                        [
                            {
                                name: 'Reports', action: () => {
                                    browserHistory.push('/reports');
                                }
                            },
                            {
                                name: "Admin Report", action: () => {
                                    browserHistory.push({
                                        pathname: '/reports/admin-report',
                                        state: { selectedSubject: this.state.selectedSubject }
                                    })
                                }
                            },
                            {
                                name: "Grade Tracker"
                            }
                        ]
                        :
                        [
                            {
                                name: 'Reports', action: () => {
                                    browserHistory.push('/reports');
                                }
                            },
                            {
                                name: "Grade Tracker"
                            }
                        ]}
                />
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>



                        {(this.state.forAdminReportForSingleStudent || this.state.forAdminReport || this.state.forAdminReportForSingleTeacher) && <div className="backButtonDiv" style={{ marginTop: "21px" }}>

                            {/* <span style={{ zIndex: "1" }} className="backButton btn btn-default" onClick={() => {
                                browserHistory.push({
                                    pathname: '/reports/admin-report',
                                    state: { selectedSubject: this.state.selectedSubject }
                                })

                            }}><MaterialIcon icon="arrow_back" size={17} /> Admin Report</span> */}

                            <span style={{ zIndex: "1", /*marginTop: "40px"*/ }} className="backButton btn btn-default" onClick={() => {/*browserHistory.push({
    pathname: '/reports/studentactivityreport', state: { forAdminReportForSingleStudent: true, studentIDforAdminReportForSingleStudent: this.state.studentIDforAdminReportForSingleStudent, studentNameForAdminReportForSingleStudent: this.state.studentNameForAdminReportForSingleStudent, teacherIDforAdminReportForSingleStudent: this.state.teacherIDforAdminReportForSingleStudent, teacherNameforAdminReportForSingleStudent: this.state.teacherNameforAdminReportForSingleStudent, subjectForAdminReportForSingleStudent: this.state.selectedSubject  }
})*/
                                let backToTeacher = this.state.fromAllStudents ? undefined : this.state.teacherForAdminReportForSingleTeacher ? this.state.teacherForAdminReportForSingleTeacher : (this.state.teacherIDforAdminReportForSingleStudent ? { id: this.state.teacherIDforAdminReportForSingleStudent, name: this.state.teacherNameforAdminReportForSingleStudent } : undefined)
                                let fromAllStudents = this.state.fromAllStudents;

                                browserHistory.push({
                                    pathname: '/reports/admin-report',
                                    state: { selectedSubject: this.state.selectedSubject, backToTeacher, fromAllStudents }
                                })

                            }}><MaterialIcon icon="arrow_back" size={17} /> back</span>
                        </div>}




                    { !this.state.forAdminReportForSingleStudent &&

                        <div className="filterBlock filterBlockBox NiceScroll" id="filterInnerContainer" style={(this.state.forAdminReport || this.state.forAdminReportForSingleTeacher) && { width: "28%"/*"32%"*/, paddingLeft: "95px"/*"160px"*/ }}>
                            <div className="smText hidden-lg">
                                <h3>Filter</h3>
                                <span className="HideCross" onClick={function (evt) {
                                    $('.filterBlock').removeClass('TopZero');
                                    $('html').css({'overflow': 'auto', 'position': 'inherit'});
                                    $('.footer ').show();
                                }.bind(this)}/>
                            </div>

                            <div className="filterBoxStyle overFlowScroll-sm">
                                { false && this.state.forAdminReport == true && this.state.selectedTeacher == undefined

                                ?
                                    <div>
                                        <h3><b>Teachers</b></h3><br/>
                                        <div className="studentList" style={{ textTransform : "capitalize" }}>
                                            <ul>
                                                {this.getTeacherList()}
                                            </ul>
                                        </div>
                                    </div>

                                    :

                                    <div>

                                        {this.state.selectedTeacher && <div><h3 style={{textTransform: 'capitalize'}}><b>{this.state.selectedTeacher.name}</b></h3><br/></div>}
                                        {this.state.forAdminReport && this.getTeachersPeriodDropDown()}
                                        {this.getPeriodDropdown()}
                                        {this.backToClassAggr && this.classSelected &&
                                            <a href="#" className="keywordTag" onClick={() => {
                                                context.loadAllStudentByPeriodDetails();
                                            }}>{'< Back to ' + "'" + this.state.selectedPeriod + "'" + ' Overview'}</a>
                                        }

                                        {/* <br/> */}

                                        {false && this.backToSubjectAggr != true && this.state.backToTeacherAggr &&
                                            <a href="#" className="keywordTag" onClick={() => {
                                                this.setState({backToTeacherAggr: false, selectedTeacher: undefined})
                                            }}>{"< Back to 'All Teacher'"}</a>
                                        }

                                        {this.backToSubjectAggr &&
                                            <a href="#" className="keywordTag" onClick={() => {
                                                context.loadAllStudentDetails(context.state.selectedTeacher ? context.state.selectedTeacher.id : null, context.state.selectedTeacher ? context.state.selectedTeacher.name : null);
                                                this.backToSubjectAggr = false;
                                                this.backToClassAggr = false;
                                                this.classSelected = false;
                                                this.setState({ selectedStudent: null, selectedPeriod: 'All Students' });
                                            }}>{"< Back to 'All Students' Overview"}</a>
                                        }

                                        <hr/>

                                        {this.state.selectedTeacher && <div><h4><b>Students</b></h4><br/></div>}

                                        <div className="studentList" style={{ textTransform : "capitalize" }}>
                                            <ul>
                                                {this.getStudentList(this.state.selectedPeriod)}
                                            </ul>
                                        </div>

                                        <button className="ApplyFilter hidden-md hidden-lg" onClick={(evt) => {
                                            $('.filterBlock').removeClass('TopZero');
                                            $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                                            $('.footer ').show();
                                        }}> Apply
                                        </button>

                                    </div>
                                }

                            </div>

                        </div>
                    }

                        <div className={this.tableCssClassName} style={!this.state.forAdminReportForSingleStudent ? (this.state.forAdminReport || this.state.forAdminReportForSingleTeacher) ? {width: "71%"/*'69%'*/, marginLeft: "28%"/*'31%'*/, float: "unset", paddingBottom: "0px"} : { width:'75%', marginLeft:'23%', paddingBottom: "0px"} : {background:'#f7f8f9', width:'100%', marginLeft:'0%', width: 'auto', margin: "21px 10.5% 70px", float: "none", paddingBottom: "0px"}}>

                            <div className="listToBesslide NiceScroll  tableBoxStyle" style={ this.state.forAdminReport || this.state.forAdminReportForSingleStudent || this.state.forAdminReportForSingleTeacher ? { margin: "20px 0px" } : { margin: "20px" }}>
                                <div className="blockForlist ">
                                    {(this.state.view === 'report-card' || this.state.view === 'activities') && (/*this.state.forAdminReport === true ||*/ this.state.forAdminReportForSingleStudent === true) &&
                                        <div className="selectSubjectDropdown">
                                            <SelectFieldGroup
                                                field="selectedReport"
                                                value={this.state.selectedReport}
                                                options={[{ id: 'gradeTracker', name: 'Grade Tracker' }, { id: 'studentReport', name: 'Student Report' }]}
                                                placeholder="Select Report"
                                                onChange={onReportChange}
                                            />
                                        </div>}
                                    {!this.state.forAdminReportForSingleStudent && this.state.view === 'report-card' &&
                                    <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedType"
                                            value={this.state.selectedType}
                                            options={[{id:'assignment', name:'Teacher Assignments'}, {id:'practice', name:'Self Study'} ,{id:'cumulative', name:'Cumulative'}]}
                                            placeholder="Select Type"
                                            onChange={onChange}
                                        />
                                    </div>}
                                    {!this.state.forAdminReportForSingleStudent && !this.state.forAdminReportForSingleTeacher && !this.state.forAdminReport && this.state.view === 'report-card' && <div className="reportCardTopBar">
                                        {selectSubject}
                                    </div>}
                                    {this.state.view === 'report-card' && table}
                                    {this.state.view === 'activities' && activityTable}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(this.state.loadingReport) && <div>
                    <div className="modal-backdrop fade in"></div>
                    <div className="sk-fading-circle-main">
                        <div className="sk-fading-bar">


                            <h1>
                                {this.state.intervalcount+ '%'}
                            </h1>

                            <div id="myProgress">

                                <Progress completed={this.state.intervalcount}/>

                            </div>

                            <h2>
                                Loading please wait...
                            </h2>
                        </div>
                    </div>

                </div>     }





                <ProgressIndicator show={this.state.loading}/>
                {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
            </div>
        );
    }



    getAllSubjects() {
        let context = this;
        let subjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            subjects.push({
                id: sub,
                name: sub
            });
        });
        context.setState({
            subjects: subjects
        });
    }


    loadAttemptDetails(assignmentID, studentID, activityID){
        let context = this;
        context.showIndicator();



        return new Promise((resolve, reject) => {
            let context = this;


            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/history/{assignmentID}/student/{studentID}/activity/{activityID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            context.showProgressBar();
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideProgressBar();
                resolve(result.data);
            }).catch(function (error) {
                context.hideProgressBar();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    viewStudentDetails(student, activity) {
        let context = this;
        this.loadStudentDetails(activity.assignmentID, student.students, activity.activityID).then(result => {
            context.setState({
                showUserDetailsDialog: true,
                userRecord: result,
                userRecordActivityID: activity.activityID,
                userRecordActivityName: activity.activityName,
                userRecordActivityType: activity.activityType
            });
        }).catch(error=> {
            console.error(error);
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    loadStudentDetails(assignmentID, studentID, activityID){
        return new Promise((resolve, reject) => {
            let context = this;

            let params = {
                studentID: studentID,
                activityID: activityID,
                assignmentID:assignmentID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            context.showProgressBar();
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                context.hideProgressBar();
                resolve(result.data);
            }).catch(function (error) {
                context.hideProgressBar();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getUserDetailsDialog() {
        let record = this.state.userRecord;
        let userRecordActivityID = this.state.userRecordActivityID;
        let userRecordActivityName = this.state.userRecordActivityName;
        let actType = this.state.userRecordActivityType;

        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {cat.correctAnswers + ' out of ' + cat.total + ' questions correct (' + cat.score.toFixed(0) + '%)'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                             style={{marginRight: '10px', marginTop: '5px', background:'white'}}>
                            <div className="filled" style={{width: (cat.score ? cat.score : 0) + '%', background:(cat.score && cat.score > 64 ? 'green' : 'red')}}/>
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
            {categories}
            </tbody>
        </table>;

        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        let htmlToReactParser = new Parser();
        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer && question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr dir lang = { this.props.auth.user.prefLanguage }  className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlToReactParser.parse('<span>' + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlToReactParser.parse('<span>' + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlToReactParser.parse('<span>' + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlToReactParser.parse('<span>' + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlToReactParser.parse('<span>' + question.essayScore + '</span>') : ( question.essayScore < 65 ? (htmlToReactParser.parse('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlToReactParser.parse('<span style=" color: green; ">' + question.essayScore + '</span>')) )}
                            </p>
                        </div> : ""
                        }

                    </td>
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
            <tr>
                <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
                <td>{userRecordActivityName}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="accessibility" size={24}/> Student Name:</td>
                <td>{record.studentName}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="assessment" size={24}/> Score:</td>
                <td>{correctNumbers + ' out of ' + record.questionStats.length + ' questions correct (' +record.score.toFixed(0)}{'%)'}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="alarm" size={24}/> Time In:</td>
                <td>{parseHours(record.timeIn)}{' hours '}{parseMinutes(record.timeIn)}{' mins '}{parseSeconds(record.timeIn)}{' seconds'}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="date_range" size={24}/> Completion Date:</td>
                {record.completionDate !== "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
            </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
            <tr>
                <th style={{background: '#f7f8f9', border:'1px solid #555555'}}>
                    Questions
                </th>
                {actType === 'question' && false && <th>
                </th>}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{overflow:'auto'}}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{overflow:'auto'}}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Question Analysis </h1>
                <p style={{overflow: 'auto'}}>
                    {studentStats}
                </p>
            </div>}
        </div>;
        return <Modal id="user-detail-dialog"
                      show={this.state.showUserDetailsDialog}
                      onHide={()=> {
                          this.setState({showUserDetailsDialog: false, userRecord: null, userRecordActivityID:null});
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Student Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showUserDetailsDialog: false, userRecord: null, userRecordActivityID:null});
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    getTeachersPeriodDropDown() {
        let context = this;
        let teachers = [];
        // teachers.push({ id: 'All Teachers', name: 'All Teachers' });
        if (context.state.allTeachers) {
            // if (context.state.periods) {
            // let sorted = context.state.allTeachers.sort((a, b) => {
            //     return a.name > b.name;
            // });
            // sorted.forEach(teacher => {
            //     teachers.push({ id: teacher.id, name: teacher.name });
            // });
            context.state.allTeachers.forEach(teacher => {
                teachers.push({ id: teacher.id, name: teacher.name });
            });
            // }
        }
        return <SelectFieldGroup
            id="teacherSelect"
            field="teacherSelect"
            value={this.state.selectedTeacher ? this.state.selectedTeacher.id : undefined}
            options={teachers}
            onChange={(evt) => {

                let value = evt.target.value;
                let text = evt.target.selectedOptions[0].innerText;

                console.log(value, "<->", text);

                // context.setState({
                //     selectedTeacher: { id: value, name: text }
                // }, () => {

                    if (text !== 'All Teachers') {

                        context.state.backToTeacherAggr = true;
                        // context.state.hideLoading = true
                        context.state.disableStudentPeriodDropDown = false;
                        context.state.selectedTeacher = { id: value, name: text }

                        // context.loadAllStudentByPeriodDetails(text)
                        context.getPeriodsByTeacher(value);
                        context.getTeacherStudentReport(value);
                        context.getAllSubjects();
                        context.getStudentsByTeacher(value, true);
                        context.loadAllStudentDetails(value, text);

                    }
                    else {
                        // context.loadAllStudentDetails();
                        // this.backToSubjectAggr = false;
                        // this.backToClassAggr = false;
                        // this.classSelected = false;
                        // this.setState({selectedStudent:null});

                        context.setState({selectedTeacher: undefined, disableStudentPeriodDropDown: true, selectedStudent:null, students: []}, () => {
                            context.loadAllTeachersStudentDetails();
                        });
                    }
                // });
            }}
        />
    }

    getPeriodDropdown() {
        let context = this;
        let periods = [];
        periods.push({id:'All Students', name:'All Students'});
        if (context.state.periods) {
            let sorted = context.state.periods.sort((a, b) => {
                return a.period > b.period;
            });
            sorted.forEach(period => {
                periods.push({id:period.period, name:period.period});
            });
        }
        return <SelectFieldGroup
            id="periodSelect"
            field="periodSelect"
            value={this.state.selectedPeriod}
            isDisabled={this.state.disableStudentPeriodDropDown}
            options={periods}
            onChange={(evt)=> {

                let value = evt.target.value;
                let text = evt.target.selectedOptions[0].innerText;
                context.setState({
                    selectedPeriod: value
                }, function () {

                    if (text !== 'All Students')
                    {
                        context.loadAllStudentByPeriodDetails(text)
                    }
                    else
                    {
                        context.loadAllStudentDetails(context.state.selectedTeacher ? context.state.selectedTeacher.id : null, context.state.selectedTeacher ? context.state.selectedTeacher.name : null);
                        this.backToSubjectAggr = false;
                        this.backToClassAggr = false;
                        this.classSelected = false;
                        this.setState({selectedStudent:null});
                    }
                });
            }}
        />
    }

    showIndicator() {
        this.setState({
            //  ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            // ...this.state,
            loading: false
        });
    }

    getTeacherStudentReport(teacherID) {
        let {dispatch}= this.props;
        let context = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch,'studentAssignment'.toString(),'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{createdAt:{order: "desc"}}, []);
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{createdAt:{order: "desc"}}, [], function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords:totalRecords
                });
            }, true, 'studentID: ("'+ teacherID ? teacherID : context.props.auth.user.userName +'")', ElasticSearchTypes.StudentAssignment);
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(TeacherStudentReport);
