/**
 * Created by ashahab on 5/31/2017.
 */
import React from 'react';
import LPlogo from '../../assets/images/logo.png'
import stemLabsLogo from "./../../assets/images/stem-labs.png"
import VirtualLearningLogo from "./../../assets/images/virtual-logo.png"
import DummyPerson from '../../assets/images/dummyPerson.jpg';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types';
import { userLogoutRequest, loginLinkedAccount } from '../../actions/login-action';
import { Notifications } from '../notifications/notifications.js'
import apiClient from "../../actions/api-client";
import classnames from 'classnames';
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import config from '../../config.js'
import ClevertapReact from "clevertap-react";
import NavigationBar from "../navigation/navigation-bar";
import './header.css';
import { ElasticSearchTypes, SET_SCHOOL_LICENSE_TYPE } from '../../actions/types.js';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
        this.closeNotitcation = this.closeNotitcation.bind(this);
        this.showProfile = this.showProfile.bind(this);
        this.toggleHideLogout = this.toggleHideLogout.bind(this);
        this.getAwsCredentials = this.getAwsCredentials.bind(this);
        this.loginLinkedAccount = this.loginLinkedAccount.bind(this);
        this.getSchoolLicenses = this.getSchoolLicenses.bind(this);

        this.state = {
            allNotifications: [],
            showNotifcation: false,
            notificationCount: 0,
            hideLogout: true,
            checker: false,
            width: window.innerWidth
        };
    }

    componentDidMount() {
        this.getNotifications();
        this.getSchoolLicenses();
        // this.getAwsCredentials();
    }

    logout() {
        ClevertapReact.event('User logged out', { 'userName': this.props.auth.user.userName });
        this.toggleHideLogout();
        this.props.userLogoutRequest().then(
            (res) => browserHistory.push('/')
        );
        {
            this.props.auth.user.federatedIdentity &&
            window.location.reload(false)
        }

    }

    loginLinkedAccount() {

        this.toggleHideLogout();
        this.props.loginLinkedAccount().then(
            (res) => {
                browserHistory.push(res);
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            }
        ).catch((err) => console.log(err))

    }

    toggleHideLogout() {
        this.setState({ 'hideLogout': !this.state.hideLogout })
    }
    showProfile() {
        this.toggleHideLogout();
        if (this.props.auth.user.userType.toLowerCase() === "student") {
            browserHistory.push('/student/profile');
        } else {
            browserHistory.push('/profile');
        }

    }
    getNotifications() {

        var additionalParams = {
            queryParams: {
                show: this.state.checker,
            }
        }
        let that = this;
        apiClient.invokeApi({ userName: this.props.auth.user.userName }, '/notifications/{userName}', 'GET', additionalParams, {}, "2").then(function (result) {
            that.setState({ allNotifications: result.data.notifications, notificationCount: result.data.count });
        }).catch(function (result) {

        });
    }
    closeNotitcation() {

        this.setState({ showNotifcation: false });
    }

    getAwsCredentials() {
        AWS.config.update({ region: 'us-east-1' });

        let userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (session) {
                console.log('session validity: ' + session.isValid());
                localStorage.setItem('token', session.getIdToken().getJwtToken());
            }
        });

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                [config.cognitoURL]: localStorage.getItem('token')
            }
        });

        // AWS.config.credentials.clearCachedId();
        // We can set the get method of the Credentials object to retrieve
        // the unique identifier for the end user (identityId) once the provider
        // has refreshed itself
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.get(function (err) {
            if (err) {
                console.log("Error: " + err);
            }
            // localStorage.setItem('token', result.getIdToken().getJwtToken());
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });


        return AWS.config.credentials.getPromise();
    }

    getSchoolLicenses(){
        apiClient
          .invokeApi(
            {},
            "/getesdata",
            "POST",
            {},
            {
              payload: {
                query: {
                  term: {
                    id: this.props.auth.user.schoolID,
                  },
                },
              },
              index: "learnerpal/",
              esDocumentType: ElasticSearchTypes.School + config.stage,
            },
            "5"
          )
          .then((result) => {
            console.log(result);
            {
              let response = [];
              if (
                result.data &&
                result.data.hits &&
                result.data.hits.hits &&
                result.data.hits.hits[0] &&
                result.data.hits.hits[0]._source
              ) {
                response = result.data.hits.hits[0]._source;
              }
            this.props.dispatch({
              type: SET_SCHOOL_LICENSE_TYPE,
              schoolType: response.schoolType,
            });
            
              this.setState({ schoolType: response.schoolType });
            }
          });
    }

    render() {
        let logo = LPlogo;
        if(this.props.appSettings.theme === 'stemLabs'){
            logo = stemLabsLogo;
        } else if(this.props.appSettings.theme === 'virtual'){
            logo = VirtualLearningLogo;
        }
        return (
            <div>

                <Notifications allNotifications={this.state.allNotifications} showNotifcation={this.state.showNotifcation} closeNotification={this.closeNotitcation} />
                <div className="container-fluid">
                    <div className="row full-wdith-blue-top">
                        <div className="col-lg-12 col-md-12 col-xs-12"></div>
                    </div>
                    <div className="row">
                        <div className="topFirstBar">
                            {this.state.width > 768 && <div className="col-md-2 logo-container"> <a href="#" className="LogoAnch"><img src={logo} alt="-" className="logoClass" /></a> </div>}
                            {this.state.width <= 768 && <div className="col-xs-1 logo-container"> <a href="#" className="LogoAnch"><img src={logo} alt="-" className="logoClass" /></a> </div>}
                            {this.state.width > 768 &&
                                <div className="col-md-8">
                                    <NavigationBar />
                                </div>}
                            <div className="col-md-2 pull-right">
                                <ul className="topBarProfileIcons">
                                    {this.props.auth.user && this.props.auth.user.userType.toLowerCase() !== "student" && <li className="hidden-xs"><a href="http://www.learnerpal.com/help/" target="_blank" className="TopBarhelp" /></li>}
                                    <li ><a href="javascript:;" className="TopBarnotification" onClick={() => {
                                        this.setState({ checker: true }, () => {
                                            this.getNotifications();
                                        })
                                        // this.getNotifications();
                                        this.setState({ showNotifcation: true });
                                        console.log(this.state.notificationCount)
                                    }}><span className="badge">{this.state.notificationCount > 0 ? this.state.notificationCount : null}</span></a></li>
                                    <li className="hidden-xs">
                                        <div className="profileImgLink" onClick={this.toggleHideLogout}>
                                            <span className="personImg"><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>
                                            {/* <span className="pername">{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</span> */}
                                            {/* <span className="caret caret-close"><p>{this.props.auth.user ? (this.props.auth.user.firstName[0]).toUpperCase() + (this.props.auth.user.lastName[0]).toUpperCase() : ''}</p>
                                            </span> */}
                                            {/* <svg width="35" height="35">
                                                <circle cx="19" cy="19" r="15" stroke="#337ab7" stroke-width="2" fill="#337ab7" /><text fill="#F2F3F4" font-size="4"
                                                    x="10" y="24">{this.props.auth.user ? (this.props.auth.user.firstName[0]).toUpperCase() + (this.props.auth.user.lastName[0]).toUpperCase() : ''}</text>
                                            </svg> */}
                                            <div className={classnames("dropBoxDiv", { "removeDisplay": this.state.hideLogout })}>
                                                <ul>
                                                    <li><a href="javascript:void 0" onClick={this.showProfile}>Profile</a></li>
                                                    {this.props.auth.user && this.props.auth.user.linkedAccounts &&
                                                        <li><a href="javascript:void 0" onClick={this.loginLinkedAccount}>{this.props.auth.user.linkedAccounts.learnerPal && "Switch to LP Account" || this.props.auth.user.linkedAccounts.gmail && "Switch to Google Account"}</a></li>
                                                    }
                                                    <li><a href="javascript:void 0" onClick={this.logout}>Logout</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.width <= 768 &&
                    <div className="col-xs">
                        <NavigationBar />
                    </div>}
            </div>
        );
    }
}

Header.propTypes = {
    auth: PropTypes.object.isRequired,
    userLogoutRequest: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        appSettings: state.appSettings
    }
}
const mapDispatchToProps = dispatch => {
    return {
        userLogoutRequest, 
        loginLinkedAccount, 
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
