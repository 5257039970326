import { SET_FULLSCREEN, SET_SCHOOL_LICENSE_TYPE, SET_THEME, SET_THEME_STEM_LAB, SET_THEME_VIRTUAL, UNSET_FULLSCREEN } from '../actions/types';

const initialState = {
    isFullScreen : false,
    theme: '',
    schoolType: ''
};

export default ( state = initialState, action = {}) => {
  switch (action.type) {
    case SET_FULLSCREEN:
      return {
        ...state,
        isFullScreen : true
      };
    case UNSET_FULLSCREEN:
      return {
        ...state,
        isFullScreen : false
      };
    case SET_THEME_VIRTUAL:
      return {
        ...state,
        theme : 'virtual'
      };
    case SET_THEME_STEM_LAB:
      return {
        ...state,
        theme : 'stemLabs'
      }     
    case SET_THEME:
      return {
        ...state,
        theme : action.theme
      }     
    case SET_SCHOOL_LICENSE_TYPE:
      return {
        ...state,
        schoolType : action.schoolType
      }     
    default : return state;
  }

}
