import React from "react";
import { connect } from "react-redux";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import { RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns } from "../../../actions/types";
import classnames from "classnames";
import $ from "jquery";
import { browserHistory } from "react-router";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import ElasticSearchStore from "../../../actions/elastic-search-store";
import apiClient from "../../../actions/api-client";
import MaterialIcon from "react-google-material-icons";
import { Alert, Modal, Button } from "react-bootstrap";
import { Parser } from "html-to-react";
import SelectFieldGroup from "../../forms/select-field-group";
import Utility from "../../maintenance-table/utility";
import config from "../../../config";
import excelIcon from "../microsoft-excel.png";
import excelIconHover from "../microsoft-excebl.png";
import {ActionIcons} from "../../actionIcons/action-Icons";
import {ActionIconTypes} from "../../../actions/types";
// import "../../reports/view/view-report.css";
// // Because of student-activity-report added STUDENT-ASSIGNMNET.CSS
// import "./student-assignment.css";

// // because AnalysisStats is using css from activity-report
// import "./activity-report-clone-student-assignment.css"
// import "./activity-report-clone-view-report.css"
import "./custom.css"
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../utils/timeParser';

class CourseReportStudents extends React.Component {
    constructor(props) {

        super(props);

        this.viewStudentDetails = this.viewStudentDetails.bind(this);
        this.loadStudentDetails = this.loadStudentDetails.bind(this);
        this.loadStudentReportCard = this.loadStudentReportCard.bind(this);

        this.getPeriodsByTeacher = this.getPeriodsByTeacher.bind(this);
        this.getStudentsByTeacher = this.getStudentsByTeacher.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

        this.state = {
            screenWidth: window.innerWidth, loadingNextPage: 0, selectedType: 'assignment', allAttemptWiseActivities: [], studentActivities: [],
            cachedData: {}, currentReportName: 'Select Subject', view: 'activities', userRecord: [], currentAttempt: 0

            , dataList: [], studentsSearchData: [],
        };

        this.state.courseName = this.props.params.id;

        this.filterComponentCssClassName = 'filterBlock filterBlockBox NiceScroll';
        this.tableCssClassName = 'listViewBlock ';

        this.backToSubjectAggr = false;
        this.backToClassAggr = false;
        this.classSelected = false;

        this.exportToExcel = this.exportToExcel.bind(this);
        this.printTable = this.printTable.bind(this);

        this.params = this.props.params;

        if(this.props.location.state) {
            this.state.courseName = this.props.location.state.courseName;
            this.state.forAdminReport = this.props.location.state.forAdminReport;
            this.state.teacherForAdminReport = this.props.location.state.teacher;
            this.state.allTeachersForAdminReport = this.props.location.state.allTeachers;
            this.state.courseForAdminReport = this.props.location.state.courseForAdminReport;
            this.state.subjectForAdminReport = this.props.location.state.subjectForAdminReport;

            this.state.fromAllStudents = this.props.location.state.fromAllStudents;
        }

        console.log(this.state);
    }

    componentDidMount() {

        this.getStudentsByCourseIDAndTeacher();
        //this.getPeriodsByTeacher();
        //this.getStudentsByTeacher();
        //this.getAllSubjects();

    }

    getAllTeachers() {

        let context = this;
        if (context.state.allTeachersForAdminReport) {
            // let filtered = context.state.allTeachersForAdminReport.filter((teacher) => {
            //     return (teacher.department === context.state.department) && (teacher.id !== context.props.auth.user.userName)
            // });
            // filtered.sort(function (a, b) {
            //     return a.name.localeCompare(b.name);
            // });
            return context.state.allTeachersForAdminReport.map(teacher => {
                return <li key={teacher.id} className={context.state.teacherForAdminReport && context.state.teacherForAdminReport.id === teacher.id ? 'selectedStudentClass' : ''}>
                <style>{"\
                li:hover:not(.selectedStudentClass){\
                  background-color:#eee !important;\
                }\
              "}</style>
                    <div onClick={() => {

                        // if(context.state.teacherForAdminReport && context.state.teacherForAdminReport.id != teacher.id) {
                            context.state.teacherForAdminReport = teacher;
                            this.getCourseNameForTeacher(teacher.id)
                        // }

                        // context.setState({backToTeacherAggr: true, teacherForAdminReport: teacher})

                    }} className="studentNameLink">{teacher.name}</div>
                </li>
            });
        }
        return [];
    }

    getStudentsByCourseIDAndTeacher() {
        let context = this;

        context.showIndicator();

        let params = {
            userName: this.state.teacherForAdminReport ? this.state.teacherForAdminReport.id : this.props.auth.user.userName,
            courseID: this.state.courseForAdminReport ? this.state.courseForAdminReport.id : this.params.id
        };
        let pathTemplate = '/course/student/report/username/{userName}/courseID/{courseID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};

        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {

            let dataList = result.data;
            let allClasses = []
            allClasses.push({ id: 'all', name: 'All Classes' });
            let classes = [];

            dataList = dataList.sort((a, b) => {
                return /* String( */a.studentName/* ) */.localeCompare(b.studentName);
            });

            // these classes should be distinct and shoud have id and name and this will be used in the drop down, then filtering of the student by this
            dataList.forEach( x => {
                let TempClasses = []
                TempClasses = x.periodName.split(', ')
                TempClasses.forEach ( eachClass => {
                    if(classes.indexOf(eachClass) == -1)
                    {
                        classes.push(eachClass);
                        allClasses.push({ id: eachClass, name: eachClass})
                    }
                })
            })

            context.setState({ showAlert: false, dataList, studentsSearchData: dataList, allClasses, allClassesNames: classes, selectedClass: 'all' });

            setTimeout(function () {
                context.hideIndicator();
            }, 100);

        }).catch(function (error) {

            let responseData = [];
            let combinedMessage = '';
            if(error.response && error.response.data.errors)
            {
                for (let err of error.response.data.errors)
                    responseData.push(err.message)

                combinedMessage = responseData.join(', ')
            }


            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: responseData.length != 0 && combinedMessage ? combinedMessage : error.message,
                // alertText: 'Unable to load data, please contact administrator.'
                dataList: [], studentsSearchData: [], allClasses: [{ id: 'all', name: 'All Classes' }], allClassesNames: [], selectedClass: 'all'
            });
            // setTimeout(() => {
            //     context.setState({
            //         showAlert: false,
            //         alertStyle: '',
            //         alertText: ''
            //     })
            // }, 4000);
        });

    }


    getStudentActivities(studentID) {
        let context = this;
        context.showIndicator();
        let user = context.props.auth.user;
        // let estore = new ElasticSearchStore({esIndexName:'lp-student'});
        let teacher = user.firstName + ' ' + user.lastName;

        let elasticSearchQuery = new ElasticSearchQuery();
        return new Promise((resolve, reject) => {
            elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, context.props,
                "", 10000, 1, { dueDate: { order: "asc" } }, [], function (filterData, totalRecords) {
                    var activities = [];

                    filterData.forEach((item) => {

                        if (item.assignedActivities) {
                            activities.push({
                                assignmentID: item.assignmentID,
                                assignmentName: item.assignmentName,
                                assignmentType: item.assignmentType,
                                studentID: item.studentID,
                                teacher: item.teacher,
                                activityID: item.assignedActivities.activityID,
                                activityType: item.assignedActivities.activityType,
                                activityName: item.assignedActivities.activityName,
                                completionDate: item.createdAt,
                                progress: item.progress,
                                timeIn: item.timeIn,
                                score: item.score,
                                attemptedDate: item.attemptedDate
                            });
                        } else if (item.assignedCourses) {
                            item.assignedCourses.units.forEach(unit => {
                                unit.activities.forEach(act => {
                                    activities.push({
                                        assignmentID: item.assignmentID,
                                        assignmentName: item.assignmentName,
                                        assignmentType: item.assignmentType,
                                        studentID: item.studentID,
                                        teacher: item.teacher,
                                        activityID: act.activityID,
                                        activityName: act.activityName,
                                        activityType: act.activityType,
                                        attempts: act.attempts,
                                        completionDate: act.lastUpdated,
                                        progress: act.progress,
                                        timeIn: act.timeIn,
                                        score: act.score,
                                        attemptedDate: act.attemptedDate
                                    });
                                });
                            });
                        }
                    });
                    resolve(activities);
                }, true, 'teacher: (' + '"' + teacher + '"' + ') AND studentID: ("' + studentID + '")', 'lp-student/');
        });
    }

    loadStudentReportCard(studentID, subject) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                subject: subject,
                userName: this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/student/{studentID}/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideIndicator();
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadSelectedStudentDetails(student, type) {

        let context = this;
        // load activities data.
        if (type === 'report-card') {
            if (this.state.selectedSubject) {
                if (context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName]) {
                    context.setState({
                        currentReportName: student.stdFirstName + ' ' + student.stdLastName,
                        cumulative: context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName].cumulative,
                        practiceAssignment: context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName].practiceAssignment,
                        assignment: context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName].assignment,
                        selectedStudent: student,
                    });
                }
                else {
                    context.loadStudentReportCard(student.students, context.state.selectedSubject).then((result) => {
                        let cachedData = context.state.cachedData;
                        cachedData['byStudent' + context.state.selectedSubject + student.userName] = result;
                        context.setState({
                            cachedData,
                            currentReportName: student.stdFirstName + ' ' + student.stdLastName,
                            cumulative: result.cumulative,
                            practiceAssignment: result.practiceAssignment,
                            assignment: result.assignment,
                            selectedStudent: student,
                        });
                    }, (error) => {
                        console.error(error);
                    });
                }
            } else {
                this.setState({
                    selectedStudent: student,
                });
            }
        }
        else {
            this.getStudentActivities(student.students).then(activities => {
                context.setState({
                    loading: false,
                    studentActivities: activities,
                    selectedStudent: student
                });
                let parentActivities = [];
                activities.forEach((activity) => {
                    let index = parentActivities.findIndex(x => x.activityID === activity.activityID);
                    if (index === -1) {
                        let childActivities = [];
                        childActivities.push(activity);
                        parentActivities.push({
                            activityID: activity.activityID,
                            activities: childActivities
                        });
                    }
                    else {
                        parentActivities[index].activities.push(activity);
                    }
                });
                this.setState({ allAttemptWiseActivities: parentActivities });
            });
        }

    }

    getPeriodsByTeacher() {
        let that = this;
        let params = {
            userName: that.props.auth.user.userName
        };
        let pathTemplate = '/class/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "2").then(function (result) {
            let periods = result.data.allClasses;
            that.setState({ 'periods': periods });
        }).catch(function (result) {
            that.hideIndicator();
        });
    }

    getStudentsByTeacher() {
        let context = this;
        let params = {
            userName: this.props.auth.user.userName
        };
        let pathTemplate = '/user/students/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            result.data.sort(function (a, b) {
                return a.stdFirstName.localeCompare(b.stdFirstName);
            });
            context.setState({ students: result.data });
        }).catch(function (error) {
            context.hideIndicator();
        });
    }

    getAllSubjects() {
        let context = this;
        let subjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            subjects.push({
                id: sub,
                name: sub
            });
        });
        context.setState({
            subjects: subjects
        });
    }

    render() {
        const { screenWidth } = this.state;
        let context = this;
        let categoryData = [];
        let activities = [];
        if (this.state.selectedType === 'cumulative') {
            categoryData = context.state.cumulative || [];
        } else if (this.state.selectedType === 'practice') {
            categoryData = context.state.practiceAssignment || [];
        } else if (this.state.selectedType === 'assignment') {
            categoryData = context.state.assignment || [];
        }
        if (this.state.allAttemptWiseActivities) {
            activities = this.state.allAttemptWiseActivities;
        }
        let subjectScore = 0;
        let questions = 0;
        let correctAns = 0;

        categoryData.forEach((cat) => {
            questions += cat.totalQuestions;
            correctAns += cat.answeredCorrectly;
        });

        if (correctAns === 0 && questions === 0) {
            subjectScore = 0;
        } else {
            subjectScore = (correctAns / questions) * 100;
            subjectScore = subjectScore.toFixed(0);
        }

        categoryData = categoryData.sort((a, b) => {
            return a.category.localeCompare(b.category);
        });

        let rows = [];
        categoryData.forEach((category) => {
            let average = 0;
            let color = 'black';

            average = category.average;

            if (average.toFixed(0) < 65) {
                color = 'red';
            }
            rows.push(<tr key={Math.random()} id={category.category}>
                <td data-parent-id={category.category} data-is-expanded={false} style={{ width: '40%' }}>
                    <a href="javascript:void 0" onClick={function (evt) {
                        var parent = evt.target.parentElement.parentElement;
                        var rows = document.querySelectorAll('tr[id*=\"parent-' + parent.dataset.parentId + '\"]');
                        if (parent.dataset.isExpanded === 'false') {
                            parent.dataset.isExpanded = 'true';
                            parent.firstElementChild.lastElementChild.textContent = 'remove_circle_outline';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = '';
                            }
                        } else {
                            parent.firstElementChild.lastElementChild.textContent = 'add_circle_outline';
                            parent.dataset.isExpanded = 'false';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = 'none';
                            }
                        }
                    }.bind(this)} data-parent-id={category.category}><strong>{category.category}</strong><MaterialIcon icon="add_circle_outline" size={24} /></a>
                </td>
                <td style={{ width: '15%', color: color }}>
                    {average ? average.toFixed(0) : 0}{'%'}
                </td>
                <td style={{ width: '15%' }}>
                    {category.totalQuestions}
                </td>
                <td style={{ width: '15%' }}>
                    {category.answeredCorrectly}
                </td>
                <td style={{ width: '15%' }}>
                    {category.answeredIncorrectly}
                </td>
            </tr>);

            category.subCategories.forEach(subCat => {
                let average = 0;
                let color = 'black';

                average = subCat.average

                if (average.toFixed(0) < 65) {
                    color = 'red';
                }

                rows.push(<tr key={Math.random()} id={'parent-' + category.category + subCat.category}
                    data-category-parent-id={category.category} style={{ display: 'none' }}>
                    <td className="indentSubCat">
                        {subCat.category}
                    </td>
                    <td style={{ color: color }}>
                        {average.toFixed(0)}{'%'}
                    </td>
                    <td>
                        {subCat.totalQuestions}
                    </td>
                    <td>
                        {subCat.answeredCorrectly}
                    </td>
                    <td>
                        {subCat.answeredIncorrectly}
                    </td>
                </tr>);
            });
        });

        let actRows = [];

        for (var dataItem of this.state.studentsSearchData) {

            let {timeIn, progress, studentName, average, completedAverage, stdID} = dataItem;

            actRows.push(
                <tr key={Math.random()} id={Math.random() * 1000}>
                    <td style={{ width: '15%' }}>
                        <div className='makeMeBolder' style={{ fontStyle: 'bold',/* color: "#0f6fb4",*/ 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize '}} title={studentName}>
                            {studentName}
                        </div>
                    </td>
                    <td style={{ width: '15%' }}>
                        {progress.toFixed(0)}{'%'}
                    </td>
                    <td style={{ width: '15%' }}>
                        {(average.toFixed(0) < 65) ? <span style={{color: "red"}}>{average.toFixed(0)}{'%'}</span> : <span>{average.toFixed(0)}{'%'}</span> }
                    </td>
                    <td style={{ width: '15%' }}>
                        {/* this is coming null in 'for momin' course WHY? and NOT OTHER ///// this is handled on backend see: get CourseStudentReportByTeacher: 663 */}
                        {/* {completedAverage ? (completedAverage.toFixed(0) < 65) ? <span style={{color: "red"}}>{completedAverage.toFixed(0)}{'%'}</span> : <span>{completedAverage.toFixed(0)}{'%'}</span> : '0%' } */}
                        {(completedAverage.toFixed(0) < 65) ? <span style={{color: "red"}}>{completedAverage.toFixed(0)}{'%'}</span> : <span>{completedAverage.toFixed(0)}{'%'}</span>}

                    </td>
                    <td style={{ width: '15%' }}>
                        {timeIn ?
                            (
                                parseHours(timeIn) + ':'
                                + parseMinutes(timeIn) + ':'
                                + parseSeconds(timeIn)
                            )
                            : '0:0:0'}
                    </td>
                    <td style={{ width: '15%' }} className="viewUserDetLink">
                        <a href="javascript:void(0)" onClick={
                            function () {

                                    browserHistory.push({
                                        pathname: '/reports/course-report-students/course-report-activities/' + encodeURIComponent(this.params.id),
                                        state: {
                                            studentID: stdID, studentName: studentName, courseName: this.state.courseName,
                                            forAdminReport: this.state.forAdminReport, teacherForAdminReport: this.state.teacherForAdminReport,
                                            allTeachersForAdminReport: this.state.allTeachersForAdminReport, courseForAdminReport: this.state.courseForAdminReport, subjectForAdminReport: this.state.subjectForAdminReport
                                        }
                                    });

                            }.bind(this)
                        }>
                            <MaterialIcon icon="search" size={24} />
                        </a>
                    </td>
                </tr>
            );
        }



        let studentStats = <table className="table" id="student-attempt-history">
            <thead>
                <tr>
                    <th>
                    </th>
                    <th>
                        Average
                </th>
                    <th>
                        Total Questions
                </th>
                    <th>
                        Answered Correctly
                </th>
                    <th>
                        Answered Incorrectly
                </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        let activityStats =
        <div id="course-report-student">
        <table className="table table-hover table-bordered" id="student-attempt-history">
            <thead>
                <tr>
                    <th className="dropdown" data-activity-id='studentName' data-order="dsc" onClick={function (evt) {
                            let allData =  this.state.studentsSearchData;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData.sort(function (a, b) {
                                    return (a.studentName.localeCompare(b.studentName));
                                });
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData.sort(function (a, b) {
                                    return (b.studentName.localeCompare(a.studentName));
                                });
                            }
                            context.setState({studentsSearchData:allData});
                        }.bind(this)}>
                        Student Name <span className="caret caret-close"/>
                    </th>
                    <th className="dropup" data-activity-id='progress' data-order="asc" onClick={function (evt) {
                            let allData =  this.state.studentsSearchData;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData.sort(function (a, b) {
                                    return (a.progress - b.progress);
                                });
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData.sort(function (a, b) {
                                    return (b.progress - a.progress);
                                });
                            }
                            context.setState({studentsSearchData:allData});
                        }.bind(this)}>
                        Progress <span className="caret caret-close"/>
                    </th>
                    <th className="dropup" data-activity-id='average' data-order="asc" onClick={function (evt) {
                            let allData =  this.state.studentsSearchData;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData.sort(function (a, b) {
                                    return (a.average - b.average);
                                });
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData.sort(function (a, b) {
                                    return (b.average - a.average);
                                });
                            }
                            context.setState({studentsSearchData:allData});
                        }.bind(this)}>
                        Average <span className="caret caret-close"/>
                    </th>
                    <th className="dropup" data-activity-id='completedAverage' data-order="asc" onClick={function (evt) {
                            let allData =  this.state.studentsSearchData;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData.sort(function (a, b) {
                                    return (a.completedAverage - b.completedAverage);
                                });
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData.sort(function (a, b) {
                                    return (b.completedAverage - a.completedAverage);
                                });
                            }
                            context.setState({studentsSearchData:allData});
                        }.bind(this)}>
                        Completed Average {/*Comprehensive Average*/} <span className="caret caret-close"/>
                    </th>
                    <th className="dropup" data-activity-id='timeIn' data-order="asc" onClick={function (evt) {
                            let allData =  this.state.studentsSearchData;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData.sort(function (a, b) {
                                    return (a.timeIn - b.timeIn);
                                });
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData.sort(function (a, b) {
                                    return (b.timeIn - a.timeIn);
                                });
                            }
                            context.setState({studentsSearchData:allData});
                        }.bind(this)}>
                        Time In Course <span className="caret caret-close"/>
                    </th>
                    <th>
                        View Details
                    </th>
                </tr>
            </thead>
            <tbody>

                {actRows}

            </tbody>
        </table>
        </div>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {
                    this.state.selectedSubject &&
                    <p className="subjectScoreHeading" style={{ width: '25%' }}>
                        {this.state.selectedSubject} : <span style={{ color: subjectScore < 65 ? 'red' : 'green' }} className="subjectScoreHeadingSpan">{subjectScore === NaN ? 0 : subjectScore}{'%'}</span>
                    </p>
                }
                {
                    !this.state.selectedSubject && <p className="subjectScoreHeading" style={{ width: '25%' }}>
                        Select a Subject
                    </p>
                }
                <p style={{ marginLeft: '50px', marginTop: '-30px', fontStyle: 'italic' }}>
                    {this.state.selectedStudent ? this.state.selectedStudent.stdFirstName + ' ' + this.state.selectedStudent.stdLastName : this.state.currentReportName}
                </p>
                <p style={{ overflow: 'auto' }}>
                    {studentStats}
                </p>
            </div>
        </div>;
        let activityTable = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
        </div>;

        let onChange = function (evt) {
            this.setState({
                selectedType: evt.target.value
            })
        }.bind(context);

        let subjects = this.state.subjects;
        let nodes = [];
        if (subjects) {
            subjects.forEach(sub => {
                nodes.push({ id: sub.id, name: sub.name });
            });
        }

        let courseAnalysisReport = this.viewCourseReportStudentAnalysis();

        return (
            <div id="TeacherStudentReport">

                <div id='forCourseReport'>

                    <div className="container-fluid noPad listMainBoxWrapper " id="activity-analysis" style={{ overflowX: 'hidden' }}>

                        <Heading
                            isQuery={false}
                            customURL={ElasticSearchTypes.StudentAssignment}
                            location="studentAssignment"
                            actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                            heading="Assignment Reports"
                            isSearch={false}
                            isShowButton={false}
                            screenNameForElasticSearch={this.props.auth.user.userName}
                            allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                            breadcrumbsData={
                                this.state.forAdminReport ?
                                    [
                                        {
                                            name: 'Reports', action: () => {
                                                browserHistory.push('/reports');
                                            }
                                        },
                                        {
                                            name: "Admin Report", action: () => {
                                                browserHistory.push({
                                                    pathname: '/reports/admin-report',
                                                    state: { selectedSubject: this.state.subjectForAdminReport }
                                                })
                                            }
                                        },
                                        {
                                            name: 'Course Report - ' + this.state.teacherForAdminReport.name
                                        }
                                    ]
                                    :
                                    [
                                        {
                                            name: 'Reports', action: () => {
                                                browserHistory.push('/reports');
                                            }
                                        },
                                        {
                                            name: 'Course Report - By Student'
                                        }
                                    ]
                            }
                        />
                        {this.state.showAlert &&
                            <div className="row">
                                <div className="col-md-3 col-xs-3" />
                                <div className="col-md-6 col-xs-3">
                                    <Alert bsStyle={this.state.alertStyle}
                                        onDismiss={this.handleAlertDismiss}>
                                        {this.state.alertText}
                                    </Alert>
                                </div>
                                <div className="col-md-3 col-xs-3" />
                            </div>
                        }


                        <div id="tableFiltersAndSideView">
                            <div className={classnames("container-fluid noPad listMainBoxWrapper")}>














                                {(this.state.forAdminReportForSingleStudent || this.state.forAdminReport || this.state.forAdminReportForSingleTeacher) && <div className="backButtonDiv" style={{ marginTop: "21px" }}>

                                    <span style={{ zIndex: "1", /*marginTop: "40px"*/ }} className="backButton btn btn-default" onClick={() => {

                                        let backToTeacher = this.state.fromAllStudents ? undefined : (this.state.teacherForAdminReport ? this.state.teacherForAdminReport : undefined)
                                        let fromAllStudents = this.state.fromAllStudents;

                                        browserHistory.push({
                                            pathname: '/reports/admin-report',
                                            state: { selectedSubject: this.state.subjectForAdminReport, backToTeacher, fromAllStudents }
                                        })

                                    }}><MaterialIcon icon="arrow_back" size={17} /> back</span>
                                </div>}















                                { false && this.state.forAdminReport ?
                                    <div className="filterBlock filterBlockBox NiceScroll" id="filterInnerContainer" /* style={{ position: 'static' }} */>
                                        <div className="filterBoxStyle overFlowScroll-sm">
                                            <h3><b>Teachers</b></h3><hr />

                                            {/* <br />

                                            {this.backToSubjectAggr != true && this.state.backToTeacherAggr &&
                                                <a href="#" className="keywordTag" onClick={() => {
                                                    this.setState({ backToTeacherAggr: false, teacherForAdminReport: undefined })
                                                }}>{"< Back to 'All Teacher'"}</a>
                                            }

                                            <hr /> */}

                                            <div className="studentList" style={{ textTransform: "capitalize" }}>
                                                <ul>
                                                    {this.getAllTeachers()}
                                                </ul>
                                            </div>
                                        </div>
                                    </div> : null
                                }



                                {/*added inline css below */}
                                {/* <div className={this.tableCssClassName} style={{background:'#f7f8f9', width:'75%', marginLeft:'23%'}}> */}

                                {/* style={{ marginLeft: '2%', marginRight: '2%', width: '96%' }} */}



                                {/* <div className={this.tableCssClassName} style={{ marginLeft: '0%', marginRight: '0%', width: '100%' }}>
                            <div className="listToBesslide NiceScroll  tableBoxStyle" >
                                <div className="blockForlist "> */}



                                <div id="submission" className="tab-pane fade active in">

                                    <div id="contentPanel" style={ false && this.state.forAdminReport ? {
                                        padding: '35px',
                                        // marginLeft: 'unset',
                                        marginTop: "21px",
                                        // marginRight: "15px",
                                        // width: 'auto',
                                        // float: 'unset',
                                        border: '1px solid #ddd',
                                        width: "75%",
                                        marginLeft: "24%"
                                     } : {
                                        margin: '1.5% 10.5% 5%',
                                        width: '80%',
                                        padding: '35px',
                                        border: '1px solid rgb(204, 204, 204)',

                                        float: 'unset'

                                        //margin: '1.5% 10.5% 5%',
                                        //width: '80%',
                                        //padding: '10px 35px',
                                        //border: '1px solid rgb(204, 204, 204)'

                                        //marginLeft: '10%',
                                        //marginRight: '10%',
                                        //width: '80%',
                                        //padding: '0px 35px 35px',
                                    }}>


                                        <div className="selectSubjectDropdown" style={{ marginTop: "0px" }}>
                                            <SelectFieldGroup
                                                field="selectedByStudentOrByActivity"
                                                value={this.state.selectedByStudentOrByActivity}
                                                options={[{ id: 'by-student', name: 'By Student' }, { id: 'by-activity', name: 'By Activity' }]}
                                                onChange={function (event) {
                                                    let value = event.target.value;
                                                    context.setState({ selectedByStudentOrByActivity: value }, function () {
                                                        if (context.state.selectedByStudentOrByActivity === 'by-activity') {
                                                            console.info('render by activity');
                                                            {/* browserHistory.push({
                                                            pathname: '/reports/course-report-activities/' + encodeURIComponent(this.params.id)
                                                        }); */}

                                                            {/* TODO */ }

                                                            browserHistory.push({
                                                                pathname: '/reports/course-report-activity-report/' + encodeURIComponent(this.params.id),
                                                                state: {
                                                                    CourseInfoBoxByStudent: this.state.CourseInfoBoxByStudent,
                                                                    courseName: this.state.courseName, forAdminReport: this.state.forAdminReport, teacherForAdminReport: this.state.teacherForAdminReport,
                                                                    allTeachersForAdminReport: this.state.allTeachersForAdminReport, courseForAdminReport: this.state.courseForAdminReport, subjectForAdminReport: this.state.subjectForAdminReport
                                                                }
                                                            });

                                                        } else if (context.state.selectedByStudentOrByActivity === 'by-student') {
                                                            console.info('render by student');
                                                        }
                                                    });
                                                }.bind(this)}
                                            />
                                        </div>
                                        <div className="selectSubjectDropdown" style={{ marginTop: "0px" }}>
                                            <SelectFieldGroup
                                                field="selectedPeriod"
                                                value={this.state.selectedClass}
                                                // ALL CLASSES WORK AS DONE IN ACTIVITYREPORT.JS
                                                options={this.state.allClasses ? this.state.allClasses : [{ id: 'all', name: 'All Classes' }]}
                                                //options={[{ id: 'all', name: 'All Classes' }, { id: 'period1', name: 'Period 1' }, { id: 'period2', name: 'Period 2' }, { id: 'period3', name: 'Period 3' }]}
                                                //placeholder="Select Period"
                                                onChange={function (event) {
                                                    let value = event.target.value;
                                                    context.setState({ selectedClass: value }, function () {

                                                        let searchTerm = value//.toLowerCase();
                                                        let searchResult = [];
                                                        let data = context.state.dataList;

                                                        if (searchTerm === "" || searchTerm === "all") {
                                                            searchResult = data;
                                                        }
                                                        else {
                                                            if (data.length > 0) {
                                                                for (let i = 0; i < data.length; i++) {
                                                                    if (data[i] && data[i].periodName) {

                                                                        if (data[i].periodName.indexOf(searchTerm) != -1)
                                                                            searchResult.push(data[i]);

                                                                    }
                                                                }
                                                            }
                                                        }
                                                        context.setState({ studentsSearchData: searchResult }, function () {
                                                            {/* if (context.state.contentView === 'classes') {
                                                                    if (value === 'all') {
                                                                        context.loadClassWiseDetails(context.state.currentActivity.activityID);
                                                                    }
                                                                    else {
                                                                        context.loadClassWiseDetails(context.state.currentActivity.activityID, value);
                                                                    }
                                                                } */}
                                                        });
                                                    });
                                                }.bind(this)}
                                            />
                                        </div>


                                        <div className="" id="gray-box-container">
                                            <div id="ReportCardTableStudent" style={{ background: 'white' }}>
                                                {courseAnalysisReport}
                                            </div>
                                        </div>

                                        {this.state.view === 'report-card' && table}
                                        {this.state.view === 'activities' && activityTable}


                                    </div>
                                </div>


                                {/* </div>
                            </div>
                        </div> */}

                            </div>
                        </div>
                    </div>
                    {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
                    {this.state.showCourseDialog && this.getCoursesDialog()}
                    <ProgressIndicator show={this.state.loading} />
                </div>
            </div>
        );
    }

    getCourseNameForTeacher(teacherID) {

        this.showIndicator();

        // limit is 1000, change it if any issue arises

        let visibility = '(public OR "' + teacherID + '"';//this.props.auth.user.userName;
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0,subjectsToSearch.length-3); console.log(subjectsToSearch);

        let query = "visibleTo : "+visibility+" AND subject : " + subjectsToSearch+")";

        //LoginUtils.isAuthenticated(this.props, browserHistory, '/courses', '/');
        //const {dispatch} = this.props;
        //ElasticSearchActions.setElasticSearchAttributes(dispatch,"courses",'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):1000, 1 ,{_score:{order: "desc"}}, []);
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Course+config.stage, ElasticSearchColumns.Course, this.props,
            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):1000, 1 ,{_score:{order: "desc"}}, [], (results, totalRecords) => {

                    // this.state.coursesOfUser = results;
                    this.setState({coursesOfUser: results, showCourseDialog: true});

                this.hideIndicator();

            }, true, query);
    }

    getCoursesDialog() {

        let courses = this.state.coursesOfUser;

        courses.sort((a,b) =>
        {
            if(a.id < b.id) return -1;
            if(a.id > b.id) return 1;
            return 0;
        })

        let nodes = [];

        if (courses) {
            courses.forEach(sub => {
                nodes.push({ id: sub.id, name: sub.courseName });
            });
        }

        let context = this;
        let body = <div>
            <label style={{ padding: '10px' }}>Please select a Course for Admin Report:</label>
            <SelectFieldGroup
                field="selectedCourse"
                value={this.state.selectedCourse ? this.state.selectedCourse.id : null}
                //options={this.state.coursesOfUser}
                options={nodes}
                placeholder="Select a Course"
                onChange={(evt) => {
                    context.setState({
                        selectedCourse: {id: evt.target.value, name: evt.target.selectedOptions[0].innerText}
                    })
                }}
            />
        </div>;

        return <Modal id="hint_modal"
            show={this.state.showCourseDialog}
            onHide={() => {
                this.setState({ showCourseDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select Course for Admin Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {

                    if (this.state.selectedCourse && this.state.selectedCourse !== 'none') { console.log(this.state);

                        this.setState({ showCourseDialog: false, courseForAdminReport: this.state.selectedCourse }, this.getStudentsByCourseIDAndTeacher);
                    }
                }} bsStyle='primary'>Run</Button>
            </Modal.Footer>
        </Modal>;
    }

    viewCourseReportStudentAnalysis() {

        let context = this;

        console.count('Rendering-->')

        let passedStudents = 0;
        let failedStudents = 0;
        let unStartedStudents = 0;
        let completedStudents = 0;
        let inProgressStudents = 0;
        let totalStudentsAssigned = 0;
        let averageScore = 0;
        let averageTime = 0;
        let averageScoreCompletedActivities = 0;



        let searchStudentsData = this.state.studentsSearchData;
        let totalNoOfStudents = this.state.studentsSearchData.length;
        let totalNoOfStudentsCompleted = 0;
        let totalAverage = 0;
        let totalCompletedAverage = 0;
        let totalTimeIn = 0;


        let totalStudentsActivities = 0
        let totalWeightageScore = 0;
        let totalStudentsCompletedActivities = 0
        let totalWeightageCompletedScore = 0;

        // if(false)
        if (searchStudentsData.length != 0) {
            // searchStudentsData.forEach( studentData => {
            for (let studentData of searchStudentsData) {

                if (studentData.average >= 65 && studentData.progress >= 1) //&& studentData.progress == 100)
                    passedStudents++
                else if (studentData.average < 65 && studentData.progress >= 1) // && studentData.progress == 100)
                    failedStudents++;
                else {
                    console.error('There is something wrong with the scores of the student')
                }

                if (studentData.progress == 0) //&& studentData.timeIn == 0)
                    unStartedStudents++;

                if (studentData.progress == 100) {
                    completedStudents++;
                    totalNoOfStudentsCompleted++;
                }

                if (studentData.progress > 0 && studentData.progress < 100)
                    inProgressStudents++;

                totalStudentsAssigned = totalNoOfStudents;

                totalAverage += studentData.average ? studentData.average : 0;
                totalCompletedAverage += studentData.completedAverage ? studentData.completedAverage : 0;
                totalTimeIn += studentData.timeIn ? studentData.timeIn : 0;

                totalStudentsActivities += studentData.totalNoOfActivities;
                totalWeightageScore += studentData.average * studentData.totalNoOfActivities;

                totalStudentsCompletedActivities += studentData.totalNoOfCompletedActivities;
                totalWeightageCompletedScore += studentData.completedAverage * studentData.totalNoOfCompletedActivities;

                // })
            }

            averageTime = totalNoOfStudents ? totalTimeIn / totalNoOfStudents : 0;

            // averageScore = totalNoOfStudents ? totalAverage / totalNoOfStudents : 0;
            averageScore = totalStudentsActivities ? totalWeightageScore / totalStudentsActivities : 0;

            // averageScoreCompletedActivities = totalNoOfStudentsCompleted ? totalCompletedAverage / totalNoOfStudentsCompleted : 0;
            averageScoreCompletedActivities = totalStudentsCompletedActivities ? totalWeightageCompletedScore / totalStudentsCompletedActivities : 0;



            if(this.state.selectedClass == 'all') {
                this.state.allClassesPassStudents = passedStudents;
                this.state.allClassesFailStudents = failedStudents;
                this.state.allClassesUnStartedStudents = unStartedStudents;
                this.state.allClassesCompletedStudents = completedStudents;
                this.state.allClassesInProgressStudents = inProgressStudents;
                this.state.allClassesTotalStudents = totalStudentsAssigned;
                this.state.allClassesTime = averageTime;
                this.state.allClassesAverage = averageScore;
                this.state.allClassesCompletedAverage = averageScoreCompletedActivities;
            }


            let CourseInfoBoxByStudent = {}

            CourseInfoBoxByStudent.passedStudents = this.state.allClassesPassStudents;
            CourseInfoBoxByStudent.failedStudents = this.state.allClassesFailStudents;
            CourseInfoBoxByStudent.unStartedStudents = this.state.allClassesUnStartedStudents;
            CourseInfoBoxByStudent.completedStudents = this.state.allClassesCompletedStudents;
            CourseInfoBoxByStudent.inProgressStudents = this.state.allClassesInProgressStudents;
            CourseInfoBoxByStudent.totalStudentsAssigned = this.state.allClassesTotalStudents;
            CourseInfoBoxByStudent.averageTime = this.state.allClassesTime;
            CourseInfoBoxByStudent.averageScore = this.state.allClassesAverage;
            CourseInfoBoxByStudent.averageScoreCompletedActivities = this.state.allClassesCompletedAverage;



            // context.setState({ CourseInfoBoxByStudent })
            context.state.CourseInfoBoxByStudent = CourseInfoBoxByStudent;
        }


        let analysisStats = <table className="table" id="act-stat">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24}/> Average <span style={{ fontWeight: "normal" }}>(Assigned Activities)</span>:<a className = "Activity-toolTip toolTipCus tooltip-pos"><span className = "tooltiptext"style={{ fontWeight: 'normal' }}>This score is based on the average of all the scores of all assigned activities. Incomplete or un-started activities count towards this average as 0%</span></a></td>
                    <td><span className='fontSizeOfTableValues'>{averageScore ? averageScore < 65 ? <span style={{ color:'red' }}>{averageScore.toFixed(0)}{'%'}</span> : averageScore.toFixed(0)+'%' : <span style={{ color:'red' }}>0%</span>}</span></td>
                    <td><MaterialIcon icon="timer" size={24}/><strong> Period:</strong></td>
                    <td><span className='fontSizeOfTableValues'>{this.state.selectedClass == 'all' ? this.state.allClassesNames.length > 0 ? this.state.allClassesNames.join(", ") : <span className="text-danger">Not Assigned To Any Class</span> : this.state.selectedClass }</span></td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24}/> Completed Average <span style={{ fontWeight: 'normal' }}>(Completed Activities)</span>:<a className = "Activity-toolTip toolTipCus tooltip-pos"><span className = "tooltiptext"style={{ fontWeight: 'normal' }}>This score is based on only activities that were completed</span></a></td>
                    <td><span className='fontSizeOfTableValues'>{averageScoreCompletedActivities ? averageScoreCompletedActivities < 65 ? <span style={{ color:'red' }}>{averageScoreCompletedActivities.toFixed(0)}{'%'}</span> : averageScoreCompletedActivities.toFixed(0)+'%' : <span style={{ color:'red' }}>0%</span>}</span></td>
                    <td width="25%"><MaterialIcon icon="supervisor_account" size={24}/><strong> Total Students Assigned:</strong></td>
                    <td width="20%"><span className='fontSizeOfTableValues'>{totalStudentsAssigned}</span></td>
                </tr>
                <tr>
                    <td width="25%"><MaterialIcon icon="done" size={24}/><strong> Passing Students:</strong></td>
                    <td width="20%"><span className='fontSizeOfTableValues'>{passedStudents}</span></td>
                    <td width="25%"><MaterialIcon icon="highlight_off" size={24}/><strong> Completed Students:</strong></td>
                    <td width="20%"><span className='fontSizeOfTableValues'>{completedStudents}</span></td>
                </tr>
                <tr>
                    <td width="25%"><MaterialIcon icon="highlight_off" size={24}/><strong> Failing Students:</strong></td>
                    <td width="20%"><span className='fontSizeOfTableValues'>{failedStudents}</span></td>
                    <td><MaterialIcon icon="alarm" size={24}/><strong> In-Progress Students:</strong></td>
                    <td><span className='fontSizeOfTableValues'>{inProgressStudents}</span></td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24}/> Average Time In Course:</td>
                    <td><span className='fontSizeOfTableValues'>{averageTime ?
                        (
                            parseHours(averageTime) + ' hours '
                            + parseMinutes(averageTime) + ' mins '
                            + parseSeconds(averageTime) + ' seconds'
                        )
                        : 'N/A'}</span></td>
                    <td width="25%"><MaterialIcon icon="eject" size={24}/><strong> Un-started Students:</strong></td>
                    <td width="20%"><span className='fontSizeOfTableValues'>{unStartedStudents}</span></td>
                    {/* <td width="35%"><MaterialIcon icon="eject" size={24}/><strong> # of incomplete students:</strong></td>
                    <td width="10%">{incompleteStudents}</td> */}
                </tr>
            </tbody>
        </table>;


        return (
            <div id="student-activity-analysis" className="row" style={{ zIndex: '-100' }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div>
                        <span className="boardheadblff1 makeMyFontBig marginBottom10px">{this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName}</span>
                        <br />
                        <span className="boardheadblff1 makeMyFontSmall">{this.state.teacherForAdminReport ? this.state.teacherForAdminReport.name : this.props.auth.user.firstName + ' ' + this.props.auth.user.lastName}</span>
                    </div>

                    <div>
                        <b style={{ 'font-size': "17px" }}>Course Information</b>
                        <p style={{ overflow: 'auto' }}>
                            {analysisStats}
                        </p>
                    </div>
                </div>


                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{marginTop: '-12px', "text-align": "right", marginBottom: "5px"}}>
                    {/* <a className="boardheadblff noHover">Students Analysis of Course: {this.params.id.slice(0, this.params.id.indexOf('_'))}</a> */}
                    <span onMouseOver={(event)=>{event.target.firstElementChild.src = excelIconHover;}} onMouseOut={(event)=>{event.target.firstElementChild.src = excelIcon;}}
                          className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                          onClick={()=>{
                              this.exportToExcel('course-report-student', true);
                          }}>
                            <img src={excelIcon} style={{width:'43px'}} />
                        </span>
                    <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                        context.printTable('course-report-student', true);
                    }}/>
                </div>


            </div>
        );
    }



    exportToExcel(tableID, removeLastHeader)
    {
        //EXPORT TO EXCEL
        let data_type = 'data:application/vnd.ms-excel';
        let table_div = document.getElementById(tableID).cloneNode(true);

        if(removeLastHeader)
        {
            table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
        }
        for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
        {
            for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
            {
                if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
                {
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                }
            }
        }
        let table_html = table_div.outerHTML.replace(/ /g, '%20');
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = data_type + ', ' + table_html;
        // a.download = 'Student Activity Analysis-' + this.state.currentActivity.activityName + '.xls';
        a.download = 'Course Report - ' + (this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName) + '.xls';
        a.click();
    }

    printTable(tableID, removeLastHeader)
    {
        //PRINT TABLE
        let table_div = document.getElementById(tableID)?document.getElementById(tableID).cloneNode(true):null;
        if (table_div)
        {
            if(removeLastHeader)
            {
                table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
            }
            for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
            {
                for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
                {
                    if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
                    {
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                    }
                }
            }
            let myWindow = window.open('', 'PRINT', 'height=600,width=800');
            myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
            myWindow.document.write('<h1>' + 'Course Report - ' + (this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName) + '</h1>');
            myWindow.document.write(table_div.outerHTML);
            myWindow.document.write('</body></html>');
            myWindow.focus(); // necessary for IE >= 10*/
            setTimeout(function () {
                myWindow.print();
                myWindow.document.close(); // necessary for IE >= 10
                myWindow.close();
            }, 1000);
        }
        else
        {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Content not available, can not print. Please contact administrator.'
            });
            setTimeout(()=> {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }
    }




    viewStudentDetails(allActivites, student, index = 0) {
        let context = this;
        this.showIndicator();
        this.loadStudentDetails(allActivites[index].assignmentID, student.students, allActivites[index].activityID).then(result => {
            context.hideIndicator();
            let userRecord = context.state.userRecord;
            for (let z = 0; z < result.length; z++) {
                if (result[z].completionDate !== 'N/A') {
                    userRecord.push(result[z]);
                }
            }
            context.setState({
                userRecord: userRecord,
                userRecordActivityID: allActivites[index].activityID,
                userRecordActivityName: allActivites[index].activityName,
                userRecordActivityType: allActivites[index].activityType,
                currentActivity: allActivites[index]
            }, function () {
                index++;
                if (index < allActivites.length) {
                    context.viewStudentDetails(allActivites, student, index);
                }
                else {
                    if (context.state.userRecord.length > 0) {
                        context.setState({ showUserDetailsDialog: true });
                    }
                    else {
                        context.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: student.stdFirstName + ' ' + student.stdLastName + ' has not started/completed this activity'
                        });
                        setTimeout(() => {
                            context.setState({
                                showAlert: false,
                                alertStyle: '',
                                alertText: ''
                            })
                        }, 4000);
                    }
                }
            });
        }).catch(error => {
            console.error(error);
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            context.hideIndicator();
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    loadStudentDetails(assignmentID, studentID, activityID) {

        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                activityID: activityID,
                assignmentID: assignmentID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getUserDetailsDialog() {
        let context = this;

        let record = this.state.userRecord[this.state.currentAttempt];
        let userRecordActivityID = this.state.userRecordActivityID;
        let userRecordActivityName = this.state.userRecordActivityName;
        let actType = this.state.userRecordActivityType;

        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {/*{cat.correctAnswers + ' out of ' + cat.total + ' questions correct (' + cat.score.toFixed(0) + '%)'}*/}
                        {cat.score.toFixed(0) + '% (' + cat.correctAnswers + ' out of ' + cat.total + ' questions correct)'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                            style={{ marginRight: '10px', marginTop: '5px', background: 'white', width: '100%' }}>
                            <div className="filled" style={{ width: (cat.score ? cat.score : 0) + '%', background: (cat.score && cat.score > 64 ? 'green' : 'red'), height: '9px', borderRadius: '5px' }} />
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
                {categories}
            </tbody>
        </table>;

        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        let htmlToReactParser = new Parser();
        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer && question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr dir lang = { this.props.auth.user.prefLanguage }  className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlToReactParser.parse('<span>' + question.essayScore + '</span>') : ( question.essayScore < 65 ? (htmlToReactParser.parse('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlToReactParser.parse('<span style=" color: green; ">' + question.essayScore + '</span>')) )}
                            </p>
                        </div> : ""
                        }

                    </td>
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{userRecordActivityName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="accessibility" size={24} /> Student Name:</td>
                    <td>{record.studentName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Score:</td>
                    {/*<td>{correctNumbers + ' out of ' + (record.questionStats? record.questionStats.length:0)  + ' questions correct (' +record.score.toFixed(0)}{'%)'}</td>*/}
                    <td>{record.score.toFixed(0) + '% (' + correctNumbers + ' out of ' + (record.questionStats ? record.questionStats.length : 0) + ' questions correct)'}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Time In:</td>
                    <td>{parseHours(record.timeIn)}{' hours '}{parseMinutes(record.timeIn)}{' mins '}{parseSeconds(record.timeIn)}{' seconds'}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="date_range" size={24} /> Completion Date:</td>
                    {record.completionDate !== "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                    {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
                </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr>
                    <th style={{ background: '#f7f8f9', border: '1px solid #555555' }}>
                        Questions
                </th>
                    {actType === 'question' && false && <th>
                    </th>}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h1 className="reportHeading">Student Question Analysis </h1>
                    <p style={{ overflow: 'auto' }}>
                        {studentStats}
                    </p>
                </div>}
        </div>;

        let attemptsArray = [];
        let allStudentsData = this.state.userRecord;

        allStudentsData.sort(function (a, b) {
            return new Date(b.completionDate) - new Date(a.completionDate);
        });
        let counter = 0;

        allStudentsData.forEach(attempt => {
            attemptsArray.push({ id: counter, name: new Date(attempt.completionDate).toLocaleString() });
            counter++;
        });
        return <Modal id="user-detail-dialog"
            show={this.state.showUserDetailsDialog}
            onHide={() => {
                this.setState({ showUserDetailsDialog: false, userRecord: [], userRecordActivityID: null });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Student Details</Modal.Title>
                {attemptsArray.length > 1 && <div className="col-md-4" style={{ marginTop: '20px' }}>
                    <SelectFieldGroup
                        field="currentAttempt"
                        value={this.state.currentAttempt}
                        options={attemptsArray}
                        onChange={(event) => {
                            context.setState({ currentAttempt: event.target.value });
                        }}
                    />
                </div>}
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showUserDetailsDialog: false, userRecord: [], userRecordActivityID: null });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    handleAlertDismiss(e) {
        this.setState({'showAlert': false});
    }

}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(CourseReportStudents);
