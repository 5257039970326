import AWS from 'aws-sdk';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser
} from 'amazon-cognito-identity-js';
import config from '../../../config.js'

export async function s3Upload(file, ownerName) {

  await getAwsCredentials();

  const s3 = new AWS.S3({
    params: {
      Bucket: config.dataBucket//'learnerpal-data',
    }
  });
  const filename = ownerName + "/" + Date.now() + "-" + file.name;

  return s3.upload({
    Bucket: config.dataBucket,//'learnerpal-data',
    Key: filename,
    Body: file,
    ContentType: file.type,
    ACL: config.dataBucket == 'learnerpal-dev-data' ? '' : 'public-read',
  }).promise();
}

export function getAwsCredentials() {
  AWS.config.update({ region: 'us-east-1' });

  const userObj = JSON.parse(localStorage.getItem('userObj'));

  if (userObj.federatedIdentity) {
    const token = localStorage.getItem('identityToken');
    const access_token = localStorage.getItem('access_token');

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.identityPoolId,
      Logins: {
        'accounts.google.com': token
      }
    });
    AWS.config.region = config.region;
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials.get(function (err, data) {
      if (err) {
        console.log("Error: " + err);
      }
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('identityToken', token);
      localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
      localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
      localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
    });

    return AWS.config.credentials.getPromise();
  } else {
    let userPool = new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.clientId
    });
    let cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession(function (err, session) {
      if (session) {
        console.log('session validity: ' + session.isValid());
        localStorage.setItem('token', session.getIdToken().getJwtToken());
      }
    });

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.identityPoolId,
      Logins: {
        [config.cognitoURL]: localStorage.getItem('token')
      }
    });
    AWS.config.credentials.clearCachedId();
    return AWS.config.credentials.getPromise();
  }

}
