import React from "react";
import { connect } from "react-redux";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import { RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns } from "../../../actions/types";
import classnames from "classnames";
import $ from "jquery";
import { browserHistory } from "react-router";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import ElasticSearchStore from "../../../actions/elastic-search-store";
import apiClient from "../../../actions/api-client";
import MaterialIcon from "react-google-material-icons";
import { Alert, Modal, Button } from "react-bootstrap";
import { Parser } from "html-to-react";
import SelectFieldGroup from "../../forms/select-field-group";
import Utility from "../../maintenance-table/utility";

import excelIcon from "../microsoft-excel.png";
import excelIconHover from "../microsoft-excebl.png";
import {ActionIcons} from "../../actionIcons/action-Icons";
import {ActionIconTypes} from "../../../actions/types";
// import "../../reports/view/view-report.css";
// // Because of this I have added a STUDENT-ASSIGNMNET.CSS
// import "./student-assignment.css";
import "./custom.css";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../utils/timeParser';

class CourseReportActivities extends React.Component {
    constructor(props) {
        super(props);

        this.viewStudentDetails = this.viewStudentDetails.bind(this);
        this.loadStudentDetails = this.loadStudentDetails.bind(this);
        this.loadStudentReportCard = this.loadStudentReportCard.bind(this);
        this.getTeacherStudentReport = this.getTeacherStudentReport.bind(this);
        this.loadAttemptDetails = this.loadAttemptDetails.bind(this);
        this.getPeriodsByTeacher = this.getPeriodsByTeacher.bind(this);
        this.getStudentsByTeacher = this.getStudentsByTeacher.bind(this);
        this.getStudentList = this.getStudentList.bind(this);
        this.getPeriodDropdown = this.getPeriodDropdown.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.loadAllStudentReportCard = this.loadAllStudentReportCard.bind(this);

        this.loadAllStudentDetails = this.loadAllStudentDetails.bind(this);

        this.loadAllStudentByPeriodReportCard = this.loadAllStudentByPeriodReportCard.bind(this);
        this.loadAllStudentByPeriodDetails = this.loadAllStudentByPeriodDetails.bind(this);

        this.state = {
            screenWidth: window.innerWidth, loadingNextPage: 0, selectedType: 'assignment', allAttemptWiseActivities: [], studentActivities: [],
            cachedData: {}, currentReportName: 'Select Subject', view: 'activities', userRecord: [], currentAttempt: 0
        };

        this.state.courseName = this.props.params.id;

        this.filterComponentCssClassName = 'filterBlock filterBlockBox NiceScroll';
        this.tableCssClassName = 'listViewBlock ';

        this.backToSubjectAggr = false;
        this.backToClassAggr = false;
        this.classSelected = false;

        this.params = this.props.params;

        if (this.props.location.state) {
            this.state.studentID = this.props.location.state.studentID;
            this.state.studentName = this.props.location.state.studentName;
            this.state.courseName = this.props.location.state.courseName;
            this.state.forAdminReport = this.props.location.state.forAdminReport;
            this.state.teacherForAdminReport = this.props.location.state.teacherForAdminReport;
            this.state.allTeachersForAdminReport = this.props.location.state.allTeachersForAdminReport;
            this.state.courseForAdminReport = this.props.location.state.courseForAdminReport;
            this.state.subjectForAdminReport = this.props.location.state.subjectForAdminReport;
        }

        console.log(this.state);
    }

    componentDidMount() {
        // this.getPeriodsByTeacher();
        // // this.getTeacherStudentReport();
        // this.getStudentsByTeacher();
        // this.getAllSubjects();
        // // this.loadAllStudentDetails();


        // let demoStudent = {
        //     className: "Period 1",
        //     enrollmentCode: "jpulver1331",
        //     password: "N/A",
        //     stdFirstName: "jkk1",
        //     stdLastName: "sss",
        //     students: "jk1",
        //     teacherFirstName: "jen",
        //     teacherLastName: "pulver",
        //     teacherName: "jen",
        //     userEmail: "s@gmail.com",
        //     userName: "jen_period1_jk1"
        // };

        // this.loadSelectedStudentDetails(demoStudent, 'activities')

        this.loadSelectedStudentDetails(this.state.studentID)

    }


    getAssignmentIDsByCourseNameAndByTeacher(studentID) {
        let that = this;
        return new Promise((resolve, reject) => {
            let params = {
                userName: that.state.teacherForAdminReport ? that.state.teacherForAdminReport.id : that.props.auth.user.userName,
                courseID: that.state.courseForAdminReport ? that.state.courseForAdminReport.id : that.params.id,
                studentID,
                // wantAssignmentIDs: true
            };
            // let pathTemplate = '/course/assignmentIDsAndAllActivities/report/username/{userName}/courseID/{courseID}/wantAssignmentIDs/{wantAssignmentIDs}/studentID/{studentID}';
            let pathTemplate = '/course/assignmentIDsAndAllActivities/report/username/{userName}/courseID/{courseID}/studentID/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            let context = this;
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {
                context.state.courseStarted = result.data.courseStarted;
                // let assignmentIDs = result.data.assignmentIDs;
                // let queryStringWithAssignments = '';
                // if (assignmentIDs) {
                //     queryStringWithAssignments = ' AND ('
                //     assignmentIDs.forEach((x, index) => {
                //         if (index == assignmentIDs.length - 1)
                //             queryStringWithAssignments += ' assignmentID: (' + x + ') )'
                //         else
                //             queryStringWithAssignments += ' assignmentID: (' + x + ') OR'
                //     })
                // }
                let data = {
                    activities: result.data.activities,
                    // query: queryStringWithAssignments
                };
                resolve(data);
            }
            , (err) => {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: err.response.data.errors.map(e => e.message).join(', ')
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject({activities: []});
            }
            ).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject({ ...error, activities: []});
            });

        })
    }


    async getStudentActivities(studentID) {
        let context = this;
        context.showIndicator();
        // let user = context.props.auth.user;
        // let estore = new ElasticSearchStore({esIndexName:'lp-student'});
        // let teacher = this.state.teacherForAdminReport ? this.state.teacherForAdminReport.name : user.firstName + ' ' + user.lastName;

        let assignmentActivityResult = await this.getAssignmentIDsByCourseNameAndByTeacher(studentID).catch(err => err);
        context.hideIndicator();

        // let elasticSearchQuery = new ElasticSearchQuery();
        return new Promise((resolve, reject) => {

            // if (!assignmentActivityResult.query) {
            //     reject(null)
            //     return;
            // }

            // elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, context.props,
            //     "", 10000, 1, { dueDate: { order: "asc" } }, [], function (filterData, totalRecords) {
                    var activities = [];

                    if(assignmentActivityResult.activities) {
                        activities = assignmentActivityResult.activities;
                    }

                    // filterData.forEach((item) => {

                    //     if (item.assignedActivities) {
                    //         activities.push({
                    //             assignmentID: item.assignmentID,
                    //             assignmentName: item.assignmentName,
                    //             assignmentType: item.assignmentType,
                    //             studentID: item.studentID,
                    //             teacher: item.teacher,
                    //             activityID: item.assignedActivities.activityID,
                    //             activityType: item.assignedActivities.activityType ? item.assignedActivities.activityType : item.activityType,
                    //             activityName: item.assignedActivities.activityName,
                    //             completionDate: item.createdAt,
                    //             progress: item.progress,
                    //             timeIn: item.timeIn,
                    //             score: item.score,
                    //             attempts: item.attempts,
                    //             attemptedDate: item.attemptedDate
                    //         });
                    //     } else if (item.assignedCourses) {
                    //         item.assignedCourses.units.forEach(unit => {
                    //             unit.activities.forEach(act => {
                    //                 activities.push({
                    //                     assignmentID: item.assignmentID,
                    //                     assignmentName: item.assignmentName,
                    //                     assignmentType: item.assignmentType,
                    //                     studentID: item.studentID,
                    //                     teacher: item.teacher,
                    //                     activityID: act.activityID,
                    //                     activityName: act.activityName,
                    //                     activityType: act.activityType,
                    //                     attempts: act.attempts,
                    //                     completionDate: act.lastUpdated,
                    //                     assignmentCreatedDate: item.createdAt,
                    //                     progress: act.progress,
                    //                     timeIn: act.timeIn,
                    //                     score: act.score,
                    //                     attemptedDate: act.attemptedDate //item.attemptedDate
                    //                 });
                    //             });
                    //         });
                    //     }
                    // });


                    // if(assignmentActivityResult.activities) {

                    //     // inserting unitId and unitName is to the activities

                    //     // activities = activities.map( x => {

                    //     activities.forEach(x => {
                    //         assignmentActivityResult.activities.forEach(y => {
                    //             if (x.activityID == y.activityID) {
                    //                 x.unitID = y.unitID;
                    //                 x.unitName = y.unitName;
                    //             }
                    //         })
                    //     })

                    //     // matching for missing activites from ES to allActivties from Courses
                    //     assignmentActivityResult.activities.forEach( x => {
                    //         let searchIndex = activities.findIndex( y => y.activityID == x.activityID);
                    //         if(searchIndex == -1) {
                    //             x.notAssigned = true;
                    //             activities.push(x);
                    //         } else {
                    //             activities[searchIndex].unitOrderID = x.unitOrderID;
                    //             activities[searchIndex].activityOrderID = x.activityOrderID;
                    //         }
                    //     })

                    // }

                    resolve(activities);
                // }, true, 'teacher: (' + '"' + teacher + '"' + ') AND studentID: (' + studentID + ')' + assignmentActivityResult.query, 'lp-student/');
        });

        // return new Promise((resolve, reject) => {
        //     estore.query(null, "stdassign", {
        //         bool: {
        //             must:{
        //                 query_string:{
        //                     query:"teacher: ("+teacher+") AND " + "studentID: ("+studentID+")"
        //                 }
        //             }
        //         }
        //     }, ["studentID", "type", "ownerName", "dueDate", "assignmentStatus", "teacher", "assignmentName", "assignmentID", "assignmentType", "activityID", "activityType", "activityName", "progress", "createdAt", "assignedActivities", "score", "timeIn", "attemptedDate", "assignedCourses"], null, null, null, "10000").then((filterData) => {
        //
        //     }).catch(error=> {
        //         context.setState({
        //             showAlert: true,
        //             alertStyle: 'danger',
        //             alertText: 'Unable to perform search, please contact administrator.',
        //             loading:false
        //         });
        //         setTimeout(()=> {
        //             context.setState({
        //                 showAlert: false,
        //                 alertStyle: '',
        //                 alertText: ''
        //             })
        //         }, 4000);
        //         reject(error);
        //     })
        // });
    }

    loadAllStudentByPeriodReportCard(subject, periodName) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName,
                periodName: periodName
            };
            let pathTemplate = '/assignment/analysis/subject/{subject}/teacher/{userName}/class/{periodName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                resolve(result.data);
                context.hideIndicator();
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadAllStudentReportCard(subject) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadStudentReportCard(studentID, subject) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                subject: subject,
                userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/student/{studentID}/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideIndicator();
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getPeriodsByTeacher() {
        let that = this;
        let params = {
            userName: that.state.teacherForAdminReport ? that.state.teacherForAdminReport.id : that.props.auth.user.userName
        };
        let pathTemplate = '/class/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "2").then(function (result) {
            let periods = result.data.allClasses;
            that.setState({ 'periods': periods });
        }).catch(function (result) {
            that.hideIndicator();
        });
    }

    loadAllStudentByPeriodDetails(className) {
        let context = this;
        context.backToClassAggr = false;
        context.classSelected = true;
        context.backToSubjectAggr = false;
        let periodName = '';
        if (className) {
            this.setState({ periodName: className });
            periodName = className;
        }
        else if (this.state.periodName) {
            periodName = this.state.periodName;
        }
        else {
            return;
        }

        if (context.state.cachedData['byPeriod' + periodName]) {
            context.setState({
                periodName: periodName,
                currentReportName: periodName + ' Overview',
                cumulative: context.state.cachedData['byPeriod' + periodName].cumulative,
                practiceAssignment: context.state.cachedData['byPeriod' + periodName].practiceAssignment,
                assignment: context.state.cachedData['byPeriod' + periodName].assignment,
                view: 'report-card'
            }, context.hideIndicator);
        }
        else {
            if (this.state.selectedSubject) {
                this.loadAllStudentByPeriodReportCard(this.state.selectedSubject, periodName).then((result) => {
                    let cachedData = context.state.cachedData;
                    cachedData['byPeriod' + periodName] = result;
                    context.setState({
                        cachedData,
                        periodName: periodName,
                        currentReportName: periodName + ' Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card'
                    });
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

    loadAllStudentDetails() {
        let context = this;
        context.showIndicator();
        if (context.state.cachedData[context.state.selectedSubject]) {
            context.setState({
                currentReportName: 'All Students Overview',
                cumulative: context.state.cachedData[context.state.selectedSubject].cumulative,
                practiceAssignment: context.state.cachedData[context.state.selectedSubject].practiceAssignment,
                assignment: context.state.cachedData[context.state.selectedSubject].assignment,
                view: 'report-card'
            }, context.hideIndicator);
        }
        else {
            if (context.state.selectedSubject) {
                context.loadAllStudentReportCard(context.state.selectedSubject).then((result) => {
                    let cachedData = context.state.cachedData;
                    cachedData[context.state.selectedSubject] = result;
                    context.setState({
                        cachedData,
                        currentReportName: 'All Students Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card'
                    }, context.hideIndicator);
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

    loadSelectedStudentDetails(student, type) {

        let context = this;
        // load activities data.

        // if (type === 'report-card') {
        //     if (this.state.selectedSubject) {
        //         if (context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName]) {
        //             context.setState({
        //                 currentReportName: student.stdFirstName + ' ' + student.stdLastName,
        //                 cumulative: context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName].cumulative,
        //                 practiceAssignment: context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName].practiceAssignment,
        //                 assignment: context.state.cachedData['byStudent' + context.state.selectedSubject + student.userName].assignment,
        //                 selectedStudent: student,
        //             });
        //         }
        //         else {
        //             context.loadStudentReportCard(student.students, context.state.selectedSubject).then((result) => {
        //                 let cachedData = context.state.cachedData;
        //                 cachedData['byStudent' + context.state.selectedSubject + student.userName] = result;
        //                 context.setState({
        //                     cachedData,
        //                     currentReportName: student.stdFirstName + ' ' + student.stdLastName,
        //                     cumulative: result.cumulative,
        //                     practiceAssignment: result.practiceAssignment,
        //                     assignment: result.assignment,
        //                     selectedStudent: student,
        //                 });
        //             }, (error) => {
        //                 console.error(error);
        //             });
        //         }
        //     } else {
        //         this.setState({
        //             selectedStudent: student,
        //         });
        //     }
        // }
        // else {
        // this.getStudentActivities(student.students).then(activities => {
        //     context.setState({
        //         loading: false,
        //         studentActivities: activities,
        //         selectedStudent: student
        //     });
        //     let parentActivities = [];
        //     activities.forEach((activity) => {
        //         let index = parentActivities.findIndex(x => x.activityID === activity.activityID);
        //         if (index === -1) {
        //             let childActivities = [];
        //             childActivities.push(activity);
        //             parentActivities.push({
        //                 activityID: activity.activityID,
        //                 activities: childActivities
        //             });
        //         }
        //         else {
        //             parentActivities[index].activities.push(activity);
        //         }
        //     });
        //     this.setState({ allAttemptWiseActivities: parentActivities });
        // });
        // }








        this.getStudentActivities(student).then(activities => {
            context.setState({
                loading: false,
                studentActivities: activities,
                selectedStudent: student
            });
            let parentActivities = [];
            activities.forEach((activity) => {
                let index = parentActivities.findIndex(x => x.activityID === activity.activityID);
                if (index === -1) {
                    let childActivities = [];
                    childActivities.push(activity);
                    parentActivities.push({
                        activityID: activity.activityID,
                        activities: childActivities
                    });
                }
                else {
                    parentActivities[index].activities.push(activity);
                }
            });


            let allActivitiesToShow = [];

            parentActivities.forEach((activity) => {
                // let index = actRows.findIndex(x=> x.props.id === activity.activityID);
                activity.activities.sort(function (a, b) {

                    var attemptedDate_b = b.attemptedDate ? b.attemptedDate : b.assignmentCreatedDate;
                    var attemptedDate_a = a.attemptedDate ? a.attemptedDate : a.assignmentCreatedDate;

                    return (new Date(parseInt(attemptedDate_b)) - new Date(parseInt(attemptedDate_a)));
                });

                // let currentActivity = activity.activities[0];

                let currentActivity = {}, isAttempted = false;

                for (let i = 0; i < activity.activities.length; i++) {
                    if (activity.activities[i].attempts) {
                        isAttempted = true;
                        currentActivity = activity.activities[i];
                        break;
                    }
                }

                if (!isAttempted)
                    currentActivity = activity.activities[0];


                // if (index === -1)
                // {
                let averageScore = currentActivity.score ? currentActivity.score : 0;
                let averageProgress = currentActivity.progress ? currentActivity.progress : 0;
                let averageTimeIn = 0;
                let numberOfAttempts = activity.activities.length;
                for (let q = 0; q < activity.activities.length; q++) {
                    // averageScore += activity.activities[q].score ? parseFloat(activity.activities[q].score) : 0;
                    // averageProgress += activity.activities[q].progress ? parseFloat(activity.activities[q].progress) : 0;
                    averageTimeIn += activity.activities[q].timeIn ? activity.activities[q].timeIn : 0;
                }
                // averageScore = averageScore / activity.activities.length;
                // averageTimeIn = averageTimeIn  / activity.activities.length;
                // averageProgress = averageProgress / activity.activities.length;




                let currentUnitID = activity.activities[0].unitID;
                let currentUnitName = activity.activities[0].unitName;

                let notAssigned = activity.activities[0].notAssigned;





                let singleActivity = {};
                singleActivity.unitID = currentUnitID;
                singleActivity.unitName = currentUnitName;
                singleActivity.activityID = currentActivity.activityID;
                singleActivity.activityName = currentActivity.activityName;
                singleActivity.activityType = currentActivity.activityType;
                singleActivity.averageProgress = averageProgress;
                singleActivity.averageScore = averageScore;
                singleActivity.averageTimeIn = averageTimeIn;
                singleActivity.notAssigned = notAssigned;

                singleActivity.unitOrderID = currentActivity.unitOrderID;
                singleActivity.activityOrderID = currentActivity.activityOrderID;

                singleActivity.activities = activity.activities;

                allActivitiesToShow.push(singleActivity)

            })


            //sort units as per given in course
            allActivitiesToShow.sort( (a,b) => a.unitOrderID - b.unitOrderID )


            let resultantObject = {}
            let sortUnitName = [];

            allActivitiesToShow.forEach(act => {
                let indexNo = sortUnitName.indexOf(act.unitName);
                if (indexNo == -1) {
                    sortUnitName.push(act.unitName);

                    resultantObject[act.unitName] = []
                    resultantObject[act.unitName].push(act)
                }
                else {
                    resultantObject[act.unitName].push(act)
                }
            })

            resultantObject = context.sortActivitiesInUnit(resultantObject, 'asc', 'activityOrderID')

            // resultantObject = context.sortObject(resultantObject, 'asc')
            // resultantObject = context.sortActivitiesInUnit(resultantObject, 'asc', 'activityName')

            // this.setState({ allAttemptWiseActivities: parentActivities });
            // this.setState({ allAttemptWiseActivities: allActivitiesToShow });
            this.setState({ allAttemptWiseActivities: resultantObject });
        }).catch(value => {
            context.setState({
                loading: false,
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load data, please contact administrator.'
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });

    }

    sortObject(obj, order) {
        return Object.keys(obj)
          .sort((a,b) => order == 'desc' ? b.localeCompare(a) : a.localeCompare(b))
          .reduce((accumulatorObject, currentValue) => {
          accumulatorObject[currentValue] = obj[currentValue];
          return accumulatorObject; }, {});
      }

    sortActivitiesInUnit(obj, order, attributeName) {
        for (var activities in obj) {
            if (obj.hasOwnProperty(activities)) {
                obj[activities].sort((a, b) => order == 'desc' ? (typeof b[attributeName] == 'string' && typeof a[attributeName] == 'string') ? b[attributeName].localeCompare(a[attributeName]) : b[attributeName] - a[attributeName] : (typeof a[attributeName] == 'string' && typeof b[attributeName] == 'string') ? a[attributeName].localeCompare(b[attributeName]) : a[attributeName] - b[attributeName])
            }
        }
        return obj;
    }

    getStudentList(selectedPeriod) {

        let context = this;
        if (!context.state.search) {
            if (selectedPeriod && selectedPeriod !== 'All Students') {
                // filter students by period.
                if (context.state.students) {
                    let filtered = context.state.students.filter((std) => {
                        return std.className === selectedPeriod;
                    });
                    filtered.sort(function (a, b) {
                        return a.stdFirstName.localeCompare(b.stdFirstName);
                    });
                    return filtered.map(student => {
                        return <li key={student.students} className={context.state.selectedStudent && context.state.selectedStudent.students === student.students ? 'selectedStudentClass' : ''}>
                            <div onClick={() => {
                                if (this.state.view === 'activities') {
                                    context.loadSelectedStudentDetails(student, 'activities');
                                }
                                else {
                                    context.loadSelectedStudentDetails(student, 'report-card');
                                }
                            }} className="studentNameLink">{student.stdFirstName + ' ' + student.stdLastName}</div>
                        </li>
                    });
                }
                return [];
            } else {
                // return all.
                if (context.state.students) {
                    return context.state.students.map(student => {
                        return <li key={student.students} className={context.state.selectedStudent && context.state.selectedStudent && context.state.selectedStudent.students === student.students ? 'selectedStudentClass' : ''}>
                            <div onClick={() => {
                                if (this.state.view === 'activities') {
                                    context.loadSelectedStudentDetails(student, 'activities');
                                }
                                else {
                                    context.loadSelectedStudentDetails(student, 'report-card');
                                }
                            }} className="studentNameLink">{student.stdFirstName + ' ' + student.stdLastName}</div>
                        </li>
                    });
                }
                return [];
            }
        } else {
            let searchTerm = this.state.search;
            if (this.state.students) {
                let filtered = this.state.students.filter((std) => {
                    return (std.stdFirstName + ' ' + std.stdLastName).indexOf(searchTerm) > -1;
                });
                return filtered.map(student => {
                    return <li key={student.students}>
                        <div>{student.stdFirstName + ' ' + student.stdLastName}</div>
                        <a onClick={() => {
                            this.loadSelectedStudentDetails(student, 'activities');
                        }
                        }>Activities</a>&nbsp;|&nbsp;
                        <a onClick={() => {
                            this.loadSelectedStudentDetails(student, 'report-card');
                        }
                        }>Report card</a>
                    </li>
                });
            }
            return [];
        }
    }

    getStudentsByTeacher() {
        let context = this;
        let params = {
            userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName
        };
        let pathTemplate = '/user/students/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            result.data.sort(function (a, b) {
                return a.stdFirstName.localeCompare(b.stdFirstName);
            });
            context.setState({ students: result.data });
        }).catch(function (error) {
            context.hideIndicator();
        });
    }

    render() {
        const { screenWidth } = this.state;
        let context = this;
        let categoryData = [];
        let activities = [];
        if (this.state.selectedType === 'cumulative') {
            categoryData = context.state.cumulative || [];
        } else if (this.state.selectedType === 'practice') {
            categoryData = context.state.practiceAssignment || [];
        } else if (this.state.selectedType === 'assignment') {
            categoryData = context.state.assignment || [];
        }
        if (this.state.allAttemptWiseActivities) {
            activities = this.state.allAttemptWiseActivities;
        }
        let subjectScore = 0;
        let questions = 0;
        let correctAns = 0;

        categoryData.forEach((cat) => {
            questions += cat.totalQuestions;
            correctAns += cat.answeredCorrectly;
        });

        if (correctAns === 0 && questions === 0) {
            subjectScore = 0;
        } else {
            subjectScore = (correctAns / questions) * 100;
            subjectScore = subjectScore.toFixed(0);
        }

        categoryData = categoryData.sort((a, b) => {
            return a.category.localeCompare(b.category);
        });

        let rows = [];
        categoryData.forEach((category) => {
            let average = 0;
            let color = 'black';

            average = category.average;

            if (average.toFixed(0) < 65) {
                color = 'red';
            }
            rows.push(<tr key={Math.random()} id={category.category}>
                <td data-parent-id={category.category} data-is-expanded={false} style={{ width: '40%' }}>
                    <a href="javascript:void 0" onClick={function (evt) {
                        var parent = evt.target.parentElement.parentElement;
                        var rows = document.querySelectorAll('tr[id*=\"parent-' + parent.dataset.parentId + '\"]');
                        if (parent.dataset.isExpanded === 'false') {
                            parent.dataset.isExpanded = 'true';
                            parent.firstElementChild.lastElementChild.textContent = 'remove_circle_outline';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = '';
                            }
                        } else {
                            parent.firstElementChild.lastElementChild.textContent = 'add_circle_outline';
                            parent.dataset.isExpanded = 'false';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = 'none';
                            }
                        }
                    }.bind(this)} data-parent-id={category.category}><strong>{category.category}</strong><MaterialIcon icon="add_circle_outline" size={24} /></a>
                </td>
                <td style={{ width: '15%', color: color }}>
                    {average ? average.toFixed(0) : 0}{'%'}
                </td>
                <td style={{ width: '15%' }}>
                    {category.totalQuestions}
                </td>
                <td style={{ width: '15%' }}>
                    {category.answeredCorrectly}
                </td>
                <td style={{ width: '15%' }}>
                    {category.answeredIncorrectly}
                </td>
            </tr>);

            category.subCategories.forEach(subCat => {
                let average = 0;
                let color = 'black';

                average = subCat.average

                if (average.toFixed(0) < 65) {
                    color = 'red';
                }

                rows.push(<tr key={Math.random()} id={'parent-' + category.category + subCat.category}
                    data-category-parent-id={category.category} style={{ display: 'none' }}>
                    <td className="indentSubCat">
                        {subCat.category}
                    </td>
                    <td style={{ color: color }}>
                        {average.toFixed(0)}{'%'}
                    </td>
                    <td>
                        {subCat.totalQuestions}
                    </td>
                    <td>
                        {subCat.answeredCorrectly}
                    </td>
                    <td>
                        {subCat.answeredIncorrectly}
                    </td>
                </tr>);
            });
        });

        let actRows = [];



        let allActivitesForCourseInfoBox = [];

        for (let unit in activities) {
            if (!activities.hasOwnProperty(unit)) {
                continue;
            }

            actRows.push(
                <tr key={Math.random()} id={Math.random() * 1000}>
                    <td colSpan="6" style={{ width: '25%', textAlign: 'left', padding: "15px", backgroundColor: "#f1f9ff" }}>
                        <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={unit}>
                            <span style={{ fontSize: "1.3em", color: "#222" }}>{unit}</span>
                        </div>
                    </td>
                </tr>
            );

            activities[unit].forEach(activity => {

                actRows.push(
                    <tr key={Math.random()} id={activity.activityID}>
                        <td style={{ width: '25%' }}>
                            <div style={{ /*fontStyle: 'bold',*/ color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ', marginLeft: "30px" }} title={activity.activityName}>
                                {activity.activityName}
                            </div>
                        </td>
                        <td style={{ width: '15%' }}>
                            {Utility.ucFirst(activity.activityType)}
                        </td>
                        <td style={{ width: '15%' }}>
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : (activity.averageProgress ? parseFloat(activity.averageProgress).toFixed(0) + '%' : '0%')}
                        </td>
                        <td style={{ width: '15%' }}>
                            {/* {averageScore ? averageScore.toFixed(0) : 0}{'%'} */}
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.averageScore ? (activity.averageScore.toFixed(0) < 65 ? <span style={{ color: 'red' }}>{activity.averageScore.toFixed(0)}{'%'}</span> : <span>{activity.averageScore.toFixed(0)}{'%'}</span>) : <span style={{ color: 'red' }}>0{'%'}</span>}
                        </td>
                        {/*<td style={{width:'15%'}}>*/}
                        {/*{assignedClass}*/}
                        {/*</td>*/}
                        <td style={{ width: '15%' }}>
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.averageTimeIn ?
                                parseHours(activity.averageTimeIn) + ':' + parseMinutes(activity.averageTimeIn) + ':' + parseSeconds(activity.averageTimeIn)
                                 : '0:0:0'}
                        </td>
                        {/*<td style={{width:'15%'}}>*/}
                        {/*{numberOfAttempts}*/}
                        {/*</td>*/}
                        <td style={{ width: '10%' }} className="viewUserDetLink">

                            {
                                activity.notAssigned ? <span className='text-danger'>Not Assigned Yet</span>
                                    :
                                    <a href="javascript:void(0)" onClick={
                                        function () {

                                            // console.log("activities pass through next"); console.dir(activity.activities)
                                            // console.log("selectedStudent pass through next"); console.dir(this.state.selectedStudent)
                                            this.viewStudentDetails(activity.activities, this.state.selectedStudent)

                                        }.bind(this)
                                    }>
                                        <MaterialIcon icon="search" size={24} />
                                    </a>
                            }
                        </td>
                    </tr>
                );


                let singleActivity = {};

                singleActivity.ActivityFromBackend = activity.activities[0];

                singleActivity.activityName = activity.activityName;
                singleActivity.activityType = activity.activityType;
                singleActivity.progress = activity.averageProgress;
                singleActivity.score = activity.averageScore;
                singleActivity.timeIn = activity.averageTimeIn;
                singleActivity.notAssigned = activity.notAssigned ? activity.notAssigned : false;

                allActivitesForCourseInfoBox.push(singleActivity);
            })

        }

        if (allActivitesForCourseInfoBox.length != 0)
            context.state.allActivitesForCourseInfoBox = allActivitesForCourseInfoBox;














        // let allActivitesForCourseInfoBox = [];

        // let unitID= '';

        // activities.forEach((activity) => {

        //     if (unitID != activity.unitID) {

        //         unitID = activity.unitID;

        //         actRows.push(
        //             <tr key={Math.random()} id={Math.random() * 1000}>
        //                 <td colSpan="6" style={{ width: '25%', textAlign: 'left', padding: "15px", backgroundColor: "#f1f9ff" }}>
        //                     <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={activity.unitName}>
        //                         <span style={{ fontSize: "1.3em", color: "#222" }}>{activity.unitName}</span>
        //                     </div>
        //                 </td>
        //             </tr>
        //         );

        //     }


        //     actRows.push(
        //         <tr key={Math.random()} id={activity.activityID}>
        //             <td style={{ width: '25%' }}>
        //                 <div style={{ /*fontStyle: 'bold',*/ color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ', marginLeft: "30px"  }} title={activity.activityName}>
        //                     {activity.activityName}
        //                 </div>
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {Utility.ucFirst(activity.activityType)}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : (activity.averageProgress ? parseFloat(activity.averageProgress).toFixed(0)+'%' : '0%')}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {/* {averageScore ? averageScore.toFixed(0) : 0}{'%'} */}
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.averageScore ? (activity.averageScore.toFixed(0) < 65 ? <span style={{color: 'red'}}>{activity.averageScore.toFixed(0)}{'%'}</span> : <span>{activity.averageScore.toFixed(0)}{'%'}</span>) : <span style={{color: 'red'}}>0{'%'}</span>}
        //             </td>
        //             {/*<td style={{width:'15%'}}>*/}
        //             {/*{assignedClass}*/}
        //             {/*</td>*/}
        //             <td style={{ width: '15%' }}>
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.averageTimeIn ? activity.averageTimeIn.timeFormatter('h') + ':' + activity.averageTimeIn.timeFormatter('m') + ':' + activity.averageTimeIn.timeFormatter('s') : '0:0:0'}
        //             </td>
        //             {/*<td style={{width:'15%'}}>*/}
        //             {/*{numberOfAttempts}*/}
        //             {/*</td>*/}
        //             <td style={{ width: '10%' }} className="viewUserDetLink">

        //                 {
        //                     activity.notAssigned ? <span className='text-danger'>Not Assigned Yet</span>
        //                     :
        //                 <a href="javascript:void(0)" onClick={
        //                     function () {

        //                         console.log("activities pass through next"); console.dir(activity.activities)
        //                         console.log("selectedStudent pass through next");console.dir(this.state.selectedStudent)
        //                         this.viewStudentDetails(activity.activities, this.state.selectedStudent)

        //                     }.bind(this)
        //                 }>
        //                     <MaterialIcon icon="search" size={24} />
        //                 </a>
        //                 }
        //             </td>
        //         </tr>
        //     );


        //     let singleActivity = {};
        //     singleActivity.activityName = activity.activityName;
        //     singleActivity.activityType = activity.activityType;
        //     singleActivity.progress = activity.averageProgress;
        //     singleActivity.score = activity.averageScore;
        //     singleActivity.timeIn = activity.averageTimeIn;
        //     singleActivity.notAssigned = activity.notAssigned ? activity.notAssigned : false;

        //     allActivitesForCourseInfoBox.push(singleActivity);

        //     // }
        // });


        // if(allActivitesForCourseInfoBox.length != 0)
        //     context.state.allActivitesForCourseInfoBox = allActivitesForCourseInfoBox;


















        // let allActivitesForCourseInfoBox = [];

        // let unitID= '';

        // activities.forEach((activity) => {
        //     // let index = actRows.findIndex(x=> x.props.id === activity.activityID);
        //     activity.activities.sort(function (a, b) {

        //     var attemptedDate_b = b.attemptedDate ? b.attemptedDate : b.assignmentCreatedDate;
        //     var attemptedDate_a = a.attemptedDate ? a.attemptedDate : a.assignmentCreatedDate;

        //         return (new Date(parseInt(attemptedDate_b)) - new Date(parseInt(attemptedDate_a)));
        //     });

        //     // let currentActivity = activity.activities[0];

        //     let currentActivity = {}, isAttempted = false;

        //     for (let i = 0; i < activity.activities.length; i++) {
        //         if(activity.activities[i].attempts) {
        //             isAttempted = true;
        //             currentActivity = activity.activities[i];
        //             break;
        //         }
        //     }

        //     if(!isAttempted)
        //     currentActivity = activity.activities[0];


        //     // if (index === -1)
        //     // {
        //     let averageScore = currentActivity.score ? currentActivity.score : 0;
        //     let averageProgress = currentActivity.progress ? currentActivity.progress : 0;
        //     let averageTimeIn = 0;
        //     let numberOfAttempts = activity.activities.length;
        //     for (let q = 0; q < activity.activities.length; q++) {
        //         // averageScore += activity.activities[q].score ? parseFloat(activity.activities[q].score) : 0;
        //         // averageProgress += activity.activities[q].progress ? parseFloat(activity.activities[q].progress) : 0;
        //         averageTimeIn += activity.activities[q].timeIn ? activity.activities[q].timeIn : 0;
        //     }
        //     // averageScore = averageScore / activity.activities.length;
        //     averageTimeIn = averageTimeIn // / activity.activities.length;
        //     // averageProgress = averageProgress / activity.activities.length;



        //     let assignedClass = '';
        //     if (currentActivity.assignTo && currentActivity.assignTo.length > 0) {
        //         for (let a = 0; a < currentActivity.assignTo.length; a++) {
        //             if (a === currentActivity.assignTo.length - 1) {
        //                 assignedClass += currentActivity.assignTo[a];
        //             }
        //             else {
        //                 assignedClass += currentActivity.assignTo[a] + ', ';
        //             }
        //         }
        //     }





        //     let currentUnitID = activity.activities[0].unitID;
        //     let currentUnitName = activity.activities[0].unitName;

        //     let notAssigned = activity.activities[0].notAssigned;


        //     if (unitID != currentUnitID) {

        //         unitID = currentUnitID;

        //         actRows.push(
        //             <tr key={Math.random()} id={Math.random() * 1000}>
        //                 <td colSpan="6" style={{ width: '25%', textAlign: 'left', padding: "15px", backgroundColor: "#f1f9ff" }}>
        //                     <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={currentUnitName}>
        //                         <span style={{ fontSize: "1.3em", color: "#222" }}>{currentUnitName}</span>
        //                     </div>
        //                 </td>
        //             </tr>
        //         );

        //     }


        //     actRows.push(
        //         <tr key={Math.random()} id={currentActivity.activityID}>
        //             <td style={{ width: '25%' }}>
        //                 <div style={{ /*fontStyle: 'bold',*/ color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ', marginLeft: "30px"  }} title={currentActivity.activityName}>
        //                     {currentActivity.activityName}
        //                 </div>
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {Utility.ucFirst(currentActivity.activityType)}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {notAssigned ? <span className="text-muted"><i>N/A</i></span> : (averageProgress ? parseFloat(averageProgress).toFixed(0)+'%' : '0%')}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {/* {averageScore ? averageScore.toFixed(0) : 0}{'%'} */}
        //                 {notAssigned ? <span className="text-muted"><i>N/A</i></span> : averageScore ? (averageScore.toFixed(0) < 65 ? <span style={{color: 'red'}}>{averageScore.toFixed(0)}{'%'}</span> : <span>{averageScore.toFixed(0)}{'%'}</span>) : <span style={{color: 'red'}}>0{'%'}</span>}
        //             </td>
        //             {/*<td style={{width:'15%'}}>*/}
        //             {/*{assignedClass}*/}
        //             {/*</td>*/}
        //             <td style={{ width: '15%' }}>
        //                 {notAssigned ? <span className="text-muted"><i>N/A</i></span> : averageTimeIn ? averageTimeIn.timeFormatter('h') + ':' + averageTimeIn.timeFormatter('m') + ':' + averageTimeIn.timeFormatter('s') : '0:0:0'}
        //             </td>
        //             {/*<td style={{width:'15%'}}>*/}
        //             {/*{numberOfAttempts}*/}
        //             {/*</td>*/}
        //             <td style={{ width: '10%' }} className="viewUserDetLink">

        //                 {
        //                     notAssigned ? <span className='text-danger'>Not Assigned Yet</span>
        //                     :
        //                 <a href="javascript:void(0)" onClick={
        //                     function () {

        //                         console.log("activities pass through next"); console.dir(activity.activities)
        //                         console.log("selectedStudent pass through next");console.dir(this.state.selectedStudent)
        //                         this.viewStudentDetails(activity.activities, this.state.selectedStudent)

        //                     }.bind(this)
        //                 }>
        //                     <MaterialIcon icon="search" size={24} />
        //                 </a>
        //                 }
        //             </td>
        //         </tr>
        //     );


        //     let singleActivity = {};
        //     singleActivity.activityName = currentActivity.activityName;
        //     singleActivity.activityType = currentActivity.activityType;
        //     singleActivity.progress = averageProgress;
        //     singleActivity.score = averageScore;
        //     singleActivity.timeIn = averageTimeIn;
        //     singleActivity.notAssigned = notAssigned ? notAssigned : false;

        //     allActivitesForCourseInfoBox.push(singleActivity);

        //     // }
        // });


        // if(allActivitesForCourseInfoBox.length != 0)
        //     context.state.allActivitesForCourseInfoBox = allActivitesForCourseInfoBox;










        let studentStats = <table className="table" id="student-attempt-history">
            <thead>
                <tr>
                    <th>
                    </th>
                    <th>
                        Average
                </th>
                    <th>
                        Total Questions
                </th>
                    <th>
                        Answered Correctly
                </th>
                    <th>
                        Answered Incorrectly
                </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        let activityStats = <div id='course-report-student-activities'>
            <table className="table table-hover" style={{ border: "1px solid #ddd" }} id="student-attempt-history">
                <thead>
                    <tr>
                        <th className="dropdown" data-order="dsc" onClick={function (evt) {
                            let allData = this.state.allAttemptWiseActivities;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData = this.sortActivitiesInUnit(allData, 'asc', 'activityName')
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData = this.sortActivitiesInUnit(allData, 'desc', 'activityName')
                            }
                            context.setState({ allAttemptWiseActivities: allData });
                        }.bind(this)}>
                            Activity Name<span className="caret caret-close" />
                        </th>
                        <th className="dropdown" data-order="dsc" onClick={function (evt) {
                            let allData = this.state.allAttemptWiseActivities;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData = this.sortActivitiesInUnit(allData, 'asc', 'activityType')
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData = this.sortActivitiesInUnit(allData, 'desc', 'activityType')
                            }
                            context.setState({ allAttemptWiseActivities: allData });
                        }.bind(this)}>
                            Activity Type<span className="caret caret-close" />
                </th>
                        <th className="dropdown" data-order="dsc" onClick={function (evt) {
                            let allData = this.state.allAttemptWiseActivities;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData = this.sortActivitiesInUnit(allData, 'asc', 'averageProgress')
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData = this.sortActivitiesInUnit(allData, 'desc', 'averageProgress')
                            }
                            context.setState({ allAttemptWiseActivities: allData });
                        }.bind(this)}>
                            Progress<span className="caret caret-close" />
                </th>
                        <th className="dropdown" data-order="dsc" onClick={function (evt) {
                            let allData = this.state.allAttemptWiseActivities;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData = this.sortActivitiesInUnit(allData, 'asc', 'averageScore')
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData = this.sortActivitiesInUnit(allData, 'desc', 'averageScore')
                            }
                            context.setState({ allAttemptWiseActivities: allData });
                        }.bind(this)}>
                            Score<span className="caret caret-close" />
                </th>
                        <th className="dropdown" data-order="dsc" onClick={function (evt) {
                            let allData = this.state.allAttemptWiseActivities;
                            let order = evt.target.getAttribute('data-order');
                            if (order === 'asc') {
                                evt.target.setAttribute('data-order', 'dsc');
                                evt.target.setAttribute('class', 'dropdown');
                                allData = this.sortActivitiesInUnit(allData, 'asc', 'averageTimeIn')
                            } else {
                                evt.target.setAttribute('data-order', 'asc');
                                evt.target.setAttribute('class', 'dropup');
                                allData = this.sortActivitiesInUnit(allData, 'desc', 'averageTimeIn')
                            }
                            context.setState({ allAttemptWiseActivities: allData });
                        }.bind(this)}>
                            Time In<span className="caret caret-close" />
                </th>
                        {/*<th>*/}
                        {/*Attempts*/}
                        {/*</th>*/}
                        <th>
                            View Details
                </th>
                    </tr>
                </thead>
                <tbody>

                    {actRows}

                </tbody>
            </table>
        </div>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {
                    this.state.selectedSubject &&
                    <p className="subjectScoreHeading" style={{ width: '25%' }}>
                        {this.state.selectedSubject} : <span style={{ color: subjectScore < 65 ? 'red' : 'green' }} className="subjectScoreHeadingSpan">{subjectScore === NaN ? 0 : subjectScore}{'%'}</span>
                    </p>
                }
                {
                    !this.state.selectedSubject && <p className="subjectScoreHeading" style={{ width: '25%' }}>
                        Select a Subject
                    </p>
                }
                <p style={{ marginLeft: '50px', marginTop: '-30px', fontStyle: 'italic' }}>
                    {this.state.selectedStudent ? this.state.selectedStudent.stdFirstName + ' ' + this.state.selectedStudent.stdLastName : this.state.currentReportName}
                </p>
                <p style={{ overflow: 'auto' }}>
                    {studentStats}
                </p>
            </div>
        </div>;
        let activityTable = <div id="user-activity-analysis" className="row">

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{marginTop: '-50px', "text-align": "right", marginBottom: "10px"}}>
                    {/* <a className="boardheadblff noHover">Students Analysis of Course: {this.params.id.slice(0, this.params.id.indexOf('_'))}</a> */}
                    <span onMouseOver={(event)=>{event.target.firstElementChild.src = excelIconHover;}} onMouseOut={(event)=>{event.target.firstElementChild.src = excelIcon;}}
                          className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                          onClick={()=>{
                              this.exportToExcel('course-report-student-activities', true);
                          }}>
                            <img src={excelIcon} style={{width:'43px'}} />
                        </span>
                    <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                        context.printTable('course-report-student-activities', true);
                    }}/>
                </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
        </div>;

        let onChange = function (evt) {
            this.setState({
                selectedType: evt.target.value
            })
        }.bind(context);

        let subjects = this.state.subjects;
        let nodes = [];
        if (subjects) {
            subjects.forEach(sub => {
                nodes.push({ id: sub.id, name: sub.name });
            });
        }

        let courseInfo = this.viewCourseReportStudentCourseInfo();

        return (
            <div id="TeacherStudentReport">

                <div id='forCourseReport'>

                    <div className="container-fluid noPad listMainBoxWrapper " id="activity-analysis" style={{ overflowX: 'hidden' }}>

                        <Heading
                            isQuery={false}
                            customURL={ElasticSearchTypes.StudentAssignment}
                            location="studentAssignment"
                            actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                            heading="Assignment Reports"
                            isSearch={false}
                            isShowButton={false}
                            screenNameForElasticSearch={this.state.teacherForAdminReport ? this.state.teacherForAdminReport.id : this.props.auth.user.userName}
                            allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                            breadcrumbsData={
                                this.state.forAdminReport ?
                                    [
                                        {
                                            name: 'Reports', action: () => {
                                                browserHistory.push('/reports');
                                            }
                                        },
                                        {
                                            name: "Admin Report", action: () => {
                                                browserHistory.push({
                                                    pathname: '/reports/admin-report',
                                                    state: { selectedSubject: this.state.subjectForAdminReport }
                                                })
                                            }
                                        },
                                        {
                                            name: 'Course Report - ' + this.state.teacherForAdminReport.name, action: () => {
                                                browserHistory.push({
                                                    pathname: '/reports/course-report-students/' + encodeURIComponent(this.params.id),
                                                    state: { courseName: this.state.courseName, courseForAdminReport: this.state.courseForAdminReport , forAdminReport: this.state.forAdminReport, teacher: this.state.teacherForAdminReport, allTeachers:  this.state.allTeachersForAdminReport, subjectForAdminReport: this.state.subjectForAdminReport}
                                                })
                                            }
                                        },
                                        {
                                            name: 'Activities of Student: ' + this.state.studentName
                                        }
                                    ] : [
                                        {
                                            name: 'Reports', action: () => {
                                                browserHistory.push('/reports');
                                            }
                                        },
                                        {
                                            name: 'Course Report - By Student', action: () => {
                                                browserHistory.push({
                                                    pathname: '/reports/course-report-students/' + encodeURIComponent(this.params.id),
                                                    state: { courseName: this.state.courseName }
                                                })
                                            }
                                        },
                                        {
                                            name: 'Activities of Student: ' + this.state.studentName
                                        }
                                    ]
                            }
                        />
                        {this.state.showAlert &&
                            <div className="row">
                                <div className="col-md-3 col-xs-3" />
                                <div className="col-md-6 col-xs-3">
                                    <Alert bsStyle={this.state.alertStyle}
                                        onDismiss={this.handleAlertDismiss}>
                                        {this.state.alertText}
                                    </Alert>
                                </div>
                                <div className="col-md-3 col-xs-3" />
                            </div>
                        }
                        <div id="tableFiltersAndSideView">
                            <div className={classnames("container-fluid noPad listMainBoxWrapper")}>


                                {/* <div className="filterBlock filterBlockBox NiceScroll" id="filterInnerContainer">
                            <div className="smText hidden-lg">
                                <h3>Filter</h3>
                                <span className="HideCross" onClick={function (evt) {
                                    $('.filterBlock').removeClass('TopZero');
                                    $('html').css({'overflow': 'auto', 'position': 'inherit'});
                                    $('.footer ').show();
                                }.bind(this)}/>
                            </div>

                            <div className="filterBox overFlowScroll-sm">
                                {this.state.view === 'activities' && this.getPeriodDropdown()}
                                <hr />
                                <div className="studentList">
                                    <ul>
                                        {this.getStudentList(this.state.selectedPeriod)}
                                    </ul>
                                </div>
                            </div>

                            <button className="ApplyFilter hidden-md hidden-lg" onClick={(evt)=> {
                                $('.filterBlock').removeClass('TopZero');
                                $('html').css({'overflow': 'auto', 'position': 'inherit'});
                                $('.footer ').show();
                            }}> Apply
                            </button>
                        </div> */}


                                {/*added inline css below */}
                                {/* <div className={this.tableCssClassName} style={{background:'#f7f8f9', width:'75%', marginLeft:'23%'}}> */}

                                {/* <div className={this.tableCssClassName} style={{ marginLeft: '2%', marginRight: '2%', width: '96%' }}>
                            <div className="listToBesslide NiceScroll  tableBoxStyle">
                                <div className="blockForlist "> */}



                                <div id="submission" className="tab-pane fade active in">
                                    <div id="contentPanel" style={{
                                        margin: '1.5% 10.5% 5%',
                                        width: '80%',
                                        padding: '10px 35px',
                                        border: '1px solid rgb(204, 204, 204)'

                                        //margin: '1.5% 10.5',
                                        //width: '80%',
                                        //padding: '35px',
                                        //border: '1px solid rgb(204, 204, 204)'

                                        //marginLeft: '10%',
                                        //marginRight: '10%',
                                        //width: '80%',
                                        //padding: '0px 35px 35px',
                                    }}>








                                        {/* {this.state.view === 'report-card' &&
                                    <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedType"
                                            value={this.state.selectedType}
                                            options={[{id:'assignment', name:'Teacher Assignments'}, {id:'practice', name:'Self Study'} ,{id:'cumulative', name:'Cumulative'}]}
                                            placeholder="Select Type"
                                            onChange={onChange}
                                        />
                                    </div>}
                                    {this.state.view === 'report-card' &&
                                    <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedSubject"
                                            value={this.state.selectedSubject}
                                            options={nodes}
                                            placeholder="Select a Subject"
                                            onChange={(evt) => {
                                                context.backToClassAggr = false;
                                                context.backToSubjectAggr = false;
                                                context.classSelected = false;
                                                let subject = evt.target.value;
                                                if(evt.target.value && evt.target.value !== 'none'){
                                                    context.setState({selectedSubject:subject}, function () {
                                                        context.loadSelectedStudentDetails(this.state.selectedStudent, 'report-card');
                                                    });
                                                    // this.loadStudentReportCard(this.state.selectedStudent.students, subject).then((result) => {
                                                    //     context.setState({
                                                    //         cumulative: result.cumulative,
                                                    //         practiceAssignment: result.practiceAssignment,
                                                    //         assignment: result.assignment,
                                                    //         selectedSubject: subject
                                                    //     });
                                                    // }, (error) => {
                                                    //     console.error(error);
                                                    // });
                                                }
                                            }}
                                        />
                                    </div>
                                    } */}

                                        {/* {this.state.selectedStudent && <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedReportType"
                                            value={this.state.view}
                                            options={[{id:'activities', name:'Activities'}, {id:'report-card', name:'Grade Tracker'}]}
                                            placeholder="Select Report Type"
                                            onChange={function (event) {
                                                let value = event.target.value;
                                                context.setState({view:value}, function () {
                                                    if (context.state.selectedStudent && context.state.selectedSubject) {
                                                        if (context.state.view === 'activities')
                                                        {
                                                            context.loadSelectedStudentDetails(context.state.selectedStudent, 'activities');
                                                        }
                                                        else
                                                        {
                                                            context.loadSelectedStudentDetails(context.state.selectedStudent, 'report-card');
                                                        }
                                                    }
                                                });
                                            }.bind(this)}
                                        />
                                    </div>} */}





                                        <div className="" id="gray-box-container">
                                            <div id="ReportCardTableStudent" style={{ background: 'white' }}>
                                                {courseInfo}
                                            </div>
                                        </div>

                                        <br />
                                        <br />





                                        {this.state.view === 'report-card' && table}
                                        {this.state.view === 'activities' && activityTable}



                                    </div>
                                </div>



                                {/* </div>
                            </div>
                        </div> */}

                            </div>
                        </div>
                    </div>
                    {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
                    <ProgressIndicator show={this.state.loading} />
                </div>
            </div>

        );
    }



    exportToExcel(tableID)
    {
        //EXPORT TO EXCEL
        let data_type = 'data:application/vnd.ms-excel';
        let table_div = document.getElementById(tableID).cloneNode(true);

        console.dir(table_div)

        table_div.firstElementChild.firstElementChild.firstElementChild.lastElementChild.remove()


        for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
        {
            for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
            {
                if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].className && table_div.firstElementChild.lastChild.children[i].children[j].className == "viewUserDetLink")
                    table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j])

                if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].colSpan && table_div.firstElementChild.lastChild.children[i].children[j].colSpan == 6)
                    table_div.firstElementChild.lastChild.children[i].children[j].colSpan = 5;

            }
        }

        // for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
        // {
        //     for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
        //     {
        //         if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
        //             table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
        //         {
        //             table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
        //         }
        //     }
        // }


        let table_html = table_div.outerHTML.replace(/ /g, '%20');
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = data_type + ', ' + table_html;
        // a.download = 'Student Activity Analysis-' + this.state.currentActivity.activityName + '.xls';
        a.download = 'Course Report - ' + (this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName) + ' for student ' + this.state.studentName +  '.xls';
        a.click();
    }

    printTable(tableID)
    {
        //PRINT TABLE
        let table_div = document.getElementById(tableID)?document.getElementById(tableID).cloneNode(true):null;
        if (table_div)
        {
            table_div.firstElementChild.firstElementChild.firstElementChild.lastElementChild.remove()

            for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
                for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                    if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].className && table_div.firstElementChild.lastChild.children[i].children[j].className == "viewUserDetLink")
                        table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j])

                    if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].colSpan && table_div.firstElementChild.lastChild.children[i].children[j].colSpan == 6)
                        table_div.firstElementChild.lastChild.children[i].children[j].colSpan = 5;

                }
            }

            let myWindow = window.open('', 'PRINT', 'height=600,width=800');
            myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
            myWindow.document.write('<h1>' + 'Course Report - ' + (this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName) + ' for student ' + this.state.studentName +  '</h1>');
            myWindow.document.write(table_div.outerHTML);
            myWindow.document.write('</body></html>');
            myWindow.focus(); // necessary for IE >= 10*/
            setTimeout(function () {
                myWindow.print();
                myWindow.document.close(); // necessary for IE >= 10
                myWindow.close();
            }, 1000);
        }
        else
        {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Content not available, can not print. Please contact administrator.'
            });
            setTimeout(()=> {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }
    }



    viewCourseReportStudentCourseInfo() {
        let context = this;


        console.count('Rendering-->')

        // let average = (Math.random() * 100).toFixed(0),
        //     completedAverage_Completed_Activities = (Math.random() * 100).toFixed(0),
        //     progress = (Math.random() * 100).toFixed(0),
        //     status = (Math.random() * 3).toFixed(0), // 0-1 Not Started, 2 In Progress, 3 Completed
        //     timeInCourse = (Math.random() * 5).toFixed(0),

        //     // courseStarted = new Date().toLocaleDateString();
        //     courseStarted = faker.date.past().toLocaleDateString();

        let average = 0,
            completedAverage_Completed_Activities = 0,
            progress = 0,
            status = 'Not Started', // 0-1 Not Started, 2 In Progress, 3 Completed
            timeInCourse = 0

            // courseStarted = new Date().toLocaleDateString();
            // courseStarted = faker.date.past().toLocaleDateString();

            if(context.state.allActivitesForCourseInfoBox && context.state.allActivitesForCourseInfoBox.length != 0) {

                let totalAssignedActivites = 0;
                let totalAssignedCompletedActivites = 0;
                let totalAssignedNotStartedActivities = 0;
                let totalProgress = 0;
                let totalScore = 0;
                let totalCompletedScore = 0;
                let totalTimeInCourse = 0;

                let totalActivities = 0;

                context.state.allActivitesForCourseInfoBox.forEach( activity => {

                    if(!activity.notAssigned) {

                        totalAssignedActivites++;

                        if(activity.progress == 100) {
                            totalAssignedCompletedActivites++;
                            // totalCompletedScore += activity.score ? parseFloat(activity.score) : 0;
                            totalCompletedScore += activity.ActivityFromBackend.aggregatedScore ? parseFloat(activity.ActivityFromBackend.aggregatedScore) : 0;
                        }

                        if(activity.progress == 0)
                            totalAssignedNotStartedActivities++;

                        totalProgress += activity.progress ? parseFloat(activity.progress) : 0;
                        // totalScore += activity.score ? parseFloat(activity.score) : 0;
                        // totalProgress += activity.ActivityFromBackend.aggregatedProgress ? parseFloat(activity.ActivityFromBackend.aggregatedProgress) : 0;
                        totalScore += activity.ActivityFromBackend.aggregatedScore ? parseFloat(activity.ActivityFromBackend.aggregatedScore) : 0;
                        totalTimeInCourse += activity.timeIn ? parseFloat(activity.timeIn) : 0;

                    }

                    totalActivities++;

                })

                average = totalAssignedActivites ? totalScore / totalAssignedActivites : 0;
                completedAverage_Completed_Activities = totalAssignedCompletedActivites ? totalCompletedScore / totalAssignedCompletedActivites : 0;
                progress = totalAssignedActivites ? totalProgress / totalActivities : 0;
                // timeInCourse = totalAssignedActivites ? totalTimeInCourse / totalAssignedActivites : 0;
                timeInCourse = totalTimeInCourse;

                if(totalAssignedNotStartedActivities == totalActivities)
                    status = 'Not Started'
                else if(totalAssignedCompletedActivites == totalActivities)
                    status = 'Completed'
                else
                    status = 'In Progress'

            }




        let courseInfoStats = <table className="table" id="act-stat">
            <tbody>
                <tr>
                    <td width="40%"> Average <span style={{ fontWeight: "normal" }}>(Assigned Activities)</span>:</td>
                    <td width="60%"><span className='fontSizeOfTableValues'>{average ? average < 65 ? <span style={{color: 'red'}}>{average.toFixed(0)}{'%'}</span> : <span>{average.toFixed(0)}{'%'}</span> : <span style={{color: 'red'}}>0%</span> }</span></td>
                </tr>
                <tr>
                    <td width="40%"> Completed Average <span style={{ fontWeight: "normal" }}>(Completed Activities)</span>:</td>
                    <td width="60%"><span className='fontSizeOfTableValues'>{completedAverage_Completed_Activities ? completedAverage_Completed_Activities < 65 ? <span style={{color: 'red'}}>{completedAverage_Completed_Activities.toFixed(0)}{'%'}</span> : <span>{completedAverage_Completed_Activities.toFixed(0)}{'%'}</span> : <span style={{color: 'red'}}>0%</span> }</span></td>
                </tr>
                <tr>
                    <td width="40%"> Progress:</td>
                    <td width="60%"><span className='fontSizeOfTableValues'>{progress ? progress.toFixed(0) + '%' : '0%' }</span></td>
                </tr>
                <tr>
                    <td width="40%"> Status:</td>
                    <td width="60%"><span className='fontSizeOfTableValues'>{(status == 'Completed') ? <span className="text-success">Completed</span> : (status == 'In Progress' ? <span>In Progress</span> : <span className="text-muted"><i>Not Started</i></span>)}</span></td>
                    {/* <td width="60%">{(status == 0 || status == 1) ? <span className="text-muted"><i>Not Started</i></span> : (status == 2 ? <span>In Progress</span> : <span className="text-success">Completed</span>)}</td> */}
                </tr>
                <tr>
                    <td width="40%"> Time in Course:</td>
                    <td width="60%"><span className='fontSizeOfTableValues'>{status == 'Not Started' ? 'N/A' : timeInCourse ?
                        ( parseHours(timeInCourse) + ' hours ' + parseMinutes(timeInCourse) + ' mins ' + parseSeconds(timeInCourse) + ' seconds')
                        : '0:0:0'}</span></td>
                </tr>
                <tr>
                    <td width="40%"> Course Started:</td>
                    <td width="60%"><span className='fontSizeOfTableValues'>{context.state.courseStarted && context.state.courseStarted != Infinity ? new Date(context.state.courseStarted).toLocaleString() : 'Not Started Yet'}</span></td>
                </tr>
            </tbody>
        </table>;


        return (
            <div id="student-activity-analysis" className="row" style={{ zIndex: '-100' }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div className="boardheadblff1 makeMyFontBig marginTop" >{this.state.studentName}</div>
                    <div className="boardheadblff1 makeMyFontSmallBigger">{this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName}</div>
                    {/* <br/> */}

                    {/* <a className="boardheadblff noHover">Course Info:</a> */}
                    <p style={{ overflow: 'auto' }}>
                        {courseInfoStats}
                    </p>
                </div>
            </div>
        );
    }

    getAllSubjects() {
        let context = this;
        let subjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            subjects.push({
                id: sub,
                name: sub
            });
        });
        context.setState({
            subjects: subjects
        });
    }

    loadAttemptDetails(assignmentID, studentID, activityID) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;

            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/history/{assignmentID}/student/{studentID}/activity/{activityID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideIndicator();
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    viewStudentDetails(allActivites, student, index = 0) {
        let context = this;
        this.showIndicator();
        this.loadStudentDetails(allActivites[index].assignmentID, student, allActivites[index].activityID).then(result => {
            context.hideIndicator();

            let userRecord = context.state.userRecord;
            for (let z = 0; z < result.length; z++) {
                if (result[z].completionDate !== 'N/A') {
                    userRecord.push(result[z]);
                }
            }
            context.setState({
                userRecord: userRecord,
                userRecordActivityID: allActivites[index].activityID,
                userRecordActivityName: allActivites[index].activityName,
                userRecordActivityType: allActivites[index].activityType,
                currentActivity: allActivites[index]
            }, function () {
                index++;
                if (index < allActivites.length) {
                    context.viewStudentDetails(allActivites, student, index);
                }
                else {
                    if (context.state.userRecord.length > 0) {
                        context.setState({ showUserDetailsDialog: true });
                    }
                    else {
                        context.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: this.state.studentName + ' has not started/completed this activity'
                        });
                        setTimeout(() => {
                            context.setState({
                                showAlert: false,
                                alertStyle: '',
                                alertText: ''
                            })
                        }, 4000);
                    }
                }
            });
        }).catch(error => {
            console.error(error);
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            context.hideIndicator();
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    loadStudentDetails(assignmentID, studentID, activityID) {

        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                activityID: activityID,
                assignmentID: assignmentID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getUserDetailsDialog() {
        let context = this;

        this.state.userRecord.sort(function (a, b) {
            return new Date(b.completionDate) - new Date(a.completionDate);
        });

        let record = this.state.userRecord[this.state.currentAttempt];
        let userRecordActivityID = this.state.userRecordActivityID;
        let userRecordActivityName = this.state.userRecordActivityName;
        let actType = this.state.userRecordActivityType;
        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {/*{cat.correctAnswers + ' out of ' + cat.total + ' questions correct (' + cat.score.toFixed(0) + '%)'}*/}
                        {cat.score.toFixed(0) + '% (' + cat.correctAnswers + ' out of ' + cat.total + ' questions correct)'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                            style={{ marginRight: '10px', marginTop: '5px', background: 'white', width: '100%' }}>
                            <div className="filled" style={{ width: (cat.score ? cat.score : 0) + '%', background: (cat.score && cat.score > 64 ? 'green' : 'red'), height: '9px', borderRadius: '5px' }} />
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
                {categories}
            </tbody>
        </table>;

        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        let htmlToReactParser = new Parser();
        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer && question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr dir lang = { this.props.auth.user.prefLanguage } className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlToReactParser.parse('<span>' + question.essayScore + '</span>') : ( question.essayScore < 65 ? (htmlToReactParser.parse('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlToReactParser.parse('<span style=" color: green; ">' + question.essayScore + '</span>')) )}
                            </p>
                        </div> : ""
                        }

                    </td>
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <style>{"\
                i{\
                  float:none !important;\
                }\
              "}</style>
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{userRecordActivityName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="accessibility" size={24} /> Student Name:</td>
                    <td>{record.studentName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Score:</td>
                    {/*<td>{correctNumbers + ' out of ' + (record.questionStats? record.questionStats.length:0)  + ' questions correct (' +record.score.toFixed(0)}{'%)'}</td>*/}
                    <td>{record.score.toFixed(0) + '% (' + correctNumbers + ' out of ' + (record.questionStats ? record.questionStats.length : 0) + ' questions correct)'}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Time In:</td>
                    <td>
                        {parseHours(record.timeIn)}{' hours '}
                        {parseMinutes(record.timeIn)}{' mins '}
                        {parseSeconds(record.timeIn)}{' seconds'}
                    </td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="date_range" size={24} /> Completion Date:</td>
                    {record.completionDate !== "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                    {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
                </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr>
                    <th style={{ background: '#f7f8f9', border: '1px solid #555555' }}>
                        Questions
                </th>
                    {actType === 'question' && false && <th>
                    </th>}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h1 className="reportHeading">Student Question Analysis </h1>
                    <p style={{ overflow: 'auto' }}>
                        {studentStats}
                    </p>
                </div>}
        </div>;

        let attemptsArray = [];
        let allStudentsData = this.state.userRecord;

        allStudentsData.sort(function (a, b) {
            return new Date(b.completionDate) - new Date(a.completionDate);
        });
        let counter = 0;


            // let distinctComletionDates = [...new Set(allStudentsData.map(item => item.completionDate))];

            // let distinctStudentData = [];

            // for (let value of distinctComletionDates) {
            //     let singleData = allStudentsData.find(x => {
            //         return x.completionDate == value
            //     })
            //     distinctStudentData.push(singleData);
            // }

            // distinctStudentData.forEach(attempt => {
            //     attemptsArray.push({ id: counter, name: new Date(attempt.completionDate).toLocaleString() });
            //     counter++;
            // });


        allStudentsData = this.removeDuplicatesByPropertyName(allStudentsData, "completionDate");

        allStudentsData.forEach(attempt => {
            attemptsArray.push({ id: counter, name: new Date(attempt.completionDate).toLocaleString() });
            counter++;
        });


        return <Modal id="user-detail-dialog" style={{ 'margin-top': "-92px" }}
            show={this.state.showUserDetailsDialog}
            onHide={() => {
                this.setState({ showUserDetailsDialog: false, userRecord: [], userRecordActivityID: null });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Student Details</Modal.Title>
                {attemptsArray.length > 1 && <div className="col-md-4" style={{ marginTop: '20px' }}>
                    <SelectFieldGroup
                        field="currentAttempt"
                        value={this.state.currentAttempt}
                        options={attemptsArray}
                        onChange={(event) => {
                            context.setState({ currentAttempt: event.target.value });
                            // context.setState({showUserDetailsDialog:false,currentAttempt:value}, function () {
                            //     let rawData = value.split(':');
                            //     let assignmentID = rawData[0];
                            //     let studentID = rawData[1];
                            //     context.getAttemptWiseAnalysis(assignmentID, studentID, context.state.currentActivity.activityID);
                            // });
                        }}
                    />
                </div>}
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showUserDetailsDialog: false, userRecord: [], userRecordActivityID: null });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    removeDuplicatesByPropertyName(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    getPeriodDropdown() {
        let context = this;
        let periods = [];
        if (context.state.periods) {
            let sorted = context.state.periods.sort((a, b) => {
                return a.period > b.period;
            });
            periods.push({ id: 'All Students', name: 'All Students' });
            sorted.forEach(period => {
                periods.push({ id: period.period, name: period.period });
            });
        }
        return <SelectFieldGroup
            id="periodSelect"
            field="periodSelect"
            value={this.state.selectedPeriod}
            options={periods}
            onChange={(evt) => {

                let value = evt.target.value;
                let text = evt.target.selectedOptions[0].innerText;
                context.setState({
                    selectedPeriod: value
                }, function () {

                    // if (text !== 'All Students')
                    // {
                    //     context.loadAllStudentByPeriodDetails(text)
                    // }
                    // else
                    // {
                    //     context.loadAllStudentDetails();
                    //     this.backToSubjectAggr = false;
                    //     this.backToClassAggr = false;
                    //     this.classSelected = false;
                    //     this.setState({selectedStudent:null});
                    // }
                });
            }}
        />
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getTeacherStudentReport() {
        let { dispatch } = this.props;
        let context = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'studentAssignment'.toString(), '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { createdAt: { order: "desc" } }, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            }, true, 'studentID: ("' + context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : context.props.auth.user.userName + '")', 'lp-student/');
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(CourseReportActivities);
