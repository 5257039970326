/**
 * Created by ashahab on 7/14/2017.
 */
import React, { Component } from 'react';
import SelectFieldGroup from "../../forms/select-field-group";


export default class Activity extends Component {

    getDragHeight() {
        return 50;
    }

    render() {
        const { item, itemSelected, dragHandle } = this.props;
        const scale = itemSelected * 0.05 + 1;
        const shadow = itemSelected * 15 + 1;
        const dragged = itemSelected !== 0;
        let types = [
            {
                "id": "practice",
                "name": "Practice"
            },
            {
                "id": "assessment",
                "name": "Assessment"
            }, 
            {   "id": "Interactive", 
                "name": "LP-Interactive"
            }
        ];
        if (item.activityType) {
            switch (item.activityType.toLowerCase()) {
                case 'learning':
                    item.className = ' col-lg-5 col-md-5 col-sm-5 col-xs-12 learning';
                    item.activityTypeDisplay = 'Learning';
                    break;
                case 'flashcards':
                case 'flashcard':
                    item.className = 'col-lg-5 col-md-5 col-sm-5 col-xs-12 flashCard';
                    item.activityTypeDisplay = 'Flashcards';
                    break;
                case 'question':
                    item.className = 'col-lg-5 col-md-5 col-sm-5 col-xs-12 questionRow';
                    item.activityTypeDisplay = 'Questions';
                    break;
                default:
                    item.className = 'col-lg-5 col-md-5 col-sm-5 col-xs-12 learning';
                    item.activityTypeDisplay = item.activityType;
                    break;
            }
        }


        return (
            <li className="ui-state-default" style={{ 'border-bottom': '1px solid #bababa', 'margin-left': '30px', 'margin-right': '10px' }}>
                <div className="row">
                    <div className={item.className} style={{ 'margin-left': '-25px' }}>
                        <span className="typeIcon"> </span>
                        <span style={{ display: 'inline-block', position: 'relative' }}><p>{item.activityName}</p></span>
                    </div>
                    <div className="col-lg-7  col-md-7 col-sm-7 col-xs-12">
                        <div className="posRel fieldBg col-lg-4 col-md-4 col-sm-4" style={{ 'margin-top': '10px' }}>
                            {item.activityTypeDisplay}
                        </div>
                        <div className="posRel fieldBg col-lg-4 col-md-4 col-sm-4" style={{ 'margin-top': '-8px' }}>
                            {item.activityType && item.activityType.toLowerCase() === 'question' &&
                                <SelectFieldGroup
                                    dropDownId={item.unitID}
                                    field={item.ID}
                                    value={item.questionActivityType}
                                    options={types}
                                    onChange={item.onActivityOptionsChange}
                                />
                            }
                        </div>
                        <div className="col-md-4 pull-right">
                            {dragHandle(<div className="DrgBtn" />)}
                            <a onClick={item.onDeleteActivity} name={item.ID} id={item.unitID} className="DltBtn" />
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}
