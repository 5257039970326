/**
 * Created by ashahab on 5/31/2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuItem from './menu-Item';
import DummyPerson from '../../assets/images/dummyPerson.jpg';
import { userLogoutRequest } from '../../actions/login-action';
import ClevertapReact from "clevertap-react";
import "./style.css"
import config from '../../config';
import { ElasticSearchColumns, ElasticSearchTypes, SET_SCHOOL_LICENSE_TYPE } from '../../actions/types';
import ElasticSearchQuery from '../../actions/elastic-search-query';
import apiClient from "../../actions/api-client";
class NavigationBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      viewMenu : false,
      isLinked : this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.gmail ? true : false
    };
    this.hideMenu = this.hideMenu.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.logout = this.logout.bind(this);
  }
  
  hideMenu(e) {
    console.log(this.state.viewMenu);
    if (this.state.viewMenu) {
      this.setState({ viewMenu: false });
    } else {
      this.setState({ viewMenu: true })
    }
  }

  onMenuItemClick(e) {

    ClevertapReact.event("View " + e.target.text, { 'userName': this.props.auth.user.userName });
    browserHistory.push(e.target.to);
  }

  logout() {

    ClevertapReact.event('User logged out', { 'userName': this.props.auth.user.userName });
    this.props.userLogoutRequest().then(
      (res) => browserHistory.push('/')
    );
  }

  render() {
    let teacherUser;
    let lpTeacherUser;
    let adminUser;

    if ( !this.state.isLinked ) {
      teacherUser = (
        <ul className="nav navbar-nav text-center">
          <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/courses" label="Courses" router={this.context.router} onClick={this.onMenuItemClick} /> 
          <MenuItem path="/activities" label="Activities" router={this.context.router} onClick={this.onMenuItemClick} /> 
          <MenuItem path="/assignments" label="Assignments" router={this.context.router} onClick={this.onMenuItemClick} />  
          {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
          <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/questions" label="Question Editor" router={this.context.router} onClick={this.onMenuItemClick}/>
          <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={() => { this.hideMenu();browserHistory.push("/profile")}}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
          <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
          <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
        </ul>
      );
      
    } else {
      teacherUser = (
        <ul className="nav navbar-nav text-center">
          <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
          {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
          <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
          <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={() => { this.hideMenu();browserHistory.push("/profile")}}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
          <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
          <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
        </ul>
        );
    }

    // teacherUser = (
    //   <ul className="nav navbar-nav text-center">
    //         <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <MenuItem path="/courses" label="Courses" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <MenuItem path="/activities" label="Activities" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <MenuItem path="/assignments" label="Assignments" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <MenuItem path="/questions" label="Question Editor" router={this.context.router} onClick={this.onMenuItemClick} />
    //         <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={() => { this.hideMenu(); browserHistory.push("/profile") }}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
    //         <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
    //         <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
    //   </ul>
    // );

    if ( !this.state.isLinked ) {
     lpTeacherUser = (
      <ul className="nav navbar-nav text-center">
        <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/courses" label="Courses" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/activities" label="Activities" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/assignments" label="Assignments" router={this.context.router} onClick={this.onMenuItemClick} />
        {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
        <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/questions" label="Question Editor" router={this.context.router} onClick={this.onMenuItemClick} />
        <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={() => { this.hideMenu(); browserHistory.push("/profile") }}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
        <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
        <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
      </ul>
    );
     } else {
      lpTeacherUser = (
        <ul className="nav navbar-nav text-center">
          <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
          {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
          <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
          <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={() => { this.hideMenu();browserHistory.push("/profile")}}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
          <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
          <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
        </ul>
      );
     }

     if ( !this.state.isLinked ) { 
       adminUser = (
        <ul className="nav navbar-nav text-center">
          <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/courses" label="Courses" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/activities" label="Activities" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/assignments" label="Assignments" router={this.context.router} onClick={this.onMenuItemClick} />
          {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
          <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/questions" label="Question Editor" router={this.context.router} onClick={this.onMenuItemClick}/>
          <MenuItem path="/school/administration" label="Administration" router={this.context.router} onClick={this.onMenuItemClick}/>
          <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={()=> { this.hideMenu();browserHistory.push("/profile")}}><span><img src={this.props.auth.user.profilePicture?this.props.auth.user.profilePicture:DummyPerson}/></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
          <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
          <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
        </ul>
      );
     } else {
      adminUser = (
        <ul className="nav navbar-nav text-center">
          <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
          {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
          <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/students" label="Classes" router={this.context.router} onClick={this.onMenuItemClick} />
          <MenuItem path="/school/administration" label="Administration" router={this.context.router} onClick={this.onMenuItemClick}/>
          <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={()=> { this.hideMenu();browserHistory.push("/profile")}}><span><img src={this.props.auth.user.profilePicture?this.props.auth.user.profilePicture:DummyPerson}/></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
          <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
          <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
        </ul>
      );
     }

    const lpadminUser = (
      <ul className="nav navbar-nav text-center">
        <MenuItem path="/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/courses" label="Courses" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/activities" label="Activities" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/assignments" label="Assignments" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/users" label="Users" router={this.context.router} onClick={this.onMenuItemClick} />
        {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
        <MenuItem path="/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/questions" label="Question Editor" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/administration" label="Administration" router={this.context.router} onClick={this.onMenuItemClick} />
        <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/profile" onClick={() => { this.hideMenu(); browserHistory.push("/profile") }}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
        <li className="MenuTopBarhelp hidden-md hidden-lg hidden-tab"><a onClick={this.hideMenu} target="_blank" href="http://www.learnerpal.com/help/"><span></span> Help</a></li>
        <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
      </ul>
    );

    const studentUser = (
      <ul className="nav navbar-nav text-center">
        <MenuItem path="/student/dashboard" label="Dashboard" router={this.context.router} onClick={this.onMenuItemClick} />
        <MenuItem path="/student/assignments" label="Assignments" router={this.context.router} onClick={this.onMenuItemClick} />
        {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/selfstudy" label="Self Study" router={this.context.router} onClick={this.onMenuItemClick} />}
        {(this.props.appSettings.schoolType && this.props.appSettings.schoolType != 'Professional Learning') && <MenuItem path="/student/glossary-overview" label="Vocabulary" router={this.context.router} onClick={this.onMenuItemClick} />}
        <MenuItem path="/student/reports" label="Reports" router={this.context.router} onClick={this.onMenuItemClick} />
        <li className="MenupersonImg hidden-md hidden-lg hidden-tab"><Link to="/student/profile" onClick={() => { this.hideMenu(); browserHistory.push("/profile") }}><span><img src={this.props.auth.user.profilePicture ? this.props.auth.user.profilePicture : DummyPerson} /></span>{this.props.auth.user ? this.props.auth.user.firstName + " " + this.props.auth.user.lastName : ''}</Link></li>
        <li className="hidden-md hidden-lg hidden-tab"><a href="#" onClick={this.logout}><span>Logout</span></a></li>
      </ul>
    );

    const { viewMenu } = this.state;
    return (
      // viewMenu && 
      <nav className="navbar">
        <div className="navbar-header">
          <button type="button" onClick={this.hideMenu} className="navbar-toggle pull-left" data-toggle="collapse" data-target="#myNavbar"> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
        </div>
        <div className={classnames("collapse navbar-collapse", { "in": viewMenu })} id="myNavbar">
          {this.props.auth.user && this.props.auth.user.userType.toLowerCase() === "teacher" && (!this.props.auth.user.schoolName || this.props.auth.user.schoolName.toLowerCase() != "learnerpal") && teacherUser}
          {this.props.auth.user && this.props.auth.user.userType.toLowerCase() === "teacher" && this.props.auth.user.schoolName && this.props.auth.user.schoolName.toLowerCase() === "learnerpal" && lpTeacherUser}

          {this.props.auth.user && this.props.auth.user.userType.toLowerCase() === "admin" && (!this.props.auth.user.schoolName || this.props.auth.user.schoolName.toLowerCase() != "learnerpal") && adminUser}
          {this.props.auth.user && this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName && this.props.auth.user.schoolName.toLowerCase() === "learnerpal" && lpadminUser}
          {this.props.auth.user && this.props.auth.user.userType.toLowerCase() === "student" && studentUser}
        </div>
      </nav>
    );
  }
}

NavigationBar.contextTypes = {
  router: PropTypes.object.isRequired,
  userLogoutRequest: PropTypes.func
}

function mapStateToProps(state) {
  return { ...state };
}
const mapDispatchToProps = dispatch => {
  return {
      userLogoutRequest, 
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
