export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_FULLSCREEN = 'SET_FULLSCREEN';
export const UNSET_FULLSCREEN = "UNSET_FULLSCREEN";
export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const REQUEST_SEARCH_ALL = 'FETCH_ACTIVITY_FAILED';
export const FETCH_ALL_ACTIVITIES = 'FETCH_ALL_ACTIVITIES';
export const RECEIVED_ACTIVITY_RESULTS = 'RECEIVED_ACTIVITY_RESULTS';
export const FETCH_ACTIVITY_FAILED = 'FETCH_ACTIVITY_FAILED';
export const VIEW_ACTIVITY = 'VIEW_ACTIVITY';
export const SORT_ALL_ACTIVITIES = 'SORT_ALL_ACTIVITIES';
export const SEARCH_RESULT = 'SEARCH_RESULT';
export const START_VIEW_ACTIVITY = 'START_VIEW_ACTIVITY';
export const FAILED_VIEW_ACTIVITY = 'FAILED_VIEW_ACTIVITY';
export const RCEIVED_VIEW_ACTIVITY = 'RCEIVED_VIEW_ACTIVITY';
export const SHOW_FULL_QUESTION = 'SHOW_FULL_QUESTION';
export const CREATE_ACTIVITY_MOVE_QUESTION = 'CREATE_ACTIVITY_MOVE_QUESTION';
export const CREATE_ACTIVITY_FETCH_QUESTION_FAILED = 'CREATE_ACTIVITY_FETCH_QUESTION_FAILED';
export const CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS = 'CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS';
export const CREATE_QUESTION_SET_INIT_INFO = 'CREATE_QUESTION_SET_INIT_INFO';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const MODIFY_QUESTION = 'MODIFY_QUESTION';
export const CLONE_QUESTION = 'CLONE_QUESTION';
export const FETCH_ALL_QUESTIONS = 'FETCH_ALL_QUESTIONS';
export const QUESTIONS_SEARCH_RESULT = 'QUESTIONS_SEARCH_RESULT';
export const RECEIVED_QUESTIONS_RESULTS = 'RECEIVED_QUESTIONS_RESULTS';
export const FETCH_QUESTIONS_FAILED = 'FETCH_QUESTIONS_FAILED';
export const CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS = 'CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS';
export const CREATE_ACTIVITY_FETCH_FLASHCARD_FAILED = 'CREATE_ACTIVITY_FETCH_FLASHCARD_FAILED';
export const CREATE_ACTIVITY_MOVE_SELECTED_TO_FLASHCARDS = 'CREATE_ACTIVITY_MOVE_SELECTED_TO_FLASHCARDS';
export const CREATE_ACTIVITY_MOVE_FLASHCARD = 'CREATE_ACTIVITY_MOVE_FLASHCARD';
export const CREATE_FLASHCARD_SET_INIT_INFO = 'CREATE_FLASHCARD_SET_INIT_INFO';
export const SET_CREATE_COURSE_OBJ = 'SET_CREATE_COURSE_OBJ';
export const IS_EDIT_COURSE = 'IS_EDIT_COURSE';
export const FETCH_ALL_ASSIGNMENTS = 'FETCH_ALL_ASSIGNMENTS';
export const RECEIVED_ASSIGNMENT_RESULTS = 'RECEIVED_ASSIGNMENT_RESULTS';
export const FETCH_ASSIGNMENT_FAILED = 'FETCH_ASSIGNMENT_FAILED';
export const RECEIVED_COURSE_RESULTS = 'RECEIVED_COURSE_RESULTS';
export const FETCH_COURSE_FAILED = 'FETCH_COURSE_FAILED';
export const FETCH_ALL_COURSES = 'FETCH_ALL_COURSES';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_MESSAGE_ACTIVITIES = 'SET_MESSAGE_ACTIVITIES';
export const SET_MESSAGE_ASSIGNMENTS = 'SET_MESSAGE_ASSIGNMENTS';
export const SCHOOLS_SEARCH_RESULT = 'SCHOOLS_SEARCH_RESULT';
export const FETCH_ALL_SCHOOLS = 'FETCH_ALL_SCHOOLS';
export const RECEIVED_SCHOOLS_RESULTS = 'RECEIVED_SCHOOLS_RESULTS';
export const FETCH_SCHOOLS_FAILED = 'FETCH_SCHOOLS_FAILED';
export const RECEIVED_USERS_RESULTS = 'RECEIVED_USERS_RESULTS';
export const RECEIVED_ACTIVITY_REPORT_RESULTS = 'RECEIVED_ACTIVITY_REPORT_RESULTS';
//Student Portal
export const FETCH_ALL_STUDENT_ASSIGNMENTS = 'FETCH_ALL_STUDENT_ASSIGNMENTS';
export const FETCH_STUDENT_ASSIGNMENT_FAILED = 'FETCH_STUDENT_ASSIGNMENT_FAILED';
export const RECEIVED_STUDENT_ASSIGNMENT_RESULTS = 'RECEIVED_STUDENT_ASSIGNMENT_RESULTS';
export const RECEIVED_STUDENT_GLOSSARY_RESULTS = 'RECEIVED_STUDENT_GLOSSARY_RESULTS';
export const RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS = 'RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS';
export const RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT = 'RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT';
export const RECEIVED_STUDENT_ASSIGNMENT_DASHBOARD_RESULTS = 'RECEIVED_STUDENT_ASSIGNMENT_DASHBOARD_RESULTS';
export const SET_THEME_VIRTUAL = 'SET_THEME_VIRTUAL';
export const SET_THEME_STEM_LAB = "SET_THEME_STEM_LAB";
export const SET_THEME = "SET_THEME";
export const SET_SCHOOL_LICENSE_TYPE = "SET_SCHOOL_LICENSE_TYPE";

// Reports
export const RECEIVED_LP_ADMIN_REPORT_RESULT = 'RECEIVED_LP_ADMIN_REPORT_RESULT';

//Action Icon Images used in 'View' screens
export const ActionIconTypes = {
    Edit: "Edit",
    Print: "Print",
    Duplicate: "Duplicate",
    Delete: "Delete",
    Add: "Add",
    Share:"Share",
    Assign:"Assign"
};

//For elastic search queries
export const ElasticSearchTypes = {
    Activity: "activity",
    Course:"course",
    Question:"question",
    Assignment:"assignment",
    Glossary:"glossary",
    School:"school",
    StudentAssignment:"lp-student/",
    StudentGlossary:"glossary",
    Users:"users",
    LpAdminReportForTeachers:'audit_trail',
    LpAdminReportForStudents:'student_audit_trail'
};

export const ElasticSearchColumns = {
    Activity: ['id', 'type', 'name', 'ownerName', 'createdAt', 'subjects', 'documentLink', 'activityType', 'keywords', 'questions', 'fileName', 'originalOwner', 'tags', 'activityState', 'description'],
    Course: ['id', 'type', 'keywords', 'ownerName','visibleTo','courseName','description','subject', 'units', 'originalOwner', 'lastUpdated', 'sharedWith', 'courseState', 'createdAt'],
    Question: ['id', 'type', 'keywords', 'ownerName', 'visibleTo', 'question', 'category', 'subjects', 'questionState', 'questionState2', 'ownerName3', 'hint', 'explanation', 'questionType', 'subcategory', 'choices', 'answer', 'notes', 'rating'],
    Assignment:['id', 'type', 'keywords', 'ownerName', 'visibleTo', 'assignmentName', 'assignstatus', 'dueDate', 'createdAt', 'periodName', 'activities', 'activityType', 'desiredMonth', 'assignedActivities', 'originalOwner', 'assignmentProgress', 'essayInReview',  'studentsLength', 'failStudents','passStudents','incompleteStudents', 'assignmentType', "isRandomize"],
    Glossary:[],
    School:[],
    StudentAssignment:['studentID', 'type', 'keywords', 'ownerName', 'visibleTo', 'dueDate', 'assignmentStatus', 'teacher', 'assignmentName', 'assignmentID', 'assignmentType', 'activityID', 'activityType', 'activityName', 'progress', 'createdAt','assignedActivities','score', 'timeIn', 'attempts', 'assignedCourses', 'attemptedDate'],
    StudentGlossary:['ownerName','originalOwner','word','category','definition','subjects','visibility', 'translatedWord'],
    Users:[],
    LpAdminReportForTeachers:[],
    LpAdminReportForStudents:[]
};

export const SET_ES_ATTRIBUTES = 'SET_ES_ATTRIBUTES';
