import React from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import MaintenanceTable from "../../maintenance-table/maintenance-table";
import Filter from "../../filter/filter";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import {
    RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes,
    ElasticSearchColumns
} from "../../../actions/types";
import classnames from "classnames";
import StudentPortalActions from "../../../actions/student-portal-actions";
import StudentAssignmentMaintenanceTableMobile from "../../student-assignment-maintenance-table-mobile/student-assignment-maintenance-table-mobile";
import $ from "jquery";
import { browserHistory } from "react-router";
import ReactScrollPagination from 'react-scroll-pagination'
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import config from "../../../config";
import LoginUtils from "../../common/login-utils";
import { SET_FULLSCREEN } from "../../../actions/types";
import interactiveIcon from '../../../assets/images/interactive.png';

class StudentAssignments extends React.Component {
    constructor(props) {
        super(props);
        this.getStudentAssignments = this.getStudentAssignments.bind(this);
        this.startOrResume = this.startOrResume.bind(this);
        this.getNextPage = this.getNextPage.bind(this);

        this.state = { screenWidth: window.innerWidth, loadingNextPage: 0 };
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';
    }

    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/student/assignments', '/');
        this.getStudentAssignments();
    }

    startOrResume(assignment) {
        if(assignment.assignedActivities && assignment.assignedActivities.assessmentType === 'Interactive'){
            browserHistory.push({
                pathname: '/assignments/interactive/'+assignment.assignmentID+'/'+assignment.assignmentName
            });
            return 
        }

        browserHistory.push({
            pathname: '/student/assignments/view/' + assignment.assignmentID
        });
        const { dispatch } = this.props;
        dispatch({ type: SET_FULLSCREEN })
    }

    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if (isMobile & this.state.loadingNextPage === 0) {
            let myID = Math.random() * 10000000000000000000;
            this.setState({ 'loadingNextPage': myID });
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('studentAssignment');
                const { dispatch } = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'studentAssignment', esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery = new ElasticSearchQuery();
                elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
                    esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });
                    }, true, 'studentID: ("' + this.props.auth.user.userName + '")', 'lp-student/');
                this.setState({ 'loadingNextPage': 0 });
            }

        }

    }
    render() {
        var d = new Date();
        var timestamp = Math.floor(d.getTime());
        const { screenWidth } = this.state;
        let context = this;
        const isMobile = screenWidth < 768;
        let results = this.props.studentPortal && this.props.studentPortal.assignments && this.props.studentPortal.assignments.results ? this.props.studentPortal.assignments.results : [];
        let results1 = [];
        results.forEach(item => {
            let acts = [];
            if (item.assignmentType && item.assignmentType.toLowerCase() === 'course') {
                if (item.course) {
                    for (let i = 0; i < item.course.units.length; i++) {
                        let unit = item.course.units[i];
                        if (unit.activities && unit.activities.length > 0) {
                            unit.activities.forEach(act => {
                                acts.push(act);
                            });
                        }
                    }
                }
            }
            if (acts.length > 0) {
                item.activities = acts;
            }
        });

        results.forEach(item => {
            if (item.assignedActivities && item.assignedActivities.timeStampEnd && item.assignedActivities.timeStampEnd < timestamp) { item.assignmentStatus = "Locked" }
            else if (item.assignedCourses && item.assignedCourses.timeStampEnd && item.assignedCourses.timeStampEnd < timestamp) { item.assignmentStatus = "Locked" }
            if (item.assignedActivities && item.assignedActivities.timeStampStart && item.assignedActivities.timeStampStart > timestamp) { }
            else if (item.assignedCourses && item.assignedCourses.timeStampStart && item.assignedCourses.timeStampStart > timestamp) { }
            else { results1.push(item) }
        });
        results = results1

        let esAttr = this.props.elasticSearchAttributes.map.get('studentAssignment');

        let maintenanceTableData = {
            emptyMessage: this.props.manageAssignments.isFetching === false ? 'No records found.' : '',
            showActionMenu: false,
            columns: [
                {
                    name: 'assignmentName', type: 'text', label: 'Assignment', options: {
                        sortable: true, customDom: (record) => {
                            // customDom is not applicable to array type attributes, ex: subjects in activity.
                            return (
                                <td width="37%">
                                    {(!record.assignedActivities || record.assignedActivities.assessmentType != 'Interactive') && <span className="typeIcon"> </span>}
                                    {record.assignedActivities && record.assignedActivities.assessmentType == 'Interactive' && <span className="typeIcon interactiveIcon"> 
                                        <img className="main-icon" src={interactiveIcon} />
                                    </span>}
                                    <span className="contentType ">
                                        <h4 style={{ marginBottom: '4px' }}>{record.assignmentName}</h4>
                                        <div >{Math.round(record.progress ? record.progress : 0) + '% Complete'}</div>
                                    </span>
                                    {record.activities && record.activities.length > 0 &&
                                        <span className="subRowToggleStudent" onClick={(evt) => {

                                            let parentRowId = evt.target.parentElement.parentElement.id;
                                            if (evt.target.getAttribute('class').trim().toLowerCase() === 'subRowToggleStudent'.toLowerCase()) {
                                                $('#' + parentRowId + '-child').show();
                                                evt.target.setAttribute('class', 'subRowToggleStudent minus');
                                            } else {
                                                $('#' + parentRowId + '-child').hide();
                                                evt.target.setAttribute('class', 'subRowToggleStudent');
                                            }
                                        }} />}
                                </td>
                            );
                        }
                    }
                },
                {
                    name: 'teacher',
                    type: 'text',
                    label: 'Teacher',
                    options: { sortable: true, width: '15%' }
                },
                {
                    name: 'dueDate', type: 'date', label: 'Due Date', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            if (record.dueDate !== 'NaN') {
                                let dueDate = new Date(parseFloat(record.dueDate));
                                let day = dueDate.getUTCDate();
                                let month = dueDate.getUTCMonth() + 1;
                                let year = dueDate.getUTCFullYear();
                                let colorClass = dueDate < new Date() ? 'maincolorng' : 'maincolblu';
                                return (
                                    <td width="7%">
                                        <div className={colorClass}>{month + "/" + day + "/" + year}
                                        </div>
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="7%">
                                        NaN
                                    </td>
                                );
                            }
                        }
                    }
                },
                {
                    name: 'availableDate', type: 'date', label: 'Available Until', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.

                            let d, untilMonth, amPm, strTime, untilDay, UntilYear, untilHour, untilMins, colorClassUntil;
                            if (record && record.assignedActivities && record.assignedActivities.timeStampEnd) { d = new Date(record.assignedActivities.timeStampEnd) }
                            else if (record && record.assignedCourses && record.assignedCourses.timeStampEnd) { d = new Date(record.assignedCourses.timeStampEnd) }
                            if (d) {
                                untilMonth = d.getUTCMonth() + 1
                                untilHour = d.getHours()
                                untilDay = d.getDate()
                                untilMins = d.getMinutes()
                                UntilYear = d.getUTCFullYear()
                                colorClassUntil = d < new Date() ? 'maincolorng' : 'maincolblu';
                                amPm = untilHour >= 12 ? 'pm' : 'am';
                                untilHour = untilHour % 12;
                                untilHour = untilHour ? untilHour : 12; // the hour '0' should be '12'
                                untilMins = untilMins < 10 ? '0' + untilMins : untilMins;
                                strTime = untilHour + ':' + untilMins + ' ' + amPm;

                                return (
                                    <td width="8%">
                                        {d && untilMonth > 0 && <div style={{ marginTop: "3px" }} className={colorClassUntil}>
                                            {untilMonth + "/" + untilDay + "/" + UntilYear}
                                            <br /> {strTime}</div>}
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="8%">
                                    </td>
                                );
                            }
                        }
                    }
                },
                {
                    name: 'assignmentStatus', type: 'text', label: 'Status', options: {
                        sortable: true, width: '12%'
                    }
                },
                {
                    name: 'progress', type: 'text', label: ' ', options: {
                        sortable: false, customDom: (record) => {

                            var lock = false;
                            let btnTxt = (!record.progress || record.progress === '0' || record.progress === 100) ? 'Start' : 'Resume';;
                            var d = new Date();
                            var timestamp = Math.floor(d.getTime());
                            let stamp = "", splitStamp = []
                            var dDate = new Date(parseInt(record.dueDate))
                            var createDate = new Date(parseInt(record.createdAt))
                            if (record && record.assignedActivities && record.assignedActivities.lockAssignment) {
                                // if (record.assignedActivities.timeStampEnd) {
                                // stamp = record.assignedActivities.timeStampEnd
                                // splitStamp = stamp.split(":", 2)
                                // dDate.setHours(splitStamp[0])
                                // dDate.setMinutes(splitStamp[1])
                                // }
                                // else {
                                //     dDate.setHours("17")
                                // }
                                // if (record.assignedActivities.timeStampStart) {
                                //     stamp = record.assignedActivities.timeStampStart
                                //     splitStamp = stamp.split(":", 2)
                                //     createDate.setHours(splitStamp[0])
                                //     createDate.setMinutes(splitStamp[1])
                                // }
                                // dDate = Math.floor(dDate.getTime());
                                // createDate = Math.floor(createDate.getTime());
                                // if (dDate < timestamp || createDate > timestamp) {
                                //     btnTxt = "Locked"
                                //     lock = true;
                                // }
                                if (record.assignedActivities.timeStampEnd) {
                                    if (record.assignedActivities.timeStampEnd < timestamp) {
                                        btnTxt = "Locked"
                                        lock = true;
                                    }
                                }
                                if (record.assignedActivities.timeStampStart) {
                                    if (record.assignedActivities.timeStampStart > timestamp) {
                                        btnTxt = "Locked"
                                        lock = true;
                                    }
                                }
                            }
                            else if (record && record.assignedCourses && record.assignedCourses.lockAssignment) {
                                // if (record.assignedCourses.timeStampEnd) {
                                // stamp = record.assignedCourses.timeStampEnd
                                // splitStamp = stamp.split(":", 2)
                                // dDate.setHours(splitStamp[0])
                                // dDate.setMinutes(splitStamp[1])
                                // }
                                //  else {
                                //     dDate.setHours("17")
                                // }
                                // if (record.assignedCourses.timeStampStart) {
                                //     stamp = record.assignedCourses.timeStampStart
                                //     splitStamp = stamp.split(":", 2)
                                //     createDate.setHours(splitStamp[0])
                                //     createDate.setMinutes(splitStamp[1])
                                // }
                                // dDate = Math.floor(dDate.getTime());
                                // createDate = Math.floor(createDate.getTime());
                                // if (dDate < timestamp || createDate > timestamp) {
                                //     btnTxt = "Locked"
                                //     lock = true;

                                // }
                                if (record.assignedCourses.timeStampEnd) {
                                    if (record.assignedCourses.timeStampEnd < timestamp) {
                                        btnTxt = "Locked"
                                        lock = true;
                                    }
                                }
                                if (record.assignedCourses.timeStampStart) {
                                    if (record.assignedCourses.timeStampStart > timestamp) {
                                        btnTxt = "Locked"
                                        lock = true;
                                    }
                                }
                            }
                            if(record.assignedActivities && record.assignedActivities.assessmentType == 'Interactive' && record.assignmentStatus == 'Completed'){
                                return <td width="8%"></td>;
                            }
                            return (
                                <td width="8%">
                                    <button className='btn-sta' onClick={() => { context.startOrResume(record) }} disabled={lock}>{btnTxt}</button>
                                </td>
                            );

                        }
                    }
                }
            ],
            items: results,
            pageable: true,
            rowClickAction: this.rowClickAction,
            totalRecords: this.props.studentPortal.assignments.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 10,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            },
            itemClass: (record) => {
                let assignType = record.assignmentType ? record.assignmentType.toLowerCase() : 'learning';
                if (assignType === 'course') {

                    return 'booksRow';
                } else if (assignType === 'activity') {
                    let type = record.activityType ? record.activityType.toLowerCase() : '';

                    switch (type) {
                        case 'url':
                        case 'pdf':
                            return 'learning';
                        case 'question':
                        case 'questions':
                            return 'questionRow';
                        case 'flashcard':
                        case 'flashcards':
                            return 'flashCard';
                    }
                }
                return 'learning';
            },
            rowExpansion: {
                // There is no action support in expanded rows for now.
                showActionMenu: false,
                expansionConditionExpression: (currentRow) => {
                    // calculate condition to show expand button with row.
                    let acts = [];
                    if (currentRow.assignmentType && currentRow.assignmentType.toLowerCase() === 'course') {
                        if (currentRow.course) {
                            for (let i = 0; i < currentRow.course.units.length; i++) {
                                let unit = currentRow.course.units[i];
                                if (unit.activities && unit.activities.length > 0) {
                                    unit.activities.forEach(act => {
                                        acts.push(act);
                                    });
                                }
                            }
                        }
                    }
                    return acts.length > 0;
                },
                getSubRowClass: (currentRow) => {
                    // calculate class for sub rows.
                    switch (currentRow.activityType.toLowerCase()) {
                        case 'learning':
                            return 'learning';
                        case 'flashcards':
                        case 'flashcard':
                            return 'flashCard';
                        case 'question':
                            return 'questionRow';
                        default:
                            return 'learning';
                    }
                },
                expansionKeyInCurrentItem: 'activities', // must be an array attribute in each record.
                expansionItemColumns: [                   // For now only text type is supported if its anything else it will convert it to text.
                    {
                        name: 'activityName', type: 'text', label: 'Activity Name', options: {
                            customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                return (
                                    <td width="40%">
                                        <span className="typeIcon"> </span>
                                        <span className="contentType ">
                                            <h4>{record.activityName}</h4>
                                            <p>By <strong>{record.ownerName}</strong></p>
                                        </span>
                                    </td>
                                );
                            }
                        }
                    },
                    { name: 'activityType', type: 'text', label: 'Type', options: {} }
                ],
                actions: [
                    {
                        linkLabel: 'Manage', action: (selectedRecord) => {
                            this.openManageAssignmentPage(selectedRecord);
                        },
                        isConditional: true,
                        conditionExpression: (currentRecord) => {
                            /* return (!isMobile && (currentRecord.ownerName === this.props.auth.user.userName
                             || (this.props.auth.user.userType === "Admin" && this.props.auth.user.schoolName === "LearnerPal")
                             || currentRecord.originalOwner === this.props.auth.user.userName));*/
                            return true;
                        }
                    }
                ],
                rowClickAction: (record) => {
                    console.log(record, 'Sub row clicked.');
                }
            }
        };

        return (
            <div>
                <Heading
                    isQuery={true}
                    customQuery={'studentID: ("' + this.props.auth.user.userName + '")'}
                    customURL={ElasticSearchTypes.StudentAssignment}
                    location="studentAssignment"
                    screenNameForElasticSearch="stdassign"
                    actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                    heading="Assignments"
                    isSearch={true}
                    isShowButton={true}
                    allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>
                        <Filter
                            customQuery={'studentID: ("' + this.props.auth.user.userName + '")'}
                            customURL={ElasticSearchTypes.StudentAssignment}
                            location="studentAssignment"
                            actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            filterColumns={[
                                { name: 'teacher', type: 'text', label: 'Teacher' },
                                { name: 'assignmentStatus', type: 'text', label: 'Status' }
                            ]}
                            screenNameForElasticSearch="stdassign"
                            allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                        />
                        <div className={this.tableCssClassName}>
                            <div className="listToBesslide NiceScroll  ">
                                <div className="blockForlist ">
                                    <MaintenanceTable
                                        isQuery={true}
                                        customQuery={'studentID: ("' + this.props.auth.user.userName + '")'}
                                        customURL={ElasticSearchTypes.StudentAssignment}
                                        location="studentAssignment"
                                        actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                                        screenNameForElasticSearch="stdassign"
                                        allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                    <ReactScrollPagination
                                        fetchFunc={this.getNextPage}
                                    />
                                    {<StudentAssignmentMaintenanceTableMobile
                                        items={results}
                                        emptyMessage={maintenanceTableData.emptyMessage}
                                        columns={maintenanceTableData.columns}
                                        onRowClick={(record) => { context.startOrResume(record); }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading} />
            </div>
        );
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getStudentAssignments() {
        let { dispatch } = this.props;
        let context = this;
        context.showIndicator();
        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'studentAssignment'.toString(), '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { createdAt: { order: "desc" } }, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                context.hideIndicator();
                results = JSON.parse(JSON.stringify(results))

                dispatch({
                    type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                    status: 200,
                    data: results,
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            }, true, 'studentID: ("' + context.props.auth.user.userName + '")', ElasticSearchTypes.StudentAssignment);
        // StudentPortalActions.fetchAllStudentAssignments(dispatch, null, this.props.auth.user.userName, {}, [
        //     'studentID', 'type', 'keywords', 'ownerName', 'visibleTo', 'dueDate', 'assignmentStatus', 'teacher', 'assignmentName', 'assignmentID', 'assignmentType', 'activityID', 'activityType', 'activityName', 'progress', 'course'
        // ], (results)=> {
        //     context.hideIndicator();
        //     console.log(results, 'Student Assignments - Success called.');
        // }, (error)=> {
        //     context.hideIndicator();
        //     console.error(error, 'Student Assignments - Error called.');
        // })
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(StudentAssignments);
