import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/root-reducer'
import { Router, browserHistory } from 'react-router'
import routes from './components/routes/routes';
import { setCurrentUser } from './actions/login-action';
import 'babel-polyfill';
import ClevertapReact from 'clevertap-react';

ClevertapReact.initialize("TEST-946-9KK-9W5Z");
var $ = require('jquery');
window.$ = $;

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger())
}

const store = createStore(
    rootReducer,
    // window && window.__REDUX_DEVTOOLS_EXTENSION__
    //     ? compose(
    //         applyMiddleware(...middleware),
    //         window.__REDUX_DEVTOOLS_EXTENSION__()
    //     )
    //     : 
        applyMiddleware(...middleware),
);

if (localStorage.userObj) {
    store.dispatch(setCurrentUser(JSON.parse(localStorage.userObj)))
}

ReactDOM.render(
    <Provider store={store}>
        <Router history={browserHistory} routes={routes} />
    </Provider>
    , document.getElementById('root'));
registerServiceWorker();
